var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal__title"},[_c('h3',[_vm._v("Регистрация")]),_c('div',{staticClass:"close-button",on:{"click":_vm.close}},[_c('span')])]),_c('div',{staticClass:"modal__body"},[_c('form',{staticClass:"form"},[_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"regName"}},[_vm._v("Имя:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.name.$model),expression:"$v.name.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.name.$anyError, 
            validInput: !_vm.$v.name.$anyError && _vm.$v.name.$dirty
          },attrs:{"name":"name","type":"text","placeholder":"Иванов Иван","id":"regName"},domProps:{"value":(_vm.$v.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.name.required && _vm.$v.name.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Это обязательное поле")]):_vm._e(),(!_vm.$v.name.letters && _vm.$v.name.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Введите ФИО")]):_vm._e()]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"regCompany"}},[_vm._v("Название компании:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.companyName.$model),expression:"$v.companyName.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.companyName.$anyError, 
            validInput: !_vm.$v.companyName.$anyError && _vm.$v.companyName.$dirty && _vm.$v.companyName.$model
          },attrs:{"name":"company-name","type":"text","placeholder":"Название Компании","id":"regCompany"},domProps:{"value":(_vm.$v.companyName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.companyName, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"regPhone"}},[_vm._v("Телефон:")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ### ## ##'),expression:"'+7 (###) ### ## ##'"},{name:"model",rawName:"v-model.trim",value:(_vm.$v.phone.$model),expression:"$v.phone.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.phone.$anyError, 
            validInput: !_vm.$v.phone.$anyError && _vm.$v.phone.$dirty
          },attrs:{"name":"login","type":"text","placeholder":"+7 (000) 000 00 00","id":"regPhone"},domProps:{"value":(_vm.$v.phone.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.phone, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.phone.required && _vm.$v.phone.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Это обязательное поле")]):_vm._e(),(!_vm.$v.phone.phoneNumber && _vm.$v.phone.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Введите номер телефона")]):_vm._e()]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"regPassword"}},[_vm._v("Пароль:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.$model),expression:"$v.password.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.password.$anyError, 
            validInput: !_vm.$v.password.$anyError && _vm.$v.password.$dirty && _vm.$v.password.$model
          },attrs:{"name":"password","type":"password","id":"regPassword"},domProps:{"value":(_vm.$v.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.password.required && _vm.$v.password.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Это обязательное поле")]):_vm._e(),(!_vm.$v.password.minLength && _vm.$v.password.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Не менее "+_vm._s(_vm.$v.password.$params.minLength.min)+" символов")]):_vm._e()]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"regPasswordConfirm"}},[_vm._v("Подтверждение пароля:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.passwordConfirmation.$model),expression:"$v.passwordConfirmation.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.passwordConfirmation.$anyError, 
            validInput: !_vm.$v.passwordConfirmation.$anyError && _vm.$v.passwordConfirmation.$dirty && _vm.$v.passwordConfirmation.$model
          },attrs:{"name":"password_confirmation","type":"password","id":"regPasswordConfirm"},domProps:{"value":(_vm.$v.passwordConfirmation.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.passwordConfirmation, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.passwordConfirmation.sameAsPassword && _vm.$v.passwordConfirmation.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Новый пароль и подтверждение пароля не совпадают")]):_vm._e()]),_c('div',{staticClass:"form__row form__row--btns"},[_c('button',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();return _vm.createUser($event)}}},[_vm._v("Регистрация")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }