<template>
  <div class="modal-small">
    <div class="modal-small__header">
      <h3 class="modal-small__title">Удалить партнёра</h3>
    </div>
    <div class="modal-small__body">
      <div class="form__row">
        <span>Вы точно хотите удалить данного партнёра? Действие нельзя будет отменить.</span>
      </div>
      <div class="form__row form__row--btns">
        <button class="btn btn__success" @click.prevent="deletePartner">Да</button>
        <button class="btn btn__danger" @click.prevent="close">Нет</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DeletePartner",
  props: {
    data: Array,
    type: String,
    flag: Boolean
  },
  methods: {
    close() {
      this.$store.commit("closeModal");
    },
    deletePartner() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.access_token
        }
      });

      let data = {
        id: []
      };

      if (!Array.isArray(this.data)) {
        data.id.push(this.data);
      } else {
        for (let element of this.data) {
          data.id.push(element);
        }
        console.log(data);
      }

      instance
        .post("/api/post/admin/partners/delete", data)
        .then(response => {
          this.data = [];
          this.$store.commit("changePartnersData", { type: 'deletePartner', data: data });
         
          this.$store.commit("closeModal");
          if (this.$store.state.partnersData.orders.length === 0) {
            this.$emit('getData');
          }
          
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit('forceLogout')
          }
          if (error.response.status === 422) {
            alert("Некорректные данные");
          }
          if (error.response.state === 500) {
            this.$store.commit('createMessage', {type: 'danger', text: this.$store.state.messages.serverError});
          }
        });
    }
  }
};
</script>

<style lang="scss">
</style>
