<template>
  <div class="modal-small" @click.stop>
    <div class="modal-small__header">
      <h3 class="modal-small__title">Лог</h3>
    </div>
    <div class="modal-small__body">
      <div class="form__row">
        <div class="table-partners table">
          <div class="table__row table__row--header">
            <div class="table__cell table__cell--header">Код</div>
            <div class="table__cell table__cell--header">Метод</div>
            <div class="table__cell table__cell--header">URL</div>
            <div class="table__cell table__cell--header">Тело ответа</div>
            <div class="table__cell table__cell--header">Дата</div>
          </div>
          
          <div class="table__row" v-for="req in this.$store.state.curReqInfo" :key="req">
            <div :class="{ fail: req.status === 'fail', success: req.status === 'success'}" class="table__cell">{{ req.code || "???" }}</div>
            <div class="table__cell">{{ req.method || "???" }}</div>
            <div class="table__cell">{{ req.url || "???" }}</div>
            <div class="table__cell">{{ req.response || "" }}</div>            
            <div class="table__cell">{{ req.created_at || "" }}</div>
          </div>        

        </div>
      </div>
      <div class="form__row form__row--btns">
        <button class="btn btn__danger" @click.prevent="close">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'LogModal',
  methods: {
    close() {
      this.$store.commit('closeModal');
    }
  },
  mounted() {
    this.$store.commit('showPreloader');

    const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.access_token
        }
      });

      instance
        .get(`api/post/admin/request/show?id=${this.$store.state.curRequestId}&log=true`)
        .then(response => {
          this.$store.commit("setCurReqInfo", response.data);
          this.$store.commit('hidePreloader');
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 422) {
            alert("Некорректные данные");
          }
          if (error.response.state === 500) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
          }
        });
  }
};
</script>

<style lang="scss" scoped>
  .modal-small {
    max-width: unset;
    max-height: 80vh;
    overflow-y: scroll;
  }

  .fail {
    color: red;
  }

  .success {
    color: green;
  } 
</style>
