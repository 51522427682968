<template>
  <div class="modal-big">
    <div class="modal-big__header">
      <span class="modal-big__back-btn back-btn" @click="back">
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 612 612"
          style="enable-background:new 0 0 612 612;"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M587.572,186.881c-32.266-75.225-87.096-129.934-162.956-162.285C386.711,8.427,346.985,0.168,305.497,0.168
                      c-41.488,0-80.914,8.181-118.784,24.428C111.488,56.861,56.415,111.535,24.092,186.881C7.895,224.629,0,264.176,0,305.664
                      c0,41.496,7.895,81.371,24.092,119.127c32.323,75.346,87.396,130.348,162.621,162.621c37.87,16.247,77.295,24.42,118.784,24.42
                      c41.489,0,81.214-8.259,119.12-24.42c75.86-32.352,130.69-87.403,162.956-162.621C603.819,386.914,612,347.16,612,305.664
                      C612,264.176,603.819,224.757,587.572,186.881z M538.717,440.853c-24.014,41.195-56.922,73.876-98.375,98.039
                      c-41.196,24.021-86.325,36.135-134.845,36.135c-36.47,0-71.27-7.024-104.399-21.415c-33.123-14.384-61.733-33.294-85.662-57.215
                      c-23.921-23.928-42.966-52.811-57.214-85.997c-14.198-33.065-21.08-68.258-21.08-104.735c0-48.52,11.921-93.428,35.799-134.509
                      c23.971-41.231,56.886-73.947,98.04-98.04c41.16-24.092,85.997-36.142,134.517-36.142s93.649,12.121,134.845,36.142
                      c41.453,24.164,74.283,56.879,98.375,98.04c24.092,41.153,36.142,85.99,36.142,134.509
                      C574.858,354.185,562.888,399.399,538.717,440.853z"
              ></path>
              <path
                d="M270.026,128.995c-7.603-7.795-19.402-7.367-26.098-0.671c-7.695,7.695-7.274,18.981-0.671,25.763l149.571,153.591
                      L243.257,457.242c-7.21,7.217-6.689,19.074,0,25.77c3.683,3.677,8.366,5.689,13.72,5.689c5.019,0,9.366-2.021,13.049-5.689
                      l162.286-161.621c7.23-7.195,6.609-19.324,0-26.098L270.026,128.995z"
              ></path>
            </g>
          </g>
        </svg>
      </span>
      <h3 class="modal-big__title">Настройка внешнего запроса</h3>
    </div>

    <div class="modal-big__body">
      <div class="form__row">
        <label class="form__label">Название запроса</label>
        <input
          type="text"
          class="form__input"
          v-model.trim="$v.name.$model"
          :class="{
              errorInput: $v.name.$anyError, 
              validInput: !$v.name.$anyError && $v.name.$dirty && $v.name.$model
            }"
        >
        <div class="error" v-if="!$v.name.required && $v.name.$dirty">Это обязательное поле</div>
      </div>

      <div class="form__row">
        <input name="sendOrdersAddReq" id="sendOrdersAddReq" type="checkbox" class="checkbox-input" v-model="reqData.auth.status">
        <label for="sendOrdersAddReq" class="chekbox">
          <span class="chekbox-label"></span>
          <span class="checkbox-text">Для отправки запроса требуется авторизация</span>
        </label>
      </div>

      <div class="form__request" v-if="reqData.auth.status">
        <div class="request__header" @click="toggleGroup('auth')">
          <p class="request__title">Авторизация</p>
          <span class="arr-down" :class="{closed: !isOpened.auth}">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 240.811 240.811"
              style="enable-background:new 0 0 240.811 240.811;"
              xml:space="preserve"
            >
              <path
                d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
              ></path>
            </svg>
          </span>
        </div>

        <transition name="slide">
          <div class="request__body" v-show="isOpened.auth">
            <div class="form__row">
              <div class="form__label">Метод запроса:</div>
              <multiselect
                v-model.trim="$v.reqData.auth.method.$model"
                :options="this.methods"
                :multiple="false"
                label="name"
                value="id"
                track-by="name"
                :show-labels="false"
                placeholder="POST"
                :class="{
                  errorInput: $v.reqData.auth.method.$anyError, 
                  validInput: !$v.reqData.auth.method.$anyError && $v.reqData.auth.method.$dirty && $v.reqData.auth.method.$model
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
               <div class="error" v-if="!$v.reqData.auth.method.required && $v.reqData.auth.method.$dirty">Это обязательное поле</div>
            </div>

            <div class="form__row">
              <div class="form__label">Тип запроса:</div>
              <multiselect
                v-model.trim="$v.reqData.auth.type.$model"
                :options="this.types"
                :multiple="false"
                label="name"
                value="id"
                track-by="name"
                :show-labels="false"
                placeholder="JSON"
                :class="{
                  errorInput: $v.reqData.auth.type.$anyError, 
                  validInput: !$v.reqData.auth.type.$anyError && $v.reqData.auth.type.$dirty && $v.reqData.auth.type.$model
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
              <div class="error" v-if="!$v.reqData.auth.type.required && $v.reqData.auth.type.$dirty">Это обязательное поле</div>
            </div>

            <div class="form__row">
              <label for class="form__label">URL:</label>
              <input
                type="text"
                class="form__input"
                v-model.trim="$v.reqData.auth.url.$model"
                :class="{
                  errorInput: $v.reqData.auth.url.$anyError, 
                  validInput: !$v.reqData.auth.url.$anyError && $v.reqData.auth.url.$dirty && $v.reqData.auth.url.$model
                }"
                placeholder="https://api.sfgroup.ru/auth "
              >
              <div class="error" v-if="!$v.reqData.auth.url.required && $v.reqData.auth.url.$dirty">Это обязательное поле</div>
            </div>

            <ModalInput :header="'Заголовки'" :inputs="reqData.auth.headers" @addField="addfield">
              <button class="btn" @click="addField({type:'auth', name: 'headers'})">+ Добавить поле</button>
            </ModalInput>

            <ModalInput :header="'Тело запроса'" :inputs="reqData.auth.data" @addField="addfield">
              <button class="btn" @click="addField({type:'auth', name: 'data'})">+ Добавить поле</button>
            </ModalInput>
          </div>
        </transition>
      </div>

      <div class="form__request">
        <div class="request__header" @click="toggleGroup('body')">
          <p class="request__title">Основной запрос</p>
          <span class="arr-down" :class="{closed: !isOpened.body}">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 240.811 240.811"
              style="enable-background:new 0 0 240.811 240.811;"
              xml:space="preserve"
            >
              <path
                d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
              ></path>
            </svg>
          </span>
        </div>

        <transition name="slide">
          <div class="request__body" v-show="isOpened.body">
            <div class="form__row">
              <div class="form__label">Метод запроса:</div>
              <multiselect
                v-model.trim="$v.reqData.body.method.$model"
                :options="this.methods"
                :multiple="false"
                label="name"
                value="id"
                track-by="name"
                :show-labels="false"
                placeholder="POST"
                :class="{
                  errorInput: $v.reqData.body.method.$anyError, 
                  validInput: !$v.reqData.body.method.$anyError && $v.reqData.body.method.$dirty && $v.reqData.body.method.$model
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
              <div class="error" v-if="!$v.reqData.body.method.required && $v.reqData.body.method.$dirty">Это обязательное поле</div>
            </div>

            <div class="form__row">
              <div class="form__label">Тип запроса:</div>
              <multiselect
                v-model.trim="$v.reqData.body.type.$model"
                :options="this.types"
                :multiple="false"
                label="name"
                value="id"
                track-by="name"
                :show-labels="false"
                placeholder="JSON"
                :class="{
                  errorInput: $v.reqData.body.type.$anyError, 
                  validInput: !$v.reqData.body.type.$anyError && $v.reqData.body.type.$dirty && $v.reqData.body.type.$model
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
              <div class="error" v-if="!$v.reqData.body.type.required && $v.reqData.body.type.$dirty">Это обязательное поле</div>
            </div>

            <div class="form__row">
              <label for class="form__label">URL:</label>
              <input
                type="text"
                class="form__input"
                v-model.trim="$v.reqData.body.url.$model"
                :class="{
                  errorInput: $v.reqData.body.url.$anyError, 
                  validInput: !$v.reqData.body.url.$anyError && $v.reqData.body.url.$dirty && $v.reqData.body.url.$model
                }"
                placeholder="https://api.sfgroup.ru/order/create"
              >
              <div class="error" v-if="!$v.reqData.body.url.required && $v.reqData.body.url.$dirty">Это обязательное поле</div>
            </div>

            <ModalInput :header="'Заголовки'" :inputs="reqData.body.headers" @addField="addfield">
              <button class="btn" @click="addField({type:'body', name: 'headers'})">+ Добавить поле</button>
            </ModalInput>

            <ModalInput :header="'Тело запроса'" :inputs="reqData.body.data" @addField="addfield">
              <button class="btn" @click="addField({type:'body', name: 'data'})">+ Добавить поле</button>
            </ModalInput>
          </div>
        </transition>
      </div>

      <div class="form__row">
        <div class="form__label">Выбор региона:</div>
        <multiselect
          v-model.trim="regions"
          :options="this.$store.state.filters.departments"
          :multiple="true"
          label="name"
          value="id"
          track-by="name"
          :show-labels="false"
          placeholder="Новосибирск"
        >
          <span slot="noResult">Совпадений не найдено</span>
        </multiselect>
      </div>

      <div class="form__row form__row--btns">
        <button class="btn btn__success" @click="addRequest">Добавить</button>
        <button class="btn btn__danger" @click="back">Отменить</button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInput from "./ModalInputs/ModalInput";
import axios from "axios";
import {
  required,
  email,
  numeric,
  helpers,
  minLength,
  sameAs,
  between,
  requiredIf
} from "vuelidate/lib/validators";

export default {
  name: "AddRequestModal",
  props: {
    partner: Object
  },
  components: {
    ModalInput
  },
  data() {
    return {
      name: "",
      reqData: {
        auth: {
          status: false,
          type: [],
          method: [],
          url: "",
          headers: [],
          data: []
        },
        body: {
          type: null,
          method: null,
          url: "",
          headers: [],
          data: []
        }
      },
      regions: [],
      methods: [
        { name: "CONNECT", id: 1 },
        { name: "DELETE", id: 2 },
        { name: "GET", id: 3 },
        { name: "HEAD", id: 4 },
        { name: "OPTIONS", id: 5 },
        { name: "PATCH", id: 6 },
        { name: "POST", id: 7 },
        { name: "PUT", id: 8 },
        { name: "TRACE", id: 9 }
      ],
      types: [{ name: "JSON", id: 1 }, { name: "BODY", id: 2 }],
      isOpened: {
        auth: false,
        body: false
      }
    };
  },
  methods: {
    back() {
      this.$store.commit("openModal", "EditPartner");
    },
    addField(obj) {
      this.reqData[obj.type][obj.name].push({ key: "", value: "" });
    },
    addRequest() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.access_token
          }
        });

        this.$store.commit("showPreloader");
        let departments = [];
        if (this.regions.length) {
          this.regions.forEach(function(city) {
            departments.push(city.id);
          });
        }

        let data = {};

        if (this.reqData.auth.status) {
          data.auth = {};

          data.auth.status = this.reqData.auth.status;
          data.auth.method = this.reqData.auth.method.name;
          data.auth.type = this.reqData.auth.type.name;
          data.auth.url = this.reqData.auth.url;

          data.auth.headers = {};
          this.reqData.auth.headers.forEach(function(obj) {
            data.auth.headers[obj.name] = obj.value;
          });

          data.auth.data = {};
          this.reqData.auth.data.forEach(function(obj) {
            data.auth.data[obj.name] = obj.value;
          });
        }

        data.body = {};

        data.body.method = this.reqData.body.method.name;
        data.body.type = this.reqData.body.type.name;
        data.body.url = this.reqData.body.url;

        data.body.headers = {};
        this.reqData.body.headers.forEach(function(obj) {
          data.body.headers[obj.name] = obj.value;
        });

        data.body.data = {};
        this.reqData.body.data.forEach(function(obj) {
          data.body.data[obj.name] = obj.value;
        });

        let dataToSend = {
          id: this.partner.id,
          user: {
            phone: this.partner.phone
          },
          request: {
            name: this.name,
            data: data,
            cities: departments
          }
        };

        console.log(data);

        instance
          .post("/api/post/admin/partners/update", dataToSend)
          .then(response => {
            this.$store.commit("hidePreloader");
            this.$store.commit("closeModal");
            this.reqData = {
              auth: {
                status: null,
                type: [],
                method: [],
                url: "",
                headers: [],
                data: []
              },
              body: {
                type: null,
                method: null,
                url: "",
                headers: [],
                data: []
              }
            };
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("forceLogout");
            }
            if (error.response.status === 500) {
              this.$store.commit("hidePreloader");
              this.$store.commit("createMessage", {
                type: "error",
                text: this.$store.state.serverError
              });
            }
          });
      }
    },
    toggleGroup(group) {
      this.isOpened[group] = !this.isOpened[group];
    }
  },
  computed: {},
  validations: {
    name: {
      required
    },
    reqData: {
      auth: {
        url: {
          required: requiredIf(function() {
            return this.reqData.auth.status
          })
        },
        method: {
          required: requiredIf(function() {
            return this.reqData.auth.status
          })
        },
        type: {
          required: requiredIf(function() {
            return this.reqData.auth.status
          })
        }
      },
      body: {
        url: {
          required
        },
        method: {
          required
        },
        type: {
          required
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  &__request {
    border: 1px solid #ced4da;

    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
.request {
  &__header {
    position: relative;
    font-size: 17px;
    padding: 0.75rem;
  }

  &__body {
    padding: 0.75rem;
    border-top: 1px solid #ced4da;
  }
}

.arr-down {
  right: 0.75rem;
  top: 50%;
  width: 15px;
  height: 15px;
  transition: 0.3s;

  svg {
    transition: 0.3s;
  }

  &.closed {
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>