<template>
  <div class="modal">
    <div class="modal__title">
      <h3>Регистрация</h3>
      <div class="close-button" @click="close">
        <span></span>
      </div>
    </div>
    <div class="modal__body">
      <form class="form">
        <div class="form__row">
          <label class="form__label" for="regName">Имя:</label>
          <input
            name="name"
            class="form__input"
            type="text"
            placeholder="Иванов Иван"
            id="regName"
            v-model.trim="$v.name.$model"
            :class="{
              errorInput: $v.name.$anyError, 
              validInput: !$v.name.$anyError && $v.name.$dirty
            }"
          >
          <div class="error" v-if="!$v.name.required && $v.name.$dirty">Это обязательное поле</div>
          <div class="error" v-if="!$v.name.letters && $v.name.$dirty">Введите ФИО</div>
        </div>
        <div class="form__row">
          <label class="form__label" for="regCompany">Название компании:</label>
          <input
            name="company-name"
            class="form__input"
            type="text"
            placeholder="Название Компании"
            id="regCompany"
            v-model.trim="$v.companyName.$model"
            :class="{
              errorInput: $v.companyName.$anyError, 
              validInput: !$v.companyName.$anyError && $v.companyName.$dirty && $v.companyName.$model
            }"
          >
        </div>
        <div class="form__row">
          <label class="form__label" for="regPhone">Телефон:</label>
          <input
            name="login"
            class="form__input"
            type="text"
            placeholder="+7 (000) 000 00 00"
            id="regPhone"
            v-mask="'+7 (###) ### ## ##'"
            v-model.trim="$v.phone.$model"
            :class="{
              errorInput: $v.phone.$anyError, 
              validInput: !$v.phone.$anyError && $v.phone.$dirty
            }"
          >
          <div class="error" v-if="!$v.phone.required && $v.phone.$dirty">Это обязательное поле</div>
          <div class="error" v-if="!$v.phone.phoneNumber && $v.phone.$dirty">Введите номер телефона</div>
        </div>
        <div class="form__row">
          <label class="form__label" for="regPassword">Пароль:</label>
          <input
            name="password"
            class="form__input"
            type="password"
            id="regPassword"
            v-model.trim="$v.password.$model"
            :class="{
              errorInput: $v.password.$anyError, 
              validInput: !$v.password.$anyError && $v.password.$dirty && $v.password.$model
            }"
          >
          <div
            class="error"
            v-if="!$v.password.required && $v.password.$dirty"
          >Это обязательное поле</div>
          <div
            class="error"
            v-if="!$v.password.minLength && $v.password.$dirty"
          >Не менее {{$v.password.$params.minLength.min}} символов</div>
        </div>
        <div class="form__row">
          <label class="form__label" for="regPasswordConfirm">Подтверждение пароля:</label>
          <input
            name="password_confirmation"
            class="form__input"
            type="password"
            id="regPasswordConfirm"
            v-model.trim="$v.passwordConfirmation.$model"
            :class="{
              errorInput: $v.passwordConfirmation.$anyError, 
              validInput: !$v.passwordConfirmation.$anyError && $v.passwordConfirmation.$dirty && $v.passwordConfirmation.$model
            }"
          >
          <div
            class="error"
            v-if="!$v.passwordConfirmation.sameAsPassword && $v.passwordConfirmation.$dirty"
          >Новый пароль и подтверждение пароля не совпадают</div>
        </div>
        <div class="form__row form__row--btns">
          <button class="btn" @click.prevent="createUser">Регистрация</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { 
  required,
  helpers,
  sameAs,
  minLength
} from "vuelidate/lib/validators";
import axios from "axios";

const letters = helpers.regex("alpha", /[А-Яа-я_]/);
const phoneNumber = helpers.regex(
  "alpha",
  /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
);

export default {
  name: "RegModal",
  data() {
    return {
      name:                 null,
      companyName:          null,
      phone:                null,
      password:             null,
      passwordConfirmation: null
    };
  },
  methods: {
    close() {
      this.$store.commit("closeModal");
    },
    createUser() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json"
        }
      });

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        console.log(
          "phone: "                 + this.phone.replace(/\D/g, ''),
          "name: "                  + this.name,
          "password: "              + this.password,
          "password_confirmation: " + this.passwordConfirmation,
          "companyName: "           + this.companyName
        );
      }

      this.$store.commit('showPreloader');
      this.$store.commit('setNewUserPhone', this.phone);
      this.$store.commit("setNewUserData", {
        name: this.name,
        company: {
          name: this.companyName
        },
        phone: this.phone.replace(/\D/g, ''),
        password: this.password,
        password_confirmation: this.passwordConfirmation
      });

      // let sendObj = {
      //   phone:                 this.phone.replace(/\D/g, ''),
      //   name:                  this.name,
      //   password:              this.password,
      //   password_confirmation: this.passwordConfirmation,
      //   company: {
      //     name: this.companyName || "Не указано"
      //   }
      // };

      instance.post("/api/auth/create", this.$store.state.newUser)
              .then(response => {
                this.$store.commit('hidePreloader');

                if(response.data.result) {
                  this.$store.commit("openModal", "code-modal");
                }
              }).catch(error => {
                if (error.response.status === 401) {
                  this.$store.commit('createMessage', {type: 'danger', text: this.$store.state.messages.loginError});
                  this.$store.commit('forceLogout');
                }
                if (error.response.status === 422) {
                  alert('Ошибка 422');
                }
                if (error.response.status === 500) {
                  this.$store.commit('createMessage', {type: 'danger', text: this.$store.state.messages.serverError});
                }
              });
    }
  },
  validations: {
    name: { 
      required,
      letters
    },
    companyName: {},
    phone: { 
      required,
      phoneNumber
    },
    password: { 
      minLength: minLength(8),
      required
    },
    passwordConfirmation: { 
      sameAsPassword: sameAs("password")
    }
  }
};
</script>
