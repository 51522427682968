<template>
  <div class="modal-big">
    <div class="modal-big__header">
      <h3 class="modal-big__title">Информация о партнёре</h3>
       <div class="close-button" @click="close">
          <span></span>
        </div>
    </div>
    <div class="modal-big__body">
      <form action="#" class="modal-bog__form">
        <div class="form__row" v-if="data.company">
          <label for class="form__label">Название компании:</label>
          <!-- <input class="form__input" type="text" id="inputName" :value="`${data.company.name}`" disabled> -->
          <span>{{`${data.company.name}`}}</span>
        </div>

        <div class="form__row">
          <label class="form__label" for="inputName" >Префикс:</label>
          <input class="form__input" type="text" id="inputName" :value="`${data.name}`" disabled>
        </div>

        <div class="form__row">
          <label for class="form__label">Имя пользователя:</label>
          <input type="text" class="form__input" :value="`${data.user_info.name}`" disabled>
        </div>

        <div class="form__row">
          <label class="form__label" for="inputTel">Телефон:</label>
          <input
            class="form__input"
            type="text"
            id="inputTel"
            :value="data.phone ? `${data.phone}` : `Отсутствует`"
            disabled
          >
        </div>
        <div class="form__row">
          <label class="form__label" for="inputMail">E-Mail:</label>
          <input
            class="form__input"
            type="text"
            id="inputMail"
            :value="data.email ? `${data.email}` : `Отсутствует`"
            disabled
          >
        </div>
        <div class="form__row">
          <label class="form__label" for="inputProcent">Процентная ставка:</label>
          <input
            class="form__input"
            type="text"
            id="inputProcent"
            :value="`${data.user_info.persent}`"
            disabled
          >
        </div>
        <div class="form__row">
          <label class="form__label" for="inputConv">Конверсия:</label>
          <input
            class="form__input"
            type="text"
            id="inputConv"
            :value="`${data.conversion}`"
            disabled
          >
        </div>
        <div class="form__row">
          <label class="form__label" for="status">Статус:</label>
          <input class="form__input" type="text" id="status" :value="`${stage}`" disabled>
        </div>
        <div class="form__row form__row--requests requests" v-if="data.requests.length">
          <div class="requests__header" @click="requestsOpened = !requestsOpened">
            <div class="requests__title">Внешние запросы</div>
            <span class="arr-down" :class="{closed: !requestsOpened}">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 240.811 240.811"
                style="enable-background:new 0 0 240.811 240.811;"
                xml:space="preserve"
              >
                <path
                  id="Expand_More"
                  d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
                ></path>
              </svg>
            </span>
          </div>
          <transition name="slide">
            <div class="requests__body" v-show="requestsOpened">
              <PartnerRequest v-for="item in data.requests" :request="item"/>
            </div>
          </transition>
        </div>
      </form>
    </div>

    <DeletePartnerRequest :id="this.curRequest" v-if="this.curRequest" @updateData="updateData" @closeAcceptModal="closeAcceptModal"/>


  </div>
</template>

<script>
import PartnerRequest from "./PartnerRequests/PartnerRequests";
import DeletePartnerRequest from "./PartnerRequests/DeletePartnerRequest";

export default {
  name: "PartnerInfo",
  components: {
    PartnerRequest,
    DeletePartnerRequest
  },
  data() {
    return {
      requestsOpened: false,
      curRequest: null
    };
  },
  props: {
    data: Object
  },
  computed: {
    stage() {
      if (this.data.stage === 0) {
        return "Не прошел модерацию";
      }
      if (this.data.stage === 1) {
        return "Подтверждена модерация";
      }
      if (this.data.stage === 2) {
        return "Забанен";
      }
    }
  },
  methods: {
    updateData(id) {
      console.log(this.data.requests);
      this.data.requests.forEach((item, index) => {
        if(item.id === id) {
          this.data.requests.splice(index, 1);
        }
      });
    },
    closeAcceptModal() {
      this.curRequest =  null;
      console.log(1);
    },
    close() {
      this.$store.commit('closeModal');
    }
  },
};
</script>

<style lang="scss" >

.modal-big {
  .close-button {
    span {
      width: 20px;
      height: 20px;

      &:before,
      &:after {
        background-color: #000;
        width: 3px;
        border-radius: 3px;
      }
    }
  }
}

[disabled] {
  background-color: #fff;
}

.requests {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  // background-color: #fff;

  &__header {
    position: relative;
    padding: 0.75rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__body {
    // padding: .75rem;
    // border-top: 1px solid rgb(206, 212, 218);
  }

  table {
    border: 1px solid rgb(206, 212, 218);
    background-color: transparent;

    thead {
      color: inherit;
      background-color: transparent;

      tr {
        border-bottom: 1px solid rgb(206, 212, 218);
        background-color: transparent !important;
      }

      th {
        &:after {
          background-color: rgb(206, 212, 218) !important;
        }
      }
    }

    td,
    th {
      padding: 5px;
    }

    td {
      &:not(:first-child) {
        word-break: break-all;
      }
    }

    tr {
      background-color: transparent !important;
    }
  }
}

.request {
  border: 1px solid #ced4da;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding: 0.75rem;
  font-size: 14px;

  &__header {
    position: relative;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  &__controls {
    position: relative;
    margin-right: 20px;

    span {
      display: inline-block;
      width: 11px;
      height: 11px;

      &:not(:last-child) {
        margin-right: 10px;
      }
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__body {
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__name {
    margin-bottom: 5px;
    margin-top: 10px;
    padding-left: 10px;
  }

  &__row {
    // display: flex;
  }

  &__key,
  &__value {
    padding: 3px 5px;
    display: inline-block;
    flex: 1;
    border: 1px solid rgba(#ced4da, 0.5);
  }

  &__key {
    margin-right: 10px;
  }
}
</style>
