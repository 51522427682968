<template>
  <div class="modal-small">
    <div class="modal-small__header">
      <h3 class="modal-small__title">Удалить новость</h3>
    </div>
    <div class="modal-small__body">
      <div class="form__row">
        <span>Вы точно хотите удалить данную новость? Действие нельзя будет отменить.</span>
      </div>
      <div class="form__row form__row--btns">
        <button class="btn btn__success" @click.prevent="del">Да</button>
        <button class="btn btn__danger" @click.prevent="close">Нет</button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "DeleteNews",
    methods: {
      close() {
        this.$store.commit('closeModal');
      },
      del() {
        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.$store.state.access_token
          }
        });

        instance.post('/api/post/admin/news/delete', { id: this.$store.state.curNews.id })
                .then(response => {
                  this.$store.commit('delFromAllNews', { id: this.$store.state.curNews.id });
                  this.$store.commit('closeModal');
                })
                .catch(error => {
                  if (error.response.status === 401) {
                    this.$store.commit('forceLogout');
                  }
                  if(error.response.status === 422) {
                    alert('422 AddNews');
                  }
                  if(error.response.status === 500) {
                    alert('500 AddNews');
                  }
                });
      }
    }
  };
</script>

<style>
</style>
