<template>
  <div class="modal-big">
    <div class="modal-big__header">
      <h3 class="modal-big__title">Информация о запросе</h3>
        <div class="close-button" @click="close">
          <span></span>
        </div>
    </div>
    <div class="modal-big__body">
      <form action="#" class="modal-bog__form">
        <div class="form__row" v-if="data.name">
          <label for class="form__label">Название запроса:</label>
          <p class="form__input">{{data.name}}</p>
        </div>

        <div class="form__row" >
          <label class="form__label" for="inputName">Партнер:</label>
          <p class="form__input"  v-if="data.users">{{data.users.name || data.users.email}}</p>
          <p class="form__input"  v-else>Партнер не привязан</p> 
        </div>

        <div class="form__row">
          <label for class="form__label">Регионы:</label>
          <p class="form__input" v-if="data.departments.length">
            <span class="region" v-for="region in data.departments" :key="region.id">{{region.name}}</span>
          </p>
          <p class="form__input" v-else>Регион не назначен</p>
        </div>

        <div class="form__row">
          <label for class="form__label">Тело запроса:</label>
          <p class="form__input">
            <pre class="language-js">
              <code>
{{data.value}}
              </code>
            </pre>
          </p>
        </div>



      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestInfo",
  props: {
    data: Object
  },
  methods: {
    close() {
      this.$store.commit('closeModal');
    }
  },
}
</script>
<style lang="scss" scoped>
  .form__input {
    background-color: #fff;

    .region {
      display: block;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    .language-js {
      padding: 0;
      margin: 0;
      background-color: #fff;
    }

  }


</style>