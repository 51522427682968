<template>
  <transition name="fade">
    <div class="overlay" v-if="this.$store.state.openedModal" @click="close"></div>
  </transition>
</template>

<style lang="scss">

</style>

<script>
export default {
  name: "ModalOverlay",
  methods: {
    close() {
      this.$store.commit("closeModal");
    }
  }
};
</script>
<style lang="scss">
</style>


