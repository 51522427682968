var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-big"},[_vm._m(0),_c('div',{staticClass:"modal-big__body"},[_c('form',{staticClass:"modal-big__form form"},[_vm._m(1),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputName"}},[_vm._v("Имя:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.userName.$model),expression:"$v.userName.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.userName.$anyError, 
            validInput: !_vm.$v.userName.$anyError && _vm.$v.userName.$dirty && _vm.$v.userName.$model
          },attrs:{"type":"text","placeholder":"Иванов Иван Иванович","id":"inputName"},domProps:{"value":this.$store.state.user.name,"value":(_vm.$v.userName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userName, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputEmail"}},[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.userEmail.$model),expression:"$v.userEmail.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.userEmail.$anyError, 
            validInput: !_vm.$v.userEmail.$anyError && _vm.$v.userEmail.$dirty && _vm.$v.userEmail.$model
          },attrs:{"type":"text","placeholder":"mail@mail.com","id":"inputEmail"},domProps:{"value":this.$store.state.user.name,"value":(_vm.$v.userEmail.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userEmail, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.userEmail.email && _vm.$v.userEmail.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Некорректный формат email")]):_vm._e()]),_vm._m(2),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"passwordOld"}},[_vm._v("Старый пароль:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.passwordOld.$model),expression:"$v.passwordOld.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.passwordOld.$anyError || !_vm.$v.passwordOld.required, 
            validInput: !_vm.$v.passwordOld.$anyError && _vm.$v.passwordOld.$dirty && _vm.$v.passwordOld.$model
          },attrs:{"type":"password","id":"passwordOld"},domProps:{"value":(_vm.$v.passwordOld.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.passwordOld, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.passwordOld.minLength && _vm.$v.passwordOld.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Не менее "+_vm._s(_vm.$v.passwordOld.$params.minLength.min)+" символов")]):_vm._e(),(!_vm.$v.passwordOld.required)?_c('div',{staticClass:"error"},[_vm._v("Введите старый пароль")]):_vm._e()]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"passwordNew"}},[_vm._v("Новый пароль:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.passwordNew.$model),expression:"$v.passwordNew.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.passwordNew.$anyError || !_vm.$v.passwordNew.required, 
            validInput: !_vm.$v.passwordNew.$anyError && _vm.$v.passwordNew.$dirty && _vm.$v.passwordNew.$model
          },attrs:{"type":"password","id":"passwordNew"},domProps:{"value":(_vm.$v.passwordNew.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.passwordNew, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.passwordNew.minLength && _vm.$v.passwordNew.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Не менее "+_vm._s(_vm.$v.passwordNew.$params.minLength.min)+" символов")]):_vm._e(),(!_vm.$v.passwordNew.required)?_c('div',{staticClass:"error"},[_vm._v("Введите новый пароль")]):_vm._e()]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"passwordNewRepeat"}},[_vm._v("Подтверждение пароля:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.passwordNewRepeat.$model),expression:"$v.passwordNewRepeat.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorInput: _vm.$v.passwordNewRepeat.$anyError, 
            validInput: !_vm.$v.passwordNewRepeat.$anyError && _vm.$v.passwordNewRepeat.$dirty && _vm.$v.passwordNewRepeat.$model
          },attrs:{"type":"password","id":"passwordNewRepeat"},domProps:{"value":(_vm.$v.passwordNewRepeat.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.passwordNewRepeat, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.passwordNewRepeat.sameAsPassword && _vm.$v.passwordNewRepeat.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Новый пароль и подтверждение пароля не совпадают")]):_vm._e()]),_c('div',{staticClass:"form__row form__row--btns"},[_c('button',{staticClass:"btn btn__success",on:{"click":function($event){$event.preventDefault();return _vm.changeSettings($event)}}},[_vm._v("Сохранить")]),_c('button',{staticClass:"btn btn__danger",on:{"click":function($event){$event.preventDefault();return _vm.close($event)}}},[_vm._v("Отменить")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-big__header"},[_c('h3',{staticClass:"modal-big__title"},[_vm._v("Редактирование профиля")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form__row"},[_c('span',[_vm._v("Личные данные")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form__row"},[_c('span',[_vm._v("Смена пароля")])])}]

export { render, staticRenderFns }