<template>
  <transition name="fade">
    <!-- <div class="transition-loader" v-if="this.$store.state.preloaderIsVisible">
      <div class="transition-loader-inner">
        <label></label>
        <label></label>
        <label></label>
        <label></label>
        <label></label>
      </div>
    </div>-->

    <div class="transition-loader" v-if="this.$store.state.preloaderIsVisible">
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Preloader"
};
</script>

<style lang="scss">
.transition-loader {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#000, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

// .transition-loader-inner {
//   transform: translateY(-50%);
//   top: 50%;
//   position: absolute;
//   width: 100%;
//   color: #fff;
//   text-align: center;
//   label {
//     padding: 10px;
//     opacity: 0;
//     display: inline-block;
//     &:after {
//       content: "";
//       display: inline-block;
//       width: 10px;
//       height: 10px;
//       background-color: #fff;
//       border-radius: 50%;
//     }
//   }
// }

// $key-frames-ms: 600ms, 500ms, 400ms, 300ms, 200ms, 100ms;

// @each $current-ms in $key-frames-ms {
//   $i: index($key-frames-ms, $current-ms);
//   .transition-loader-inner label:nth-child(#{$i}) {
//     -webkit-animation: loader 3s $current-ms infinite ease-in-out;
//     animation: loader 3s $current-ms infinite ease-in-out;
//   }
// }

// @keyframes loader {
//   0% {
//     opacity: 0;
//     transform: translateX(-300px) scale(1);
//   }
//   33% {
//     opacity: 1;
//     transform: translateX(0px) scale(2);
//   }
//   66% {
//     opacity: 1;
//     transform: translateX(0px) scale(1);
//   }
//   100% {
//     opacity: 0;
//     transform: translateX(300px) scale(2);
//   }
// }

.lds-default {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-default div {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 29px;
  left: 53px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 18px;
  left: 50px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 9px;
  left: 41px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 6px;
  left: 29px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 9px;
  left: 18px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 18px;
  left: 9px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 29px;
  left: 6px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 41px;
  left: 9px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 50px;
  left: 18px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 53px;
  left: 29px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 50px;
  left: 41px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 41px;
  left: 50px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
</style>