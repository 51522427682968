var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"modal-big"},[_c('div',{staticClass:"modal-big__header"},[_c('h3',{staticClass:"modal-big__title"},[_vm._v("Добавить заявку")])]),_c('div',{staticClass:"modal-big__body"},[_c('form',{staticClass:"addOrder form modal-big__form",attrs:{"name":"addOrder"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputName"}},[_vm._v("ФИО:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.name.$model),expression:"$v.name.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
              errorInput: _vm.$v.name.$anyError, 
              validInput: !_vm.$v.name.$anyError && _vm.$v.name.$dirty
            },attrs:{"name":"name","type":"text","placeholder":"Иванов Иван Иванович","id":"inputName"},domProps:{"value":(_vm.$v.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.name.required && _vm.$v.name.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Это обязательное поле")]):_vm._e(),(!_vm.$v.name.letters && _vm.$v.name.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Введите ФИО")]):_vm._e()]),_c('div',{staticClass:"form__row form__row--multi"},[_c('div',{staticClass:"form__col"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputPhone"}},[_vm._v("Телефон:")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ### ## ##'),expression:"'+7 (###) ### ## ##'"},{name:"model",rawName:"v-model.trim",value:(_vm.$v.phone.$model),expression:"$v.phone.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
                errorInput: _vm.$v.phone.$anyError, 
                validInput: !_vm.$v.phone.$anyError && _vm.$v.phone.$dirty
              },attrs:{"name":"phone","type":"text","placeholder":"+7 (000) 000 00 00","id":"inputPhone"},domProps:{"value":(_vm.$v.phone.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.phone, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.phone.required && _vm.$v.phone.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Это обязательное поле")]):_vm._e(),(!_vm.$v.phone.phoneNumber && _vm.$v.phone.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Введите номер телефона")]):_vm._e()]),_c('div',{staticClass:"form__col"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputEmail"}},[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.email.$model),expression:"$v.email.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
                errorInput: _vm.$v.email.$anyError,
                validInput: !_vm.$v.email.$anyError && _vm.$v.email.$dirty &&  _vm.$v.email.$model
              },attrs:{"name":"email","type":"text","placeholder":"mail@mail.com","id":"inputEmail"},domProps:{"value":(_vm.$v.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.email.email && _vm.$v.email.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Некорректный формат email")]):_vm._e()])]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputType"}},[_vm._v("Тип заявки:")]),_c('multiselect',{class:{
              errorInput: _vm.$v.product.$anyError, 
              validInput: !_vm.$v.product.$anyError && _vm.$v.product.$dirty
            },attrs:{"name":"type","options":this.$store.state.filters.products,"label":"name","show-labels":false,"placeholder":"ПТС"},model:{value:(_vm.$v.product.$model),callback:function ($$v) {_vm.$set(_vm.$v.product, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.product.$model"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Совпадений не найдено")])]),(!_vm.$v.product.required && _vm.$v.product.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Это обязательное поле")]):_vm._e()],1),_c('div',{staticClass:"form__row form__row--multi"},[_c('div',{staticClass:"form__col"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputCity"}},[_vm._v("Город:")]),_c('multiselect',{class:{
                errorInput: _vm.$v.region.$anyError, 
                validInput: !_vm.$v.region.$anyError && _vm.$v.region.$dirty
              },attrs:{"name":"department","options":this.$store.state.filters.departments,"label":"name","show-labels":false,"placeholder":"Новосибирск"},model:{value:(_vm.$v.region.$model),callback:function ($$v) {_vm.$set(_vm.$v.region, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.region.$model"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Совпадений не найдено")])]),(!_vm.$v.region.required && _vm.$v.region.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Это обязательное поле")]):_vm._e()],1),_c('div',{staticClass:"form__col"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputAddress"}},[_vm._v("Адрес:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.address.$model),expression:"$v.address.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
                errorInput: _vm.$v.address.$anyError, 
                validInput: !_vm.$v.address.$anyError && _vm.$v.address.$dirty && _vm.$v.address.$model
              },attrs:{"name":"address","type":"text","placeholder":"ул. Каменская 51","id":"inputAddress"},domProps:{"value":(_vm.$v.address.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.address, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"inputSumm"}},[_vm._v("Желаемая сумма:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.summ.$model),expression:"$v.summ.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
              errorInput: _vm.$v.summ.$anyError, 
              validInput: !_vm.$v.summ.$anyError && _vm.$v.summ.$dirty && _vm.$v.summ.$model
            },attrs:{"name":"amount","type":"text","placeholder":"30 000","id":"inputSumm"},domProps:{"value":(_vm.$v.summ.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.summ, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.summ.numeric && _vm.$v.summ.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Введите сумму")]):_vm._e()]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label form__label--file btn",attrs:{"for":"inputFile"}},[_vm._v("Загрузить файлы")]),_c('input',{staticClass:"form__input form__input--file",attrs:{"name":"files[]","type":"file","id":"inputFile","multiple":""},on:{"change":_vm.uploadFiles}})]),(_vm.files.length)?_c('div',{staticClass:"form__row form__row--preview"},[_vm._l((_vm.files),function(file,index){return _c('div',{key:index,staticClass:"preview",attrs:{"title":file.name + ' ' + _vm.getFileSize(file.size)}},[_c('div',{staticClass:"preview__delete",on:{"click":function($event){return _vm.deleteFile(index)}}},[_c('span')]),_c('div',{staticClass:"preview__img",style:('background-image: url('+ _vm.setIcon(file) + ')')}),_c('div',{staticClass:"preview__name"},[_vm._v(_vm._s(file.name))])])}),(!_vm.validateFileSize(this.files))?_c('div',{staticClass:"preview__error"},[_c('p',{staticClass:"error"},[_vm._v("Размер загружаемых файлов не должен превышать 2 MB")])]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"form__row form__row--btns"},[_c('button',{staticClass:"btn btn__success",on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v("Добавить")]),_c('button',{staticClass:"btn btn__danger",on:{"click":function($event){$event.preventDefault();return _vm.close($event)}}},[_vm._v("Отменить")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }