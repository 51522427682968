<template>
  <div class="modal-small">
    <div class="modal-small__header">
      <h3 class="modal-small__title">Вы уверены?</h3>
    </div>
    <div class="modal-small__content">
      <p class="form__row">Удаленный блок невозможно будет восстановить.</p>

      <div class="form__row form__row--btns">
        <button class="btn btn__success" @click.prevent="$emit('deleteWebhook')">Удалить</button>
        <button class="btn btn__danger" @click.prevent="close">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteWebhookModal",
  methods: {
    close() {
      this.$store.commit("closeModal");
    }
  }
};
</script>

<style lang="scss">
</style>
