<template>
  <div class="documentation">
    <LeftMenu/>
    <main class="page-container">
      <MobileHeader/>
      <PageTitle :title="'Webhooks'"/>

      <div class="content">
        <div class="section">
          <div class="section__content">
            <div class="img">
              <img src="../../assets/images/example.png" alt>
            </div>
            <p>
              <b>Веб-хук (webhook)</b> — это отправка запросов по событиям из Партнерского кабинета.
              Запросы уведомляют сторонние сервисы об изменениях заявок. Позволяют отслеживать
              поступление заяврок, их состояние и другие события Sfgroup — информация отправляется
              на указанный URL.
            </p>
            <p>
              Для подключения веб-хуков необходимо указать адрес URL, куда будут отправляться
              POST-запросы. Для этого в панели управления перейдите в раздел Интеграции > Добавить
              и, в соответствующей форме введите Название и URL — адрес и нажмите «Добавить».
            </p>
          </div>
        </div>
        <div class="section section-1" id="param">
          <h3 class="section__title">Примеры запросов</h3>
          <div class="section__body">
            <div class="section__subtitle">Смена статуса</div>
            <div class="section__content">
              <table>
                <thead>
                  <th>№</th>
                  <th>Переменная</th>
                  <th>Тип</th>
                  <th>Описание</th>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>user_id</td>
                    <td>integer</td>
                    <td>Идентификатор партнера в системе</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>event</td>
                    <td>string</td>
                    <td>Тип события на данный момент поддерживается только события смены статуса</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>data</td>
                    <td>array|object</td>
                    <td>Массив параметров запроса</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>id</td>
                    <td>integer</td>
                    <td>Идентификатор заявки в системе</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>status_id</td>
                    <td>integer</td>
                    <td>Статус заявки в системе 1 — Новая заявка, 2 — в работе, 142 — успешная, 143 - закрыто</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="section__content">
<pre class="language-js"><code>
{
  "user_id": 1234,
  "event": "status",
  "data": {
    "id": 165537,
    "status_id" : 1
  },
}
</code></pre>
            </div>
          </div>
        </div>
      </div>
      <div class="right-nav" :class="{opened: rightNavIsOpened}">
        <div class="open-right-nav" @click="rightNavIsOpened = !rightNavIsOpened">
          <span class="icon" :class="{opened: rightNavIsOpened}">
            <svg
              viewBox="0 -44.499 86 90.501"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M80.093,22.749c1.353,1.338,3.544,1.338,4.896,0c1.35-1.338,1.352-3.506,0-4.847L45.445-21.246  c-1.352-1.338-3.541-1.338-4.892,0L1.013,17.902c-1.35,1.341-1.352,3.507,0,4.847c1.352,1.338,3.543,1.338,4.895,0l37.091-35.703  L80.093,22.749z"
              ></path>
            </svg>
          </span>
        </div>
        <ul>
          <li>
            <a href="#param">Параметр запроса</a>
          </li>
        </ul>
      </div>
    </main>

    <ModalOverlay/>
  </div>
</template>

<script>
import LeftMenu from "../Components/LeftMenu";
import PageTitle from "../Components/PageTitle";
import MobileHeader from "../Components/MobileHeader";
import ModalOverlay from "../Components/ModalOverlay";

export default {
  name: "Documentation",
  data() {
    return {
      rightNavIsOpened: false
    };
  },
  components: {
    LeftMenu,
    PageTitle,
    MobileHeader,
    ModalOverlay
  }
};
</script>