<template>
  <div class="modal">
    <div class="modal__title">
      <h3>Войти</h3>
      <div class="close-button" @click="close">
        <span></span>
      </div>
    </div>
    <div class="modal__body">
      <form class="form">
        <div class="form__row">
          <label class="form__label" for="loginName">Логин:</label>
          <input
            class="form__input"
            type="text"
            placeholder="Логин"
            v-model.trim="$v.login.$model"
            :class="{
              errorInput: $v.login.$anyError, 
              validInput: !$v.login.$anyError && $v.login.$dirty
            }"
          >
          <div class="error" v-if="!$v.login.required && $v.login.$dirty">Это обязательное поле</div>
        </div>
        <div class="form__row">
          <label class="form__label" for="loginName">Пароль:</label>
          <input
            class="form__input"
            type="password"
            placeholder="Пароль"
            v-model.trim="$v.password.$model"
            :class="{
              errorInput: $v.password.$anyError, 
              validInput: !$v.password.$anyError && $v.password.$dirty
            }"
          >
          <div
            class="error"
            v-if="!$v.password.required && $v.password.$dirty"
          >Это обязательное поле</div>
        </div>
        <div class="form__row">
          <input
            class="checkbox-input"
            v-model="remember_me"
            name="remember_me"
            id="remember_me"
            type="checkbox"
          >
          <label class="chekbox" for="remember_me">
            <span class="chekbox-label"></span>
            <span class="checkbox-text">Запомнить меня</span>
          </label>
        </div>
        <div class="form__row form__row--btns">
          <button class="btn" @click.prevent="submit">Войти</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios    from "axios";

export default {
  name: "LoginModal",
  data() {
    return {
      login:       '',
      password:    '',
      remember_me: false
    };
  },
  methods: {
    close() {
      this.$store.commit("closeModal");
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error");
      } else {
        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json"
          }
        });

        this.$store.commit('showPreloader');

        let sendObj = {
          login:       this.login,
          password:    this.password,
          remember_Me: this.remember_me
        };

        instance.post("/api/auth/login", sendObj)
                .then(response => {
                  let d = new Date(response.data.expires_at);
                  document.cookie = `access_token=${response.data.access_token};expires=${d.toUTCString()};path=/`;

                  this.$store.commit('setToken', response.data.access_token);

                  if (this.$store.state.access_token) {
                    this.$store.commit('hidePreloader');
                    
                    if (this.$store.state.openedModal === "login-modal") {
                      this.$store.commit("closeModal");
                    }
                    this.$store.commit('getData');
                    this.$router.push({ name: "leads" });
                  }
                })
                .catch(error => {
                  if (error.response.status === 401) {
                    this.$store.commit('createMessage', {type: 'danger', text: this.$store.state.messages.loginError});
                    this.$store.commit('forceLogout');
                  }
                  if (error.response.status === 422) {
                    alert('Ошибка 422');
                  }
                  if (error.response.status === 500) {
                    this.$store.commit('createMessage', {type: 'danger', text: this.$store.state.messages.serverError});
                  }
                });
      }
    }
  },
  validations: {
    login: {
      required
    },
    password: {
      required
    }
  }
};
</script>

<style lang="scss">
.checkbox-input {
  display: none;

  &:checked + .chekbox .chekbox-label {
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background: #fff
        url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJfeDIwMTRfX0l0ZW1fMV8iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCINCgkgeT0iMHB4IiB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTIgMTEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEyIDExIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJjaGVjay5zdmciPg0KCTxnPg0KCQk8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzJFMzY0MCIgZD0iTTExLjQ3NiwwLjE1MWMtMC4zNzctMC4yOTYtMC45OTctMC4xMzktMS4zNTksMC4zMjRMNS4xNTMsNi41Mw0KCQkJQzUuMDc3LDYuNjI0LDQuODg0LDYuNjU3LDQuNzg1LDYuNTk1TDEuNzIzLDQuNjg4QzEuMjQsNC4zNDIsMC42MjQsNC4zNjYsMC4zNDksNC43NTNjLTAuMjY4LDAuMzc4LTAuMTgsMC45OTQsMC4yMDksMS40NjQNCgkJCWwzLjUwOSw0LjIzNEM0LjM2MSwxMC44MDUsNC43NDIsMTEsNS4xNCwxMWMwLjQ2NSwwLDAuODk1LTAuMjY2LDEuMTc3LTAuNzI4bDUuMjY0LTguNjE1QzExLjkxNiwxLjEwOSwxMS44NzEsMC40NjIsMTEuNDc2LDAuMTUxDQoJCQl6Ii8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=)
        no-repeat;
      background-position: center;
      background-size: 80%;
    }
  }
}
.chekbox-label {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}
</style>


