<template>
  <div id="home-page">
    <div class="header-home-page">
      <div class="container">
        <header class="header-main">
          <div class="logo">
            <img
              class="logo__desktop"
              src="../../assets/images/homepage/logo.png"
              alt="Содействие Финанс Групп"
            >
            <img
              class="logo__mobile"
              src="../../assets/images/homepage/logo-mobile.png"
              alt="Содействие Финанс Групп"
            >
          </div>

          <nav class="nav">
            <ul class="menu">
              <li class="menu__item">
                <a href="#adv">Преимущества</a>
              </li>
              <li class="menu__item">
                <a href="#about">О компании</a>
              </li>
              <li class="menu__item">
                <a href="#how">Как это работает</a>
              </li>
              <li class="menu__item menu__item--btn" @click="openModal('login-modal')">
                <span>Вход</span>
              </li>
              <li class="menu__item menu__item--btn" @click="openModal('reg-modal')">
                <span>Регистрация</span>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </div>

    <main class="home-content">
      <div class="top">
        <div class="container">
          <div class="top__content">
            <div class="top__row">
              <h2 class="top__title">Начните зарабатывать с Содействием прямо сейчас!</h2>
              <p class="top__text">
                Присоединяйтесь к нашей партнерской программе -
                <span
                  class="bold"
                >легко, быстро и бесплатно</span>
              </p>
            </div>
            <div class="top__row">
              <h2 class="top__title">Самые высокие комиссии в залоговой тематике!</h2>
              <p class="top__text">
                <span class="bold">partners.sfgroup.ru</span> - это лучшая тематическая
                Партнерская программа для региональных сайтов, а также сайтов автомобильных тематик
              </p>
            </div>
            <div class="top__row">
              <span class="button register-btn" @click="openModal('reg-modal')">Регистрация</span>
            </div>
          </div>
        </div>
      </div>

      <div class="about" id="about">
        <div class="container">
          <div class="section-title">
            <h3>С МКК ООО "Содействие Финанс Групп" заработает любой желающий</h3>
          </div>

          <div class="section-content">
            <p>
              <span class="bold colored">partners.sfgroup.ru</span> - это лучшая тематическая Партнерская программа для сайтов региональной тематики,
              авто и смежных тематик. Ваши посетители будут эффективно конвертироваться в клиентов, если у вас портал,
              сервис или группа:
            </p>

            <div class="client">
              <div class="client__item">
                <div class="client__icon">
                  <img src="../../assets/images/homepage/icon-car.png" alt>
                </div>
                <div class="client__text">
                  Авто портал
                  <br>Форум об авто
                  <br>Доска объявлений
                </div>
              </div>

              <div class="client__item">
                <div class="client__icon">
                  <img src="../../assets/images/homepage/icon-money.png" alt>
                </div>
                <div class="client__text">
                  Сервис займов
                  <br>Портал о кредитах
                  <br>Форум о финансах
                </div>
              </div>

              <div class="client__item">
                <div class="client__icon">
                  <img src="../../assets/images/homepage/icon-web.png" alt>
                </div>
                <div class="client__text">
                  Региональный портал
                  <br>Новосибирска, Барнаула
                  <br>Тюмени, Омска, Челябинска
                  <br>Екатеринбурга, Новокузнецка
                  <br>
                </div>
              </div>
            </div>

            <p>
              <span class="bold">МК ООО "Содействие Финанс Групп</span> - занимает лидирующие позиции в залоговых займах и инвестициях под гарантии. Мы работаем в 7 регионах России.
              Мы подходим к проблемам наших клиентов комплексно и такое взаимовыгодное сотрудничество устраивает тысячи клиентов, которые уже стали нашими
              постоянными деловыми партнерами и охотно рекомендуют нас мвоим знакомым. МКК ООО "Содействие Финанс Групп", рег.номер ЦБ РФ 2120754001764
              от 10.07.12 г.
            </p>
          </div>
        </div>
      </div>

      <div class="third-section">
        <img src="../../assets/images/homepage/handshaking.jpg" alt>
      </div>

      <div class="how" id="how">
        <div class="container">
          <div class="section-title">
            <h3>Как это работает?</h3>
          </div>
          <div class="section-content">
            <p class="bold big-text">Нет ничего проще!</p>
            <p>Мы оптимизировали партнерский процесс, чтобы упростить ваши действия и повысить точность статистики.</p>
            <div class="steps">
              <div
                class="step"
              >Посетитель нажимает на партнерскую ссылку на вашем сайте или в электронной почте</div>
              <div
                class="step"
              >Покупатель автоматически записывается в нашей системе, как приведенный от Вас</div>
              <div
                class="step"
              >Посетитель просматривает страницы нашего сайта, Вы уже заработали 5 рублей за клик</div>
            </div>
          </div>
        </div>
      </div>

      <div class="fifth-section">
        <div class="container">
          <p>
            Если клиент совершит звонок или оставит заявку - Ваш заработок составит до
            <span
              class="bold"
            >1000 рублей</span> с клиента
          </p>
        </div>
      </div>

      <div class="advantages" id="adv">
        <div class="container">
          <div class="section-title">
            <h3>Преимущества партнерской программы</h3>
          </div>
          <div class="section-content">
            <div class="advantages-list">
              <div class="advantage">
                <div class="advantage__icon">
                  <img src="../../assets/images/homepage/icon-percent.png" alt>
                </div>
                <p class="advantage__text">
                  Самые высокие комиссии в залоговой тематике!
                  Оплата за клик, за звонок и за заявку
                </p>
              </div>
              <div class="advantage">
                <div class="advantage__icon">
                  <img src="../../assets/images/homepage/icon-money.png" alt>
                </div>
                <p class="advantage__text">
                  Ежемеячные
                  и индивидуальные выплаты любым
                  удобным для партнера способом
                </p>
              </div>
              <div class="advantage">
                <div class="advantage__icon">
                  <img src="../../assets/images/homepage/icon-link.png" alt>
                </div>
                <p class="advantage__text">
                  Deeplink на любую
                  страницу сайта
                </p>
              </div>
              <div class="advantage">
                <div class="advantage__icon">
                  <img src="../../assets/images/homepage/icon-hands.png" alt>
                </div>
                <p class="advantage__text">Индивидуальные условия</p>
              </div>
              <div class="advantage">
                <div class="advantage__icon">
                  <img src="../../assets/images/homepage/icon-chart.png" alt>
                </div>
                <p class="advantage__text">Прозрачная статистика</p>
              </div>
              <div class="advantage">
                <div class="advantage__icon">
                  <img src="../../assets/images/homepage/icon-bullhorn.png" alt>
                </div>
                <p class="advantage__text">
                  Эксклюзивные промо-материалы
                  Отрисовка баннеров под заказ
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <footer class="footer">
      <div class="container">
        <div class="social">
          <p class="social__title">Социальные сети:</p>
          <ul class="social__list">
            <li class="social__item">
              <a href="https://vk.com/finance.help.group" class="social__link">
                <span class="social__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    class="ui-icon__svg-wrapper"
                  >
                    <path
                      class="ui-icon__svg_fill"
                      d="M56.7 49.5v-.1c2.3-1 3.5-3.1 3.5-5.6 0-5.6-5.2-6-9.5-6h-9.9v26h10.8c5.1 0 10.3-2 10.3-8 0-3.4-2-5.6-5.2-6.3zm-8.9-7h1.9c1.9 0 3.9.2 3.9 2.6 0 2.7-2 2.9-4.1 2.9h-1.8v-5.5h.1zM50 59h-2.2v-6.5h3c2.3 0 4.5.3 4.5 3.2 0 3.1-2.9 3.3-5.3 3.3z"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>
            <li class="social__item">
              <a href="https://www.facebook.com/promotion.finance.group" class="social__link">
                <span class="social__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    class="ui-icon__svg-wrapper"
                  >
                    <path
                      class="ui-icon__svg_fill"
                      d="M60.5 32.5c-1.7-.6-4.8-.7-6.5-.7-5.1 0-7.2 4.1-7.2 8.9v3.2h-3v6.4h3V68H53V50.3h6v-6.4h-6v-3.8c0-1.6 2.6-2.4 3.7-2.4 1 0 2 .2 2.9.5l.9-5.7"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>
            <li class="social__item">
              <a href="https://twitter.com/FinanceAssist" class="social__link">
                <span class="social__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    class="ui-icon__svg-wrapper"
                  >
                    <path
                      class="ui-icon__svg_fill"
                      d="M63.8 41.8c1.5-.8 2.6-2.1 3.1-3.6-1.4.7-2.9 1.3-4.5 1.6-1.3-1.3-3.2-2-5.1-2-3.9 0-7 2.9-7 6.5 0 .5 0 1 .2 1.5-5.6-.4-10.9-2.8-14.5-6.9-.6 1-.9 2.1-.9 3.3 0 2.2 1.2 4.2 3.1 5.4-1.2 0-2.2-.3-3.2-.8v.1c-.1 3.1 2.3 5.7 5.6 6.3-.6.2-1.2.2-1.9.2-.4 0-.9 0-1.3-.1.9 2.6 3.6 4.5 6.5 4.5-2.5 1.8-5.5 2.8-8.7 2.8-.5 0-1.1 0-1.6-.1 3.2 1.9 6.9 2.9 10.8 2.9 8.1 0 14.9-4 18.2-10.9 1.1-2.4 1.8-4.9 1.8-7.5v-.9c1.4-.9 2.6-2 3.5-3.3-1.4.5-2.7.9-4.1 1z"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>
            <li class="social__item">
              <a href="https://ok.ru/sfgroup" class="social__link">
                <span class="social__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    class="ui-icon__svg-wrapper"
                  >
                    <path
                      class="ui-icon__svg_fill"
                      d="M56.3 52.2c-2 .9-4.1 1.5-6.3 1.5-2.3 0-4.1-.6-6.2-1.5-.4-.2-.8-.3-1.3-.3-1.5 0-2.7 1.2-2.7 2.6 0 2.6 4.5 3.5 6.6 4l-5.3 4.9c-.5.5-.8 1.1-.8 1.8 0 1.4 1.2 2.6 2.7 2.6.7 0 1.4-.2 1.9-.7l5-4.7 5 4.7c.5.5 1.2.7 1.9.7 1.5 0 2.7-1.2 2.7-2.6 0-.7-.3-1.3-.8-1.8l-5.3-4.9c2.2-.4 6.6-1.5 6.6-4 0-1.3-1.2-2.6-2.7-2.6-.2-.1-.6 0-1 .3M50.1 34.3c-5.3 0-9.2 4-9.2 8.7 0 4.6 4 8.7 9.2 8.7 5.3 0 9.2-4 9.2-8.7 0-4.6-4-8.7-9.2-8.7zm0 12.3c-2.2 0-3.8-1.7-3.8-3.6 0-1.9 1.7-3.6 3.8-3.6 2.2 0 3.8 1.7 3.8 3.6 0 1.9-1.7 3.6-3.8 3.6z"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>
            <li class="social__item">
              <a href="http://www.t.me/financehelpgroup" class="social__link">
                <span class="social__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    class="ui-icon__svg-wrapper"
                  >
                    <path
                      class="ui-icon__svg_fill"
                      d="M 63.401333,35.102717 35.108309,47.853835 c -1.929521,0.900914 -1.863114,2.001305 -0.240361,2.40387 l 7.306609,1.853594 3.173527,8.309909 c 0.380716,0.907669 0.233998,1.284135 1.204042,1.230487 0.748605,-0.04141 1.063686,-0.401977 1.460325,-0.833403 0.252204,-0.274801 1.750162,-1.896406 3.422728,-3.707098 l 7.759415,5.129988 c 1.414991,0.685585 2.38973,0.235973 2.650654,-1.43246 L 65.60393,37.326576 c 0.402454,-2.049551 -0.914845,-2.896268 -2.202597,-2.223859 z M 43.277963,51.531583 58.984852,40.376611 c 0.785092,-0.537027 1.540537,-0.313422 0.958045,0.262694 l -13.261398,13.329323 -0.253243,5.811678 z"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="copyright">
          © ООО МКК «Содействие финанс групп» 2018 Регистрационный номер записи в государственном реестре
          микрофинансовых организаций №2120754001764 .
          Выгодные займы под залог автотранспортных средств, ПТС (паспорт технического средства), недвижимости.
          Срочные кредиты под залог имущества. Интервал полной стоимости потребительского займа (ПСК): 48-83.5% годовых
        </div>
      </div>
    </footer>

    <ModalOverlay/>
    <LoginModal v-show="this.$store.state.openedModal=='login-modal'"/>
    <RegModal v-show="this.$store.state.openedModal=='reg-modal'"/>
    <CodeModal v-show="this.$store.state.openedModal=='code-modal'"/>
    <InfoMessage  :message="this.$store.state.message.text" :type="this.$store.state.message.type">
  </div>
</template>

<script>
import ModalOverlay from "../Components/ModalOverlay";
import LoginModal   from "./Modals/LoginModal";
import RegModal     from "./Modals/RegModal";
import CodeModal    from "./Modals/CodeModal";
import InfoMessage  from "../Components/InfoMessage";
import axios    from "axios";

export default {
  name: "LoginPage",
  components: {
    ModalOverlay,
    LoginModal,
    RegModal,
    InfoMessage,
    CodeModal
  },
  methods: {
    openModal(modalName) {
      this.$store.commit("openModal", modalName);
    }
  },
  mounted() {
    let matches = document.cookie.match(
      new RegExp(
        "(?:^|; )" +
        "access_token".replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
      )
    );
    
    let token = matches ? decodeURIComponent(matches[1]) : "";
    this.$store.commit("setToken", token);

    if (matches) {
      this.$router.push({ name: "leads" });
    }
  }
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

#home-page {
  .modal {
    padding: 0;
    height: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    position: fixed;
    background-color: #f5f5f5;
    z-index: 10;
    width: 90%;
    max-width: 400px;
    overflow: hidden;

    &__title {
      background-color: #e31c3b;
      padding: 20px 30px;
      margin: 0;
      color: #fff;
      text-align: left;
    }

    &__body {
      padding: 25px;
    }
  }

  .close-button {
    span:before,
    span:after {
      background-color: #fff;
    }
  }

  .btn {
    background-color: #e31c3b;
    color: #fff;
    border: none;
  }
}

.header-home-page {
  padding-top: 15px;
  padding-bottom: 15px;
}
.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  &__desktop {
    width: 200px;
  }
  &__mobile {
    display: none;
    width: 50px;
  }
}

.menu {
  font-size: 14px;
  font-weight: 500;

  &__item {
    display: inline-block;
    text-transform: uppercase;
    transition: 0.3s color;
    font-weight: 600;

    &:hover {
      color: #e31c3b;
    }

    &:not(:last-child) {
      margin-right: 1vw;
    }

    &--btn {
      border: 2px solid #e31c3b;
      padding: 5px 7px;
      border-radius: 5px;
      color: #e31c3b;
      font-weight: 600;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #fff;
        background-color: #e31c3b;
      }
    }
  }
}

.footer .container {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
}

.social {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__list {
    text-align: center;
  }
  &__item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__link {
    display: inline-block;
  }

  &__icon {
    display: block;
    width: 50px;
    height: 50px;
    border: 1px solid #74a3c7;
    border-radius: 50%;

    &:hover {
      border-color: #E72442;
      background-color: #fef3f5;

      svg {
        fill: #E72442;
      }
    }

    svg {
      width: 100%;
      fill: #74a3c7;
    }
  }
}

.copyright {
  text-align: justify;
  color: #94969a;
  font-size: 0.875em;
}

.top {
  background-image: url('../../assets/images/homepage/bgsuperman.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;

  &__content {
    width: 100%;
    max-width: 600px;
  }

  &__row {
    &:first-child {
      &:after {
        content: "";
        display: block;
        width: 100%;
        max-width: 250px;
        margin-top: 20px;
        height: 1px;
        background-color: #c8c8c8;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 40px;
    color: #e31c3b;
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 20px;
    padding-left: 20px;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: #e31c3b;
    }
  }

  .container {
    display: flex;
    justify-content: flex-end;
  }
}

.button {
  border-radius: 10px;
  background-color: #e31c3b;
  padding: 15px 20px;
  color: #fff;
  display: inline-block;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.how {
  padding-top: 45px;
  padding-bottom: 45px;
}

.steps {
  counter-reset: stepCount;
  display: flex;
  justify-content: space-between;

  .step {
    position: relative;
    padding-left: 60px;
    flex: 1;
    margin: 20px;

    &:before {
      counter-increment: stepCount;
      content: counter(stepCount);
      display: block;
      position: absolute;
      color: #e31c3b;
      font-size: 2.5em;
      font-weight: 600;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      left: 44px;
      top: 0;
      width: 2px;
      height: 100%;
      background-color: #878787;
    }
  }
}

.advantages {
  padding-top: 45px;
  padding-bottom: 45px;
}

.advantages-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.advantage {
  width: 30%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;

  &__icon {
    margin: 0 auto;
    width: 120px;
  }
}

.about {
  padding-top: 45px;
  padding-bottom: 45px;
}

.client {
  display: flex;
  justify-content: space-between;

  &__item {
    flex: 1;
    display: flex;
  }

  &__icon {
    width: 90px;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }

  &__text {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1023px) {
  .header-main {
    .menu {
      &__item:not(.menu__item--btn) {
        display: none;
      }
    }

    .logo {
      &__desktop {
        display: none;
      }

      &__mobile {
        display: initial;
      }
    }
  }

  .top {
    &__title {
      font-size: 25px;
    }
  }

  .client {
    &__item {
      flex-direction: column;
      align-items: center;
      margin: 10px;
      text-align: center;
    }

    &__icon {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 992px) {
  .top {
    background-image: url('../../assets/images/homepage/bg.jpg');

    &__content {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .header-home-page {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .home-content {
    padding-top: 70px;
  }

  .advantage {
    min-width: 120px;
    margin: 10px;

    &__icon {
      width: 70px;
    }
  }

  .steps {
    flex-direction: column;
    align-items: center;

    .step {
      margin: 20px;
    }
  }

  .social {
    flex-direction: column;

    &__title {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .client {
    flex-wrap: wrap;

    &__item {
      min-width: 150px;
    }
  }
}
</style>