<template>
  <div class="mobile-header">
    <div class="container">
      <button class="menu-toggle" @click="openModal('mobileMenu')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="40px">
          <path
            d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"
            font-weight="400"
            font-family="sans-serif"
            white-space="normal"
            overflow="visible"
          ></path>
        </svg>
      </button>

      <div class="right-block">
        <div class="purse" v-if="!this.$store.state.isAdmin">
          <span class="purse__icon">
            <svg
              version="1.1"
              id="Слой_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              style="enable-background:new 0 0 100 100;"
              xml:space="preserve"
            >
              <g id="XMLID_2_">
                <path
                  id="XMLID_11_"
                  d="M94.3,32.1H92l-3-16.3c-0.2-1.2-1.4-2-2.6-1.8l-7.1,1.3c0.1-0.5,0-1.1-0.3-1.6l-8-12.1c-0.7-1-2-1.3-3-0.6
                                    l-34,22.6l-27.5,5c-0.2,0-0.1,1.3,0.1,3.4H5.7c-1.5,0-2.6,1.2-2.6,2.7v61.7c0,1.5,1.2,2.7,2.6,2.7h88.5c1.5,0,2.6-1.2,2.6-2.7V34.9
                                    C96.9,33.3,95.7,32.1,94.3,32.1z M64.3,12.1c1-0.7,2.4-0.4,3,0.6l2.9,4.3l-18.5,3.4L64.3,12.1z M78.8,22.8c1.2-0.2,2.3,0.6,2.6,1.8
                                    l1.4,7.6H27.8L78.8,22.8z M94.3,73.5c0,1.7-1.3,3.1-2.8,3.1H64.1c-1.6,0-2.8-1.4-2.8-3.1V60.4c0-1.7,1.3-3.1,2.8-3.1h27.3
                                    c1.6,0,2.8,1.4,2.8,3.1V73.5z"
                ></path>
                <path
                  id="XMLID_1_"
                  d="M66.1,67.1c-0.1-3.1,2.6-5.7,5.7-5.7c3,0.1,5.6,2.6,5.5,5.7c-0.1,3-2.6,5.4-5.6,5.4
                                    C68.7,72.5,66.2,70.1,66.1,67.1z"
                ></path>
              </g>
            </svg>
          </span>
          {{ this.$store.state.balance ? this.$store.state.balance : 0 }} руб.
        </div>
        <div class="notice" @click="notificationsOpened = !notificationsOpened" v-if="!this.$store.state.isAdmin">
          <span class="notice__icon">
            <svg
              version="1.1"
              id="Слой_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              style="enable-background:new 0 0 100 100;"
              xml:space="preserve"
            >
              <path
                d="M88.4,74.3H88c-2.4-2.3-4.9-5.3-7-8.8c-4.5-7.3-5.6-14-5.7-18.6c0.2-1.5,0.3-3,0.3-4.5c0-14.6-8.5-26.7-19.9-29.9
                                c1-1.3,1.6-2.8,1.6-4.6c0-4.1-3.3-7.4-7.4-7.4S42.5,3.8,42.5,8c0,1.7,0.6,3.2,1.5,4.5c-11.6,3.1-20.2,15.4-20.2,30
                                c0,1.5,0.1,3,0.3,4.5c-0.1,4.7-1.2,11.4-5.7,18.6c-2.2,3.5-4.7,6.4-7,8.8v12.9H38c0,0.1,0,0.1,0,0.2c0,6.6,5.4,12,12,12
                                s12-5.4,12-12c0-0.1,0-0.1,0-0.2h26.6V74.3H88.4z M50.7,95.3c-2.5,1.6-5.8,0.9-7.5-1.6c-1.4-2.1-1.2-4.8,0.3-6.6
                                c0,1.4,0.3,2.7,1.1,3.9c1.7,2.5,4.7,3.6,7.5,2.9C51.7,94.4,51.3,94.9,50.7,95.3z"
              ></path>
            </svg>
          </span>
          <span class="notice__count" v-if="this.$store.state.notificationNews.count">
            {{this.$store.state.notificationNews.count}}
          </span>

          <Notifications v-if="notificationsOpened"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from "./Notifications";

export default {
  name: "MobileHeader",
  components: {
    Notifications
  },
  methods: {
    openModal(modalName) {
      this.$store.commit("openModal", modalName);
    }
  },
  data() {
    return {
      notificationsOpened: false
    };
  }
};
</script>

<style lang="scss">
.mobile-header {
  background-color: #263148;
  padding: 15px 0;
  display: none;
  height: 70px;
  z-index: 3;

  .container {
    display: flex;
    justify-content: space-between;

    .right-block {
      display: flex;

      .purse {
        background-color: rgba(#fff, 0.1);
        color: #fff;

        &__icon {
          fill: #fff;
        }
      }

      .notice {
        &__icon {
          fill: #fff;
        }
      }
    }
  }
}

.menu-toggle {
  display: flex;
  fill: #e4485d;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(#fff, 0.05);
  }
}

@media (max-width: 768px) {
  .mobile-header {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
  }
}
</style>
