<template>
  <div class="sidebar left" :class="{visible: this.$store.state.openedModal==='mobileMenu'}">
    <div class="sidebar__header">
      <router-link to="/leads" class="logo">
        <span class="logo__img">
          <img src="../../assets/logo.png" alt="Содействие Финанс Групп">
        </span>
        <span class="logo__title">Содействие</span>
      </router-link>
    </div>
    <div class="sidebar__body">
      <ul class="nav">
        <li class="nav__item">
          <router-link to="/leads" class="nav__link link">
            <span class="link__icon">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background:new 0 0 100 100;"
                xml:space="preserve"
              >
                <path
                  id="XMLID_12_"
                  d="M92.9,91.9c0-8.4,0-16.7,0-25.1c-7.2,0-14.4,0-21.7,0c-0.1,8.4-0.2,16.7-0.3,25.1h-6.3
                             c-0.1-19.3-0.2-38.6-0.3-57.9c-7.3,0-14.7,0-22,0c0.1,19.3,0.2,38.6,0.3,57.9h-6.3c0-14.1,0-28.2,0-42.3c-7.3,0-14.7,0-22,0
                             c0,14.1,0,28.2,0,42.3H8.1V23.7l8.9-5.9l12.6,7.6l0-0.1l0.1,0.1L50.3,7.7l29.2,34.2l0.8,1.3l14.3-9.4l-2-3.1l-11.5,7.5L50.7,2.5l0,0
                             l0,0l-2.5,2.2l-0.2,0.2l0,0L29.3,21l-12.3-7.4L17,13.5l-0.1,0l-0.1,0l-0.1,0.1l-8.7,5.7V0.9H0.8v91v7.2v0.1H99v-7.3H92.9z"
                ></path>
              </svg>
            </span>
            <span class="link__title">Сделки</span>
          </router-link>
        </li>

        <li
          class="nav__item"
          v-if="this.$store.state.userStage === 1 && !this.$store.state.isAdmin"
        >
          <router-link
            to="/integration"
            class="nav__link link"
            :class="{disabled: !this.$store.state.userStage}"
          >
            <span class="link__icon">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background:new 0 0 100 100;"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M91.9,60.2L74,41.8c-2.1-2.2-5.6-2.2-7.8,0l-2.8,2.8c-2.1,2.2-2.1,5.7,0,8l4.3,4.4c2.1,2.2,2.1,5.7,0,8l-1.9,1.9
                              c-2.1,2.2-5.6,2.2-7.8,0l-4.2-4.3c-2.1-2.2-5.6-2.2-7.8,0l-2.8,2.8c-2.1,2.2-2.1,5.7,0,8l17.9,18.4c7.2,7.4,17.8,8.7,26.2,4.2
                              l7.5-7.1C100.7,80.1,99.7,68.1,91.9,60.2z M87.6,68.1L69.5,86.7c-0.8,0.8-2,0.8-2.8,0l0,0c-0.8-0.8-0.8-2,0-2.9l18.2-18.6
                              c0.8-0.8,2-0.8,2.8,0C88.3,66,88.3,67.3,87.6,68.1z"
                  ></path>
                  <path
                    d="M48.5,53.4l4.5-4.6c1.7-1.7,1.7-4.7,0-6.4l-5.8-5.9c-1.7-1.7-1.7-4.7,0-6.4l4.1-4.2c1.7-1.7,1.7-4.7,0-6.4l-6.7-6.9
                              C37,4.8,25.4,3.9,16.9,9.5L8.8,1.4H1.5v7.2L9.7,17c-5,8.7-4,20.2,3.4,27.7l6.7,6.9c1.7,1.7,4.6,1.7,6.3,0l4.1-4.2
                              c1.7-1.7,4.6-1.7,6.3,0l5.8,5.9C43.9,55.2,46.7,55.2,48.5,53.4z M38.4,20.3L20.2,38.9c-0.8,0.8-2,0.8-2.8,0l0,0
                              c-0.8-0.8-0.8-2,0-2.9l18.2-18.6c0.8-0.8,2-0.8,2.8,0S39.2,19.5,38.4,20.3z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="link__title">Интеграция</span>
          </router-link>
        </li>

        <li class="nav__item" v-if="this.$store.state.userStage === 0">
          <div class="nav__link link disabled">
            <span class="link__icon">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background:new 0 0 100 100;"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M91.9,60.2L74,41.8c-2.1-2.2-5.6-2.2-7.8,0l-2.8,2.8c-2.1,2.2-2.1,5.7,0,8l4.3,4.4c2.1,2.2,2.1,5.7,0,8l-1.9,1.9
                              c-2.1,2.2-5.6,2.2-7.8,0l-4.2-4.3c-2.1-2.2-5.6-2.2-7.8,0l-2.8,2.8c-2.1,2.2-2.1,5.7,0,8l17.9,18.4c7.2,7.4,17.8,8.7,26.2,4.2
                              l7.5-7.1C100.7,80.1,99.7,68.1,91.9,60.2z M87.6,68.1L69.5,86.7c-0.8,0.8-2,0.8-2.8,0l0,0c-0.8-0.8-0.8-2,0-2.9l18.2-18.6
                              c0.8-0.8,2-0.8,2.8,0C88.3,66,88.3,67.3,87.6,68.1z"
                  ></path>
                  <path
                    d="M48.5,53.4l4.5-4.6c1.7-1.7,1.7-4.7,0-6.4l-5.8-5.9c-1.7-1.7-1.7-4.7,0-6.4l4.1-4.2c1.7-1.7,1.7-4.7,0-6.4l-6.7-6.9
                              C37,4.8,25.4,3.9,16.9,9.5L8.8,1.4H1.5v7.2L9.7,17c-5,8.7-4,20.2,3.4,27.7l6.7,6.9c1.7,1.7,4.6,1.7,6.3,0l4.1-4.2
                              c1.7-1.7,4.6-1.7,6.3,0l5.8,5.9C43.9,55.2,46.7,55.2,48.5,53.4z M38.4,20.3L20.2,38.9c-0.8,0.8-2,0.8-2.8,0l0,0
                              c-0.8-0.8-0.8-2,0-2.9l18.2-18.6c0.8-0.8,2-0.8,2.8,0S39.2,19.5,38.4,20.3z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="link__title">Интеграция</span>
          </div>
        </li>

        <li class="nav__item" v-if="this.$store.state.userStage === 1">
          <div
            class="nav__link link"
            @click="submenuOpened = !submenuOpened"
            :class="{disabled: !this.$store.state.userStage}"
          >
            <span class="link__icon">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="-549 451 100 100"
                style="enable-background:new -549 451 100 100;"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M-501.9,467.9c-0.9,0.2-5.8,0.5-10.3,3.2c-1.5,0.9-5,3.1-5.8,6.7c-0.2,0.5-1.1,5.3,2.5,7.1c1.3,0.7,3.6,0.9,5.3,0.2
                          c2.7-1.2,0.7-4.1,3.2-6.4c2.2-2.1,7.2-2.4,11-0.9c2.7,1.2,5.2,3.6,5,6c-0.2,1.7-1.8,2.4-5.8,5.6c0,0-6,5-8.5,8.6
                          c-0.2,0.2-0.5,0.9-0.7,1.5c-0.2,0.9-0.7,2.4,0,3.8c0.7,1.2,2.5,2.8,4.5,3v0.2c-3.6,0.5-6.5,2.8-6.5,5.8c0,3.2,3.6,6,7.8,6
                          s7.8-2.8,7.8-6c0-3.1-3.2-5.6-7-5.8c0-0.2,0-0.2,0-0.2c1.1-0.4,2-0.9,2.2-1.2c0.7-0.7,0.7-1.2,1.1-2.4c0.7-1.7,1.7-2.8,3.5-4.1
                          c2.2-1.9,4.3-3.1,4.5-3.2c5.3-3.4,7.2-8.8,7.4-9.3c0.5-1.2,2.2-7.3-2.5-12.4C-489.9,467-501.1,467.7-501.9,467.9z"
                  ></path>
                  <path
                    d="M-546.9,548.8c1.7-1,18.7-12.5,29.5-19.8c6.7,2.1,14.3,3,22.1,2.3c26-2.5,45.7-21.9,44.1-43.2c-0.2-2.9-0.9-5.5-1.7-8.1
                          c-0.3-0.7-0.5-1.4-0.8-2.1c0,0,0,0,0.1,0c-2.5-4.6-6.8-11.2-13.9-16.4c-9-6-20.8-9.1-33.6-7.9c-0.8,0-11,2-11,2.1
                          c-20.3,6-34.6,22.8-33.3,41.2c0.3,3.9,1.3,7.7,3,11.2C-543.8,519.2-546.7,544.9-546.9,548.8z M-538.9,535.3
                          c0.2-2.5,2.2-20.2,3.2-29.8c-1.6-3-2.5-6.2-2.8-9.8c-0.5-7.1,1.9-13.8,6.4-19.6c6.1-8.4,16.6-14.3,29-15.5c0.2,0,0.6,0,0.8,0
                          c0.3-0.2,0.8-0.2,1.1-0.2c21.9-2,40.6,10.4,41.9,27.8c1.3,17.5-15.4,33.2-37.2,35.2c-7.4,0.7-14.6-0.3-20.7-2.7
                          C-525.7,526.4-537.5,534.4-538.9,535.3z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="link__title">Документация</span>

            <span class="link__arr-down" :class="{opened: submenuOpened}">
              <svg
                viewBox="0 -44.499 86 90.501"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M80.093,22.749c1.353,1.338,3.544,1.338,4.896,0c1.35-1.338,1.352-3.506,0-4.847L45.445-21.246  c-1.352-1.338-3.541-1.338-4.892,0L1.013,17.902c-1.35,1.341-1.352,3.507,0,4.847c1.352,1.338,3.543,1.338,4.895,0l37.091-35.703  L80.093,22.749z"
                ></path>
              </svg>
            </span>
          </div>

          <transition name="slide">
            <ul v-if="submenuOpened">
              <li>
                <router-link
                  to="/documentation-api"
                  class="nav__sub-link link"
                  :class="{disabled: !this.$store.state.userStage}"
                >
                  <span class="link__title">Методы API</span>
                </router-link>
              </li>

              <li>
                <router-link
                  to="/documentation-webhooks"
                  class="nav__sub-link link"
                  :class="{disabled: !this.$store.state.userStage}"
                >
                  <span class="link__title">Webhooks</span>
                </router-link>
              </li>
            </ul>
          </transition>
        </li>

        <li class="nav__item" v-if="this.$store.state.userStage === 0">
          <div class="nav__link link disabled" @click="submenuOpened = !submenuOpened">
            <span class="link__icon">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="-549 451 100 100"
                style="enable-background:new -549 451 100 100;"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M-501.9,467.9c-0.9,0.2-5.8,0.5-10.3,3.2c-1.5,0.9-5,3.1-5.8,6.7c-0.2,0.5-1.1,5.3,2.5,7.1c1.3,0.7,3.6,0.9,5.3,0.2
                          c2.7-1.2,0.7-4.1,3.2-6.4c2.2-2.1,7.2-2.4,11-0.9c2.7,1.2,5.2,3.6,5,6c-0.2,1.7-1.8,2.4-5.8,5.6c0,0-6,5-8.5,8.6
                          c-0.2,0.2-0.5,0.9-0.7,1.5c-0.2,0.9-0.7,2.4,0,3.8c0.7,1.2,2.5,2.8,4.5,3v0.2c-3.6,0.5-6.5,2.8-6.5,5.8c0,3.2,3.6,6,7.8,6
                          s7.8-2.8,7.8-6c0-3.1-3.2-5.6-7-5.8c0-0.2,0-0.2,0-0.2c1.1-0.4,2-0.9,2.2-1.2c0.7-0.7,0.7-1.2,1.1-2.4c0.7-1.7,1.7-2.8,3.5-4.1
                          c2.2-1.9,4.3-3.1,4.5-3.2c5.3-3.4,7.2-8.8,7.4-9.3c0.5-1.2,2.2-7.3-2.5-12.4C-489.9,467-501.1,467.7-501.9,467.9z"
                  ></path>
                  <path
                    d="M-546.9,548.8c1.7-1,18.7-12.5,29.5-19.8c6.7,2.1,14.3,3,22.1,2.3c26-2.5,45.7-21.9,44.1-43.2c-0.2-2.9-0.9-5.5-1.7-8.1
                          c-0.3-0.7-0.5-1.4-0.8-2.1c0,0,0,0,0.1,0c-2.5-4.6-6.8-11.2-13.9-16.4c-9-6-20.8-9.1-33.6-7.9c-0.8,0-11,2-11,2.1
                          c-20.3,6-34.6,22.8-33.3,41.2c0.3,3.9,1.3,7.7,3,11.2C-543.8,519.2-546.7,544.9-546.9,548.8z M-538.9,535.3
                          c0.2-2.5,2.2-20.2,3.2-29.8c-1.6-3-2.5-6.2-2.8-9.8c-0.5-7.1,1.9-13.8,6.4-19.6c6.1-8.4,16.6-14.3,29-15.5c0.2,0,0.6,0,0.8,0
                          c0.3-0.2,0.8-0.2,1.1-0.2c21.9-2,40.6,10.4,41.9,27.8c1.3,17.5-15.4,33.2-37.2,35.2c-7.4,0.7-14.6-0.3-20.7-2.7
                          C-525.7,526.4-537.5,534.4-538.9,535.3z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="link__title">Документация</span>
          </div>
        </li>

        <li class="nav__item">
          <router-link :to="{ name: 'news' }" class="nav__link link">
            <span class="link__icon">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 442 442"
                style="enable-background:new 0 0 442 442;"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M171,336H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.523,0,10-4.477,10-10S176.523,336,171,336z"
                  ></path>
                  <path
                    d="M322,336H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,336,322,336z"
                  ></path>
                  <path
                    d="M322,86H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h252c5.522,0,10-4.477,10-10S327.522,86,322,86z"
                  ></path>
                  <path
                    d="M322,136H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,136,322,136z"
                  ></path>
                  <path
                    d="M322,186H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,186,322,186z"
                  ></path>
                  <path
                    d="M322,236H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,236,322,236z"
                  ></path>
                  <path
                    d="M322,286H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,286,322,286z"
                  ></path>
                  <path
                    d="M171,286H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.523,0,10-4.477,10-10S176.523,286,171,286z"
                  ></path>
                  <path
                    d="M171,136H70c-5.523,0-10,4.477-10,10v101c0,5.523,4.477,10,10,10h101c5.523,0,10-4.477,10-10V146
												C181,140.477,176.523,136,171,136z M161,237H80v-81h81V237z"
                  ></path>
                  <path
                    d="M422,76h-30V46c0-11.028-8.972-20-20-20H20C8.972,26,0,34.972,0,46v320c0,27.57,22.43,50,50,50h342c27.57,0,50-22.43,50-50
												V96C442,84.972,433.028,76,422,76z M422,366c0,16.542-13.458,30-30,30H50c-16.542,0-30-13.458-30-30V46h352v305
												c0,13.785,11.215,25,25,25c5.522,0,10-4.477,10-10s-4.478-10-10-10c-2.757,0-5-2.243-5-5V96h30V366z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="link__title">Новости</span>
          </router-link>
        </li>

        <li class="nav__item" v-if="this.$store.state.isAdmin">
          <router-link :to="{ name: 'partners' }" class="nav__link link">
            <span class="link__icon">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background:new 0 0 100 100;"
                xml:space="preserve"
              >
                <g>
                  <path
                    id="XMLID_8_"
                    d="M51.9,51.8c7-1,12.5-7,12.5-14.4c0-8-6.5-14.6-14.6-14.6s-14.6,6.5-14.6,14.6c0,7.3,5.4,13.4,12.5,14.4
												c-12.3,0.6-21.9,6.8-21.9,14.5v23.9h48V66.3C73.9,58.5,64.3,52.4,51.9,51.8z M54,76.9c0,0.2,0,0.5,0,0.8s0,0.4-0.1,0.6l-3.4,8.4
												c-0.3,0.8-1,0.8-1.3,0l-3.4-8.4c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.2-0.1-0.5,0-0.8l3-15.2c-0.2-0.1-0.4-0.3-0.6-0.6l-2.3-3.9
												C45,56,46,54.4,47.4,54.4h4.5c1.4,0,2.4,1.5,1.6,2.8l-2.3,3.9c-0.2,0.3-0.4,0.5-0.6,0.6L54,76.9z"
                  ></path>
                  <path
                    id="XMLID_6_"
                    d="M31.3,42.9c-0.4-1.2-0.6-2.4-0.9-3.7c-1.1-0.1-2.2-0.2-3.2-0.3c1.1-0.1,2.2-0.4,3.1-0.9
												c0-0.4-0.1-0.9-0.1-1.4c0-6.8,3.6-12.8,9-16.1c-1.7-6.2-7.3-10.7-14-10.7c-8,0-14.6,6.5-14.6,14.6c0,7.2,5.3,13.3,12.3,14.4
												c-0.6,0-1.2,0.1-1.8,0.2C10,40.2,1.3,46.1,1.3,53.4v23.9h19.5V67c0-1.4,0.2-2.8,0.6-4.1l0.4-1.9c1.1-1.9,2.7-4.3,5.5-6.4l0.1,0.3
												c0.5-0.4,1.1-0.9,1.8-1.3c0.2-0.1,1-0.6,2.1-1.2c1-0.5,2.6-1.3,4.6-2.1c-1.2-1.1-2.2-2.4-2.9-3.8C32.7,45.3,32.2,44,31.3,42.9z"
                  ></path>
                  <path
                    id="XMLID_7_"
                    d="M78.7,39.1c-0.6-0.1-1.2-0.2-1.8-0.2c6.9-1.1,12.3-7.1,12.3-14.4c0-8-6.5-14.6-14.6-14.6
												c-6.7,0-12.3,4.5-14,10.7c5.4,3.4,9,9.3,9,16.1c0,0.4,0,0.9-0.1,1.4c1,0.3,2.1,0.6,3.1,0.9c-1.1,0.1-2.2,0.2-3.2,0.3
												c-0.2,1.3-0.4,2.5-0.9,3.7c-0.9,1.1-1.4,2.4-1.7,3.7c-0.6,1.3-1.7,2.5-2.8,3.7c2.1,0.8,3.7,1.5,4.6,2.1c1.1,0.5,1.8,1.1,2.1,1.2
												c0.8,0.4,1.4,1,1.8,1.3l0.1-0.3c2.9,2.1,4.4,4.4,5.6,6.4l0.4,1.9c0.4,1.3,0.6,2.7,0.6,4.1v10.3h19.5V53.5
												C98.6,46.2,90.1,40.2,78.7,39.1z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="link__title">Партнёры</span>
          </router-link>
        </li>

        <li class="nav__item" v-if="this.$store.state.isAdmin">
          <router-link :to="{ name: 'requests' }" class="nav__link link">
            <span class="link__icon">
              <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <path d="M76.4,9.9c-0.7,0.7-1.3,1.3-1.8,2l-0.1-0.1c-0.1,0.1-0.3,0.2-0.2,0.4c-0.1,0.1,0,0.2-0.1,0.3c-0.6,1-1,1.9-1.4,3.1
                    c-0.9,1.7-2.3,3.5-4.6,5L65,16.9L64.9,17L51.3,1.7L4.9,42.7C2.1,45,2,49.1,4.4,51.7L19.8,69l-0.2,0.1l25.9,29.2l19.2-17.1
                    c-1.5-2.3-3.4-3.8-5.1-4.8c-1.1-0.4-2.1-0.8-3.1-1.4c-0.3-0.1-0.6-0.2-0.8-0.3c0.1-0.1,0.1-0.1,0.3-0.2c-0.8-0.5-1.4-1.2-2-1.8
                    c-4.2-4.7-3.8-11.9,1-16.1c4.7-4.2,11.9-3.8,16.1,1c0.7,0.7,1.1,1.5,1.7,2.1c0.1-0.1,0.3-0.2,0.3-0.2c0,0.2,0.1,0.5,0.2,1
                    c0.5,0.9,0.6,2,0.9,3.1c0.8,1.8,1.9,4,4.1,5.8l19.1-17L80.2,33.2c1.7-1.9,3.8-3.2,5.5-3.9c1-0.2,2.1-0.4,2.9-0.8
                    c0.5-0.5,1-0.7,1.6-0.8c0.6-0.3,1.1-0.9,1.7-1.3c4.7-4.2,5.1-11.5,1-16.1C88.3,5.9,81.1,5.6,76.4,9.9z"/>
                </g>
              </svg>
            </span>
            <span class="link__title">Реквесты</span>
          </router-link>
        </li>

        <li class="nav__item">
          <router-link :to="{ name: 'help' }" class="nav__link link">
            <span class="link__icon">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background:new 0 0 100 100;"
                xml:space="preserve"
              >
                <path
                  d="M52.6,11C27.3,11,6.7,26,6.7,44.6c0,3.4,0.7,6.7,2,9.8C6.6,64,2,86,1.4,89.1c1.7-0.7,19-9.3,30-14.6
	c6.4,2.4,13.6,3.8,21.2,3.8c25.4,0,45.9-15.1,45.9-33.7C98.6,26,78,11,52.6,11z M52.3,71.5c-7.3,0-14.2-1.4-20-3.9
	c-8.7,4.2-20.7,10.2-22,10.7c0.4-2.2,3.6-17.2,5.3-25.4c-1.3-2.7-2-5.6-2-8.7c0-6.1,2.8-11.7,7.6-16.3c6.5-6.7,17.2-11,29.3-11
	c0.2,0,0.5,0,0.7,0c0.3,0,0.7,0,1,0c21.4,0,38.7,12.2,38.7,27.3C91,59.3,73.7,71.5,52.3,71.5z"
                ></path>
              </svg>
            </span>
            <span class="link__title">Справка</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="sidebar__footer" @click="toggleUserTooltip" >
      <div
        class="user-avatar"
        :style="'background-color: ' + stringToColor(this.$store.state.user.name) + ';'"
      >{{ this.$store.state.user.name.substr(0, 2).toUpperCase()}}</div>

      <div class="user-info">
        <div class="user-name">{{ this.$store.state.user.name }}</div>
        <div class="user-id">id: {{ this.$store.state.userId }}</div>
      </div>

      <div class="user-warning" v-if="this.$store.state.userStage === 0">
        <div class="user-warning__icon">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 286.054 286.054"
            xml:space="preserve"
          >
            <g>
              <path
                style="fill:#E2574C;"
                d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
                c78.996,0,143.027-64.022,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236
                c-64.183,0-116.209-52.026-116.209-116.209S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209
                S207.21,259.236,143.027,259.236z M143.036,62.726c-10.244,0-17.995,5.346-17.995,13.981v79.201c0,8.644,7.75,13.972,17.995,13.972
                c9.994,0,17.995-5.551,17.995-13.972V76.707C161.03,68.277,153.03,62.726,143.036,62.726z M143.036,187.723
                c-9.842,0-17.852,8.01-17.852,17.86c0,9.833,8.01,17.843,17.852,17.843s17.843-8.01,17.843-17.843
                C160.878,195.732,152.878,187.723,143.036,187.723z"
              ></path>
            </g>
          </svg>
        </div>
        <div
          class="user-warning__message"
        >Ваш аккаунт не прошел модерацию, некоторые функции не доступны.</div>
      </div>
    </div>
    <div
      class="tooltip"
      v-show="this.$store.state.userTooltipOpened"
    >
      <div class="tooltip__row tooltip__row" v-if="!this.$store.state.isAdmin">
        <router-link class="tooltip__link" :to="{ name: 'settings' }">
          <span class="tooltip__text">Настройки</span>
          <span class="tooltip__icon">
            <svg
              version="1.1"
              id="Слой_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="-549 451 100 100"
              style="enable-background:new -549 451 100 100;"
              xml:space="preserve"
            >
              <path
                d="M-453.8,490h-6.8c-0.3,0-0.6,0-0.9,0.1c-0.8-3-1.9-5.9-3.4-8.5l6.2-6.2c1.6-1.6,1.6-4.2,0-5.9l-1.3-1.3l-1.9-2l-1.6-1.6
                                    l-1.6-1.6l-2-1.9l-0.9-0.9c-1.6-1.6-4.2-1.6-5.9,0l-5.6,5.6c-0.2,0.1-0.4,0.2-0.6,0.3c-2.7-1.5-5.6-2.7-8.6-3.5
                                    c0-0.2,0.1-0.4,0.1-0.6v-6.8c0-2.3-1.9-4.1-4.1-4.1h-0.5c0,0,0-0.1-0.1-0.1h-3.6h-2.2h-2.2h-3.6c0,0,0,0.1-0.1,0.1h-0.2
                                    c-2.3,0-4.1,1.9-4.1,4.1v6.8c0,0.2,0,0.3,0.1,0.5c-3.1,0.8-6.3,2-8.9,3.6c-0.3-0.1-0.6-0.3-0.9-0.4l-5.7-5.7c-1.6-1.6-4.2-1.6-5.9,0
                                    l-9.2,9.4c-1.6,1.6-1.6,4.2,0,5.9l6.4,6.4c-1.4,2.5-2.5,5.4-3.4,8.2c-0.3-0.1-0.5-0.1-0.8-0.1h-6.8c-2.3,0-4.1,1.9-4.1,4.1v0.3v3.6
                                    v2.2v2.2v3.6v0.5c0,2.3,1.9,4.1,4.1,4.1h6.8c0.2,0,0.4,0,0.6-0.1c0.8,3.2,2.2,6.2,3.7,9c-0.1,0.1-0.1,0.3-0.2,0.4l-6.3,6.5
                                    c-1.6,1.6-1.6,4.2,0,5.9l9.2,9.2c1.6,1.6,4.2,1.6,5.9,0l6.6-6.6c2.8,1.5,5.7,2.8,8.8,3.5c-0.1,0.3-0.1,0.6-0.1,0.9v6.8
                                    c0,2.3,1.9,4.1,4.1,4.1h12.5c2.3,0,4.1-1.9,4.1-4.1v-6.8c0-0.3-0.1-0.7-0.1-1c2.9-0.9,5.7-2,8.4-3.4l6.7,6.7c1.6,1.6,4.2,1.6,5.9,0
                                    l9.2-9.2c1.6-1.6,1.6-4.2,0-5.9l-5.6-5.6c-0.2-0.5-0.5-1-0.7-1.5c1.5-2.8,2.8-5.7,3.6-8.6c0.3,0.1,0.6,0.1,0.9,0.1h6.8
                                    c2.3,0,4.1-1.9,4.1-4.1v-12.5C-449.7,491.8-451.5,490-453.8,490z M-499.8,522.2c-12.2,0-22-9.8-22-22s9.8-22,22-22s22,9.8,22,22
                                    S-487.6,522.2-499.8,522.2z"
              ></path>
            </svg>
          </span>
        </router-link>
      </div>
      <div class="tooltip__row tooltip__row">
        <div class="tooltip__link" :to="{ name: 'settings' }" @click="logOut">
          <span class="tooltip__text">Выход</span>
          <span class="tooltip__icon">
            <svg
              version="1.1"
              id="Слой_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="-549 451 100 100"
              style="enable-background:new -549 451 100 100;"
              xml:space="preserve"
            >
              <g>
                <path
                  id="XMLID_1_"
                  d="M-500.5,504.3h3c1.5,0,2.7-1.2,2.7-2.7v-46.8c0-1.5-1.2-2.7-2.7-2.7h-3c-1.5,0-2.7,1.2-2.7,2.7v46.9
                      C-503.1,503.1-501.9,504.3-500.5,504.3z"
                ></path>
                <path
                  id="XMLID_4_"
                  d="M-488.5,459.9v8.7c15.4,4.5,26.7,18.8,26.7,35.7c0,20.6-16.7,37.2-37.2,37.2c-20.6,0-37.2-16.7-37.2-37.2
                      c0-16.9,11.3-31.2,26.7-35.7v-8.7c-20.1,4.8-35.1,22.8-35.1,44.4c0,25.2,20.4,45.6,45.6,45.6c25.2,0,45.6-20.4,45.6-45.6
                      C-453.4,482.7-468.3,464.7-488.5,459.9z"
                ></path>
              </g>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";


export default {
  name: "LeftMenu",
  data() {
    return {
      leftMenuOpened: false,
      submenuOpened: false
    };
  },
  methods: {
    logOut() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });

      instance
        .get("/api/auth/logout")
        .then(response => {
          this.$store.state.access_token = undefined;
          document.cookie =
            "access_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
          this.$router.push({ name: "home" });
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 422) {
            alert("Ошибка 422");
          }
          if (error.response.status === 500) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
          }
        });
    },
    stringToColor(str) {
      let hash = 0;
      let color = "#";
      let i;
      let value;
      let strLength;

      if (!str) {
        return color + "333333";
      }

      strLength = str.length;

      for (i = 0; i < strLength; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      for (i = 0; i < 3; i++) {
        value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
      }

      return color;
    },
    toggleUserTooltip() {
      if(!this.$store.state.userTooltipOpened) {
        this.$store.commit("openUserTooltip");
        console.log(this.$store.state.userTooltipOpened);
      }
    
    }
  },
};
</script>

<style lang="scss">
.router-link-active:not(.logo) {
  color: lighten(#e4485d, 10%);
}

.tooltip {
  position: absolute;
  left: calc(100% + 15px);
  bottom: 10px;
  z-index: 10;
  background: #fff;
  border-radius: 5px;
  color: #000;
  padding: 20px 25px;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);

  &__row {
    position: relative;
    color: #419ef9;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    fill: #e4485d;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__link {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #ffffff transparent transparent;
  }

  &.active {
    display: block;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

$background: #263148;

.sidebar {
  width: 280px;
  position: fixed;
  top: 0;
  left: 0;
  background: $background;
  color: #fff;
  height: 100%;
  transition: width 0.3s;
  z-index: 3;
  border-right: 1px solid rgba(0, 0, 0, 0.0625);
  padding-bottom: 90px;

  &__header {
    overflow: hidden;
    height: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  }

  &__body {
    overflow: hidden;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    word-break: keep-all;
  }
}

.logo {
  display: flex;
  align-items: center;
  padding: 5px 10px;

  &__img {
    display: inline-block;
    width: 55px;
    min-width: 55px;

    img {
      width: 100%;
    }
  }

  &__title {
    font-size: 1.2em;
    font-weight: 600;
    letter-spacing: 2px;
    padding-left: 10px;
  }
}

.nav {
  &__sub-link {
    font-size: 0.875em;
    .link__title {
      margin-left: 20px;
    }
  }
}

.link {
  cursor: pointer;
  position: relative;
  line-height: 2;
  display: block;
  padding: 5px 20px;
  padding-left: 45px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 32px;
  box-sizing: content-box;

  &:hover {
    background: lighten($background, 5%);
  }

  &__icon {
    display: block;
    fill: #e4485d;
    line-height: 20px;
    font-size: 20px;
    width: 22px;
    text-align: center;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__title {
    display: inline-block;
    font-weight: 600;
    font-size: 1em;
    padding-left: 20px;
  }

  &__arr-down {
    display: inline-block;
    width: 15px;
    position: absolute;
    right: 30px;
    fill: #aaa;
    transition: transform 0.3s;
    transform: rotate(180deg);

    svg {
      position: relative;
      top: 3px;
    }

    &.opened {
      transform: rotate(0);
    }
  }
  &.disabled {
    color: #eee;
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: transparent;
    }

    svg {
      fill: #eee;
    }
  }
}

.user {
  &-avatar {
    display: inline-block;
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    border-radius: 50%;
    background-color: lightskyblue;
    line-height: 50px;
    color: #fff;
    text-align: center;
    
  }

  &-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }

  &-name {
    margin-bottom: 5px;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-id {
    color: #999;
    white-space: nowrap;
  }

  &-warning {
    margin-left: 10px;
    display: flex;
    position: relative;

    &:hover > .user-warning__message {
      display: block;
    }

    &__message {
      display: none;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: calc(100% + 10px);
      width: 185px;
      padding: 10px 15px;
      font-size: 14px;
      border-radius: 5px;
      bottom: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
      z-index: 20;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 20px 10px 0 10px;
        border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 20px;
      width: 20px;
    }

    svg {
      width: 100%;
    }
  }
}

@media (max-width: 1439px) {
  .sidebar {
    width: 70px;

    .link,
    .logo {
      &__title {
        display: none;
      }
    }

    .link {
      &__arr-down {
        display: none;
      }
    }

    .user-info,
    .user-warning {
      display: none;
    }

    &:hover {
      width: 280px;

      .link,
      .logo {
        &__title {
          display: initial;
        }
      }

      .link {
        &__arr-down {
          display: initial;
        }
      }

      .user-warning {
        display: flex;
      }

      .user-info {
        display: flex;
      }
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 250px;
    left: -100%;
    transition: left 0.3s;
    z-index: 11;

    &.visible {
      left: 0;
    }

    .link,
    .logo {
      &__title {
        display: initial;
      }
    }
    .user-info {
      display: flex;
    }

    &:hover {
      width: 250px;
    }
  }

  .tooltip {
    padding: 15px 12px;
    &__icon {
      position: static;
      width: 20px;
      height: 20px;
    }
    &__text {
      display: none;
    }
    &__link {
      padding-left: 0;
    }
  }
}

.slide-enter-active {
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>   