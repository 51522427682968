<template>
  <div class="add-news edit-news">
    <div class="add-news__header">
      <div @click="close" class="close-button">
        <span></span>
      </div>
      <label for="titleNews" class="add-news__label">
        Заголовок:
        <input id="titleNews" class="add-news__input" v-model="title">
      </label>
    </div>
    <div class="add-news__content">
      <div class="add-news__description">
        <vue-editor v-model="text" :editorOptions="options"/>
      </div>
     <div class="add-news__btns">
        <span @click="handle" class="edit-btn btn btn__success">Сохранить</span>
        <span @click="close" class="edit-btn btn btn__danger">Отменить</span>
     </div>
    </div>
  </div>
</template>

<script>
  import { VueEditor, Quill } from 'vue2-editor';
  import axios from 'axios';

  export default {
    name: "EditNews",
    components: { 
      VueEditor
    },
    data() {
      return {
        text: this.$store.state.curNews.text,
        title: this.$store.state.curNews.title,
        options: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'align': [] }],
              ['blockquote', 'code-block'],
              ['clean'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],              
              ['link', 'image', 'video'],
            ]
          }
        }
      };
    },
    methods: {
      handle() {
        // TODO: Обработка изменения новости
        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.$store.state.access_token
          }
        });

        let sendObj = {
          id: this.$store.state.curNews.id,
          title: this.title,
          body: this.text
        };

        instance.post("api/post/admin/news/update", sendObj)
                .then(response => {
                  for (let i in this.$store.state.allNews) {
                    if (this.$store.state.allNews[i].id === sendObj.id) {
                      let data = this.$store.state.allNews[i];
                      data.title = sendObj.title;
                      data.text = sendObj.body;
                      this.$store.commit('editNewsInAllNews', {index: i, data: data});
                      this.$store.commit('closeModal');
                      break;
                    }
                  }
                })
                .catch(error => {
                  if (error.response.status === 401) {
                    this.$store.commit('forceLogout');
                  }
                  if (error.response.status === 422) {
                    alert('422 EditNews');
                  }
                  if (error.response.status === 500) {
                    alert('500 EditNews');
                  }
                });
      },
      close() {
        this.$store.commit('closeModal');
      }
    }
  };
</script>

<style lang="scss">
.edit-news {
  width: 100vw !important;
  height: 100vh !important;
  max-width: none !important;

  svg {
    fill: lightgrey;
    transition: fill 0.5s;

    &:hover {
      fill: black;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 10px;

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 18px;
    padding: 5px;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__description {
    font-size: 18px;
    margin-bottom: 10px;
    word-break: break-all;
    width: 57%;
  }
}
</style>
