<template>
  <div class="modal-big">
    <div class="modal-big__header">
      <h3 class="modal-big__title">Изменить данные партнёра</h3>
    </div>
    <div class="modal-big__body">
      <form class="modal-big__form form">
        <div class="form__row form__row--header" @click="dataOpened = !dataOpened">
          <p>Личные данные</p>
          <span class="arr-down" :class="{closed: !dataOpened}">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 240.811 240.811"
              style="enable-background:new 0 0 240.811 240.811;"
              xml:space="preserve"
            >
              <path
                id="Expand_More"
                d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
              ></path>
            </svg>
          </span>
        </div>
        <transition name="slide">
          <div class="form__group" v-if="dataOpened">
            <div class="form__row">
              <label class="form__label" for="inputFIO">Имя:</label>
              <input
                v-model.trim="$v.userName.$model"
                class="form__input"
                type="text"
                :placeholder="`${this.partner.user_info.name}`"
                id="inputFIO"
                :class="{
              errorInput: $v.userName.$anyError, 
              validInput: !$v.userName.$anyError && $v.userName.$dirty && $v.userName.$model
            }"
                autocomplete="off"
              >
            </div>
            <div class="form__row">
              <label class="form__label" for="inputEmail">Email:</label>
              <input
                v-model.trim="$v.userEmail.$model"
                class="form__input"
                type="text"
                :placeholder="this.partner.email ? `${this.partner.email}` : `E-mail`"
                id="inputEmail"
                :class="{
              errorInput: $v.userEmail.$anyError || !$v.userEmail.required, 
              validInput: !$v.userEmail.$anyError && $v.userEmail.$dirty && $v.userEmail.$model
            }"
                autocomplete="off"
              >
              <div
                class="error"
                v-if="!$v.userEmail.email && $v.userEmail.$dirty"
              >Некорректный формат email</div>
              <div
                class="error"
                v-if="!$v.userEmail.required"
              >Укажите email</div>
            </div>
            <div class="form__row">
              <label class="form__label" for="inputPrefix">Префикс:</label>
              <input
                v-model.trim="$v.prefix.$model"
                class="form__input"
                type="text"
                :placeholder="`${this.partner.name}`"
                id="inputPrefix"
                :class="{
              errorInput: $v.prefix.$anyError, 
              validInput: !$v.prefix.$anyError && $v.prefix.$dirty && $v.prefix.$model
            }"
                autocomplete="off"
              >
            </div>
            <div class="form__row">
              <label class="form__label" for="inputStake">Процентная ставка:</label>
              <input
                type="text"
                id="inputStake"
                v-model.trim="$v.userProcent.$model"
                class="form__input"
                :placeholder="`${this.partner.user_info.persent}`"
                :class="{
              errorInput: $v.userProcent.$anyError, 
              validInput: !$v.userProcent.$anyError && $v.userProcent.$dirty && $v.userProcent.$model
            }"
                autocomplete="off"
              >
              <div
                class="error"
                v-if="!$v.userProcent.procent && $v.userProcent.$dirty"
              >Поле должно быть числовым</div>
            </div>
            <div class="form__row">
              <label class="form__label" for="inputStatus">Состояние:</label>
              <multiselect
                v-model="stage"
                :options="options"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                track-by="id"
                label="label"
                placeholder="Состояние"
              >
            </div>
          </div>
        </transition>

        <div class="form__row form__row--header" @click="passwordOpened = !passwordOpened">
          <p>Смена пароля</p>
          <span class="arr-down" :class="{closed: !passwordOpened}">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 240.811 240.811"
              style="enable-background:new 0 0 240.811 240.811;"
              xml:space="preserve"
            >
              <path
                id="Expand_More"
                d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
              ></path>
            </svg>
          </span>
        </div>
        <transition name="slide">
          <div class="form__group" v-if="passwordOpened">
            <div class="form__row">
              <label class="form__label" for="passwordNew">Новый пароль:</label>
              <input
                v-model.trim="$v.passwordNew.$model"
                class="form__input"
                type="password"
                id="passwordNew"
                :class="{
              errorInput: $v.passwordNew.$anyError, 
              validInput: !$v.passwordNew.$anyError && $v.passwordNew.$dirty && $v.passwordNew.$model
            }"
                autocomplete="off"
              >
              <div
                class="error"
                v-if="!$v.passwordNew.minLength && $v.passwordNew.$dirty"
              >Не менее {{$v.passwordNew.$params.minLength.min}} символов</div>
            </div>
            <div class="form__row">
              <label class="form__label" for="passwordNewRepeat">Подтверждение пароля:</label>
              <input
                v-model.trim="$v.passwordNewRepeat.$model"
                class="form__input"
                type="password"
                id="passwordNewRepeat"
                :class="{
              errorInput: $v.passwordNewRepeat.$anyError, 
              validInput: !$v.passwordNewRepeat.$anyError && $v.passwordNewRepeat.$dirty && $v.passwordNewRepeat.$model
            }"
                autocomplete="off"
              >
              <div
                class="error"
                v-if="!$v.passwordNewRepeat.sameAsPassword && $v.passwordNewRepeat.$dirty"
              >Новый пароль и подтверждение пароля не совпадают</div>
            </div>
          </div>
        </transition>

        <div class="form__row">
          <input
            class="checkbox-input"
            name="sendOrdersEdit"
            id="sendOrdersEdit"
            type="checkbox"
            v-model="sendOrders"
          >
          <label class="chekbox" for="sendOrdersEdit">
            <span class="chekbox-label"></span>
            <span class="checkbox-text">Отправка заявок на email</span>
          </label>
        </div>

        <div class="form__row" v-if="sendOrders">
          <label class="form__label">Регион:</label>
          <multiselect
            v-model.trim="regions"
            :options="this.$store.state.filters.departments"
            :multiple="true"
            label="name"
            value="id"
            track-by="name"
            :show-labels="false"
            placeholder="Новосибирск"
          >
            <span slot="noResult">Совпадений не найдено</span>
          </multiselect>
        </div>

        <div class="form__row">
          <span class="btn" @click="openRequestSettings">Добавить внешний запрос</span>
        </div>

        <div class="form__row form__row--btns">
          <span class="btn btn__success" @click.prevent="update">Сохранить</span>
          <span class="btn btn__danger" @click.prevent="close">Отменить</span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  numeric,
  helpers,
  minLength,
  sameAs,
  between,
  requiredIf
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import axios from "axios";

const phoneNumber = helpers.regex(
  "alpha",
  /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
);

const procent = helpers.regex("alpha", /([0-9]*[.])?[0-9]+/);

export default {
  name: "EditPartner",
  components: {
    Multiselect
  },
  props: {
    partner: Object
  },
  data() {
    return {
      userName: null,
      userEmail: null,
      prefix: null,
      userProcent: null,
      passwordNew: null,
      passwordNewRepeat: null,
      stage: null,
      options: [
        {
          label: "Подтверждена модерация",
          id: 1
        },
        {
          label: "Не прошел модерацию",
          id: 0
        },
        {
          label: "Забанен",
          id: 2
        }
      ],
      sendOrders: false,
      regions: [],

      requestSettings: false,
      dataOpened: true,
      passwordOpened: true,
      authOpened: true,
      headerOpened: true,
      bodyOpened: true
    };
  },
  methods: {
    close() {
      this.$store.commit("closeModal");
    },
    update() {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });

      this.$store.commit("showPreloader");

      let data = {
        id: this.partner.id,
        user: {
          phone: this.partner.phone
        },
        user_info: {},
        email: {}
      };

      this.userName ? (data.user_info.name = this.userName) : undefined;
      this.userProcent
        ? (data.user_info.persent = this.userProcent)
        : undefined;
      this.userEmail ? (data.user.email = this.userEmail) : undefined;
      this.prefix ? (data.user.name = this.prefix) : undefined;
      this.stage ? (data.user.stage = this.stage.id) : undefined;
      this.passwordNew ? (data.user.password = this.passwordNew) : undefined;
      this.passwordNewRepeat
        ? (data.user.password_confirmation = this.passwordNewRepeat)
        : undefined;

      if (this.sendOrders && this.regions.length) {
        data.email.status = this.sendOrders;
        data.email.data = [];
        this.regions.forEach(city => {
          data.email.data.push(city.id);
        });
      } else {
        data.email.status = false;
      }

      if (
        data.user_info.name === undefined &&
        data.user_info.persent === undefined
      ) {
        delete data.user_info;
      }

      instance
        .post("/api/post/admin/partners/update", data)
        .then(response => {
          this.$store.commit("hidePreloader");
          console.log(data);
          let obj = {};

          if (data.user_info === undefined) {
            data.user_info = {};

            if (data.user.stage === 0) {
              obj = {
                id: data.id,
                user_info: {
                  name: this.partner.user_info.name,
                  persent: this.partner.user_info.persent
                },
                email: this.partner.email,
                phone: data.user.phone,
                stage: data.user.stage
              };

              this.$store.commit("changePartnersData", {
                type: "PartnerToOrder",
                data: obj
              });
              this.$store.commit("closeModal");
              this.$store.commit("createMessage", {
                type: "success",
                text: "Данные измемены"
              });
            } else {
              obj = {
                id: data.id,
                user_info: {
                  name: this.partner.user_info.name,
                  persent: this.partner.user_info.persent
                },
                email: data.user.email ? data.user.email : this.partner.email,
                phone: data.user.phone,
                stage: data.user.stage ? data.user.stage : this.partner.stage
              };

              this.$store.commit("changePartnersData", {
                type: "update",
                data: obj
              });
              this.$store.commit("closeModal");
              this.$store.commit("createMessage", {
                type: "success",
                text: "Данные измемены"
              });
            }
          } else {
            obj = {
              id: data.id,
              user_info: {
                name: data.user_info.name
                  ? data.user_info.name
                  : this.partner.user_info.name,
                persent: data.user_info.persent
                  ? data.user_info.persent
                  : this.partner.user_info.persent
              },
              email: data.user.email ? data.user.email : this.partner.email,
              phone: data.user.phone,
              stage: data.user.stage ? data.user.stage : this.partner.stage
            };

            this.$store.commit("changePartnersData", {
              type: "update",
              data: obj
            });
            this.$store.commit("closeModal");

            this.$store.commit("createMessage", {
              type: "success",
              text: "Данные измемены"
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 422) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: "Некорректные данные"
            });
          }
          if (error.response.status === 500) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
          }
        });
      }
    },
    openRequestSettings() {
      this.$store.commit("openModal", "request-modal");
      console.log(this.$store.state.openedModal);
    },
    addField(type, title) {
      console.log(type);
      console.log(this.requestFields[type]);
      // console.log( this.requestSettings[]);
      this.requestFields[type].push({ title: title, value: null });
    }
  },
  validations: {
    userName: {},
    userPhone: {
      phoneNumber
    },
    prefix: {},
    userEmail: {
      email,
      required: requiredIf(function() {
        console.log('sendOders: ' + this.sendOrders);
        console.log('partner.email: ' + this.partner.email);
        console.log('userEmail: ' + this.userEmail);
          return this.sendOrders && !this.partner.email && !this.userEmail;
        })
    },
    userProcent: {
      between: between(0, 1000),
      procent
    },
    passwordOld: {},
    passwordNew: {
      minLength: minLength(8)
    },
    passwordNewRepeat: {
      sameAsPassword: sameAs("passwordNew")
    },
    sendOrders: {

    }
  }
};
</script>

<style lang="scss">
.submodal {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 3;
  transition: 0.3s left;

  &.opened {
    left: 0;
  }
}

.back-btn {
  position: absolute;
  left: 10px;
  top: 10px;
  transform: rotate(180deg);
  display: block;
  width: 25px;
  height: 25px;
  fill: #666;
  cursor: pointer;

  svg {
    max-width: 100%;
  }
}

.form__group {
  margin-bottom: 35px;
}
.form__row {
  &--header {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    // &:not(:first-child) {
    //   margin-top: 35px;
    // }
  }

  .arr-down {
    // position: absolute;
    right: 0.75rem;
    top: 50%;
    // transform: translateY(-50%);
    width: 15px;
    height: 15px;
    transition: 0.3s;

    svg {
      transition: 0.3s;
    }

    &.closed {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
