<template>
  <div class="modal-big">
    <div class="modal-big__header">
      <h3 class="modal-big__title">Редактирование компании</h3>
    </div>
    <div class="modal-big__body">
      <form class="modal-big__form form">
        <div class="form__row">
          <label class="form__label" for="companyName">Название:</label>
          <input
            v-model.trim="$v.companyName.$model"
            class="form__input"
            type="text"
            placeholder="Название Компании"
            id="companyName"
            :class="{
                            errorInput: $v.companyName.$anyError, 
                            validInput: !$v.companyName.$anyError && $v.companyName.$dirty && $v.companyName.$model
                        }"
          >
        </div>
        <div class="form__row">
          <label class="form__label" for="companyAddress">Адрес:</label>
          <input
            v-model.trim="$v.companyAddress.$model"
            class="form__input"
            type="text"
            placeholder="г. Новосибирск, ул. Каменская, д. 51"
            id="companyAddress"
            :class="{
                            errorInput: $v.companyAddress.$anyError, 
                            validInput: !$v.companyAddress.$anyError && $v.companyAddress.$dirty && $v.companyAddress.$model
                        }"
          >
        </div>
        <div class="form__row">
          <label class="form__label" for="companyEmail">Email:</label>
          <input
            v-model.trim="$v.companyEmail.$model"
            class="form__input"
            type="text"
            placeholder="mail@mail.com"
            id="companyEmail"
            :class="{
                            errorInput: $v.companyEmail.$anyError, 
                            validInput: !$v.companyEmail.$anyError && $v.companyEmail.$dirty && $v.companyEmail.$model
                        }"
          >
          <div
            class="error"
            v-if="!$v.companyEmail.email && $v.companyEmail.$dirty"
          >Некорректный формат email</div>
        </div>
        <div class="form__row">
          <label class="form__label" for="companyPhone">Телефон:</label>
          <input
            v-mask="'+7 (###) ### ## ##'"
            v-model.trim="$v.companyPhone.$model"
            class="form__input"
            type="text"
            placeholder="+7 (000) 000 00 00"
            id="companyPhone"
            :class="{
                            errorInput: $v.companyPhone.$anyError, 
                            validInput: !$v.companyPhone.$anyError && $v.companyPhone.$dirty && $v.companyPhone.$model
                        }"
          >
          <div
            class="error"
            v-if="!$v.companyPhone.phoneNumber && $v.companyPhone.$dirty"
          >Введите нормер телефона</div>
        </div>
        <div class="form__row">
          <label class="form__label" for="companyAbout">О компании:</label>
          <textarea
            v-model.trim="$v.companyAbout.$model"
            name="aboutCompany"
            id="companyAbout"
            class="form__input"
            :class="{
                            errorInput: $v.companyAbout.$anyError, 
                            validInput: !$v.companyAbout.$anyError && $v.companyAbout.$dirty && $v.companyAbout.$model
                        }"
          ></textarea>
          <div
            class="error"
            v-if="!$v.companyAbout.maxLength && $v.companyAbout.$dirty"
          >Не более {{$v.companyAbout.$params.maxLength.max}} символов</div>
        </div>

        <div class="form__row form__row--btns">
          <button class="btn btn__success" @click.prevent="changeSettings">Сохранить</button>
          <button class="btn btn__danger" @click.prevent="close">Отменить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email, helpers, maxLength } from "vuelidate/lib/validators";
import axios from "axios";

const phoneNumber = helpers.regex(
  "alpha",
  /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
);

export default {
  name: "EditComany",
  data() {
    return {
      companyName:    '',
      companyAddress: '',
      companyEmail:   '',
      companyPhone:   '',
      companyAbout:   ''
    };
  },
  methods: {
    close() {
      this.$store.commit("closeModal");
    },
    changeSettings() {
     this.$v.$touch();
     if(!this.$v.$invalid) {
        const instance = axios.create({
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$store.state.access_token
        }
      });

      this.$store.commit('showPreloader');
      let sendObj = {
        name: this.companyName,
        address: this.companyAddress,
        phone:  this.companyPhone,
        email: this.companyEmail,
        description: this.companyAbout
      };

      Object.keys(sendObj).forEach((key) => (!sendObj[key]) && delete sendObj[key]);
      
      instance.post('api/post/company/update', {
        name:        sendObj.name,
        address:     sendObj.address,
        phone:       sendObj.phone,
        email:       sendObj.email,
        description: sendObj.description
      })
      .then(response => {
        if(response.data.result) {
          this.$store.commit('updateCompanyInfo', {
            name: this.companyName,
            address: this.companyAddress,
            phone:  this.companyPhone,
            email: this.companyEmail,
            description: this.companyAbout
          });

          this.$store.commit('hidePreloader');
          this.$store.commit('closeModal');
          this.$store.commit('createMessage', {type: 'success', text: response.data.message});
        }
      })
      .catch(error => {

        if(error.status === 401) {
          this.$store.commit('createMessage', {type: 'danger', text: error.data.message});
        }

        if(error.status === 422) {
          this.$store.commit('createMessage', {type: 'danger', text: error.data.errors});
        }
      })
     }

    }
  },
  validations: {
    companyName: {},
    companyAddress: {},
    companyEmail: {
      email
    },
    companyPhone: {
      phoneNumber
    },
    companyAbout: {
      maxLength: maxLength(150)
    }
  }
};
</script>
