<template>
  <div class="add-news current-news ">
    <div class="add-news__header">
      <div @click="close" class="close-button">
        <span></span>
      </div>
      <div class="add-news__title">
        {{ this.$store.state.curNews.title }}
      </div>
      <div class="add-news__date">
        {{ date }}
      </div>
    </div>
    <div class="add-news__content">    
      <div class="add-news__description">
        <vue-editor v-model="text" :editorOptions="options"></vue-editor>
      </div>
    </div>    
  </div>
</template>

<script>
  import { VueEditor, Quill } from 'vue2-editor';
  import moment from 'moment';
  import 'moment/locale/ru';

  moment.locale('ru');

  export default {
    name: 'ChosenNews',
    components: { 
      VueEditor
    },
    data() {
      return {
        text: this.$store.state.curNews.text,
        options: {
          readOnly: true,
          theme: 'bubble'
        }
      }
    },
    computed: {
      date() {
        let stillUtc = moment.utc(this.$store.state.curNews.created_at).toDate();
        let local = moment(stillUtc).local().format('DD MMMM YYYY HH:mm:ss');       
        return local;
      }
    },
    methods: {
      close() {
        this.$store.commit('closeModal');
      }
    }
  }
</script>

<style lang="scss">
  .current-news {
    width: 100vw!important;
    height: 100vh!important;
    max-width: none!important;

    svg {
      fill: lightgrey;
      transition: fill 0.5s;

      &:hover {
        fill: black;
      }
    }

    &__header {
      text-align: center;

      .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        cursor: pointer;
      }
    }

    &__title {
      font-size: 30px;
    }

    &__date {
      margin: 10px 0;
      font-size: 12px;
    }

    &__content {
      display: flex;
      justify-content: center;
    }

    &__description {
      font-size: 18px;
      margin-bottom: 10px;
      word-break: break-all;
      width: 57%;
    }
  }
</style>
