<template>
  <div class="settings">
    <LeftMenu/>
    <main class="page-container">
      <MobileHeader/>
      <PageTitle :title="'Настройки профиля'"/>
      <div class="content">
        <div class="settings__inner">
          <div class="settings__title">
            <span>Мой профиль</span>

            <span class="edit-button" @click="openModal('edit-user-info')">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="-549 451 100 100"
                style="enable-background:new -549 451 100 100;"
                xml:space="preserve"
              >
                <path
                  d="M-455.8,460l-7.1-6c-4.4-3.7-10.9-3.3-14.6,0.7l-13.6,14.9l-37.4,40.9l-11.4,12.9l-5.6,26.7c-0.1,0-0.2-0.1-0.2-0.1
                                    c0,0.3-0.1,0.5-0.1,0.8h0.1l-0.1,0.2l0.4-0.1c1.3,0.3,27.6-8.7,27.6-8.7l12-12.5l37.4-40.8l13.6-14.9
                                    C-450.8,470.1-451.4,463.7-455.8,460z M-534.4,542c-0.4-0.5-1-0.9-1.5-1.5c-0.4-0.4-0.9-0.8-1.4-1c1.1-4.2,2.2-8.4,3.3-12.6
                                    c0.4-0.1,5.5-1,9,2.9c2.4,2.7,2.5,6,2.4,6.8C-526.5,538.4-530.4,540.2-534.4,542z M-468.9,481.6l-14.5-12.3l6.5-7.1l14.5,12.3
                                    L-468.9,481.6z"
                ></path>
              </svg>
            </span>
          </div>

          <div class="settings__row">
            <span class="settings__label">Имя:</span>
            <span class="settings__data">{{ this.$store.state.user.name }}</span>
          </div>
          <div class="settings__row">
            <span class="settings__label">Email:</span>
            <span class="settings__data">{{ this.$store.state.userEmail || "Не указано" }}</span>
          </div>
          <div class="settings__row">
            <span class="settings__label">Телефон:</span>
            <span class="settings__data">{{ this.$store.state.user.phone  || "Не указано" }}</span>
          </div>
        </div>

        <div class="settings__inner" >
          <div class="settings__title">
            <span>Компания</span>

            <span class="edit-button" @click="openModal('edit-company-info')">
              <svg
                version="1.1"
                id="Слой_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="-549 451 100 100"
                style="enable-background:new -549 451 100 100;"
                xml:space="preserve"
              >
                <path
                  d="M-455.8,460l-7.1-6c-4.4-3.7-10.9-3.3-14.6,0.7l-13.6,14.9l-37.4,40.9l-11.4,12.9l-5.6,26.7c-0.1,0-0.2-0.1-0.2-0.1
                                    c0,0.3-0.1,0.5-0.1,0.8h0.1l-0.1,0.2l0.4-0.1c1.3,0.3,27.6-8.7,27.6-8.7l12-12.5l37.4-40.8l13.6-14.9
                                    C-450.8,470.1-451.4,463.7-455.8,460z M-534.4,542c-0.4-0.5-1-0.9-1.5-1.5c-0.4-0.4-0.9-0.8-1.4-1c1.1-4.2,2.2-8.4,3.3-12.6
                                    c0.4-0.1,5.5-1,9,2.9c2.4,2.7,2.5,6,2.4,6.8C-526.5,538.4-530.4,540.2-534.4,542z M-468.9,481.6l-14.5-12.3l6.5-7.1l14.5,12.3
                                    L-468.9,481.6z"
                ></path>
              </svg>
            </span>
          </div>
          <div class="settings__row">
            <span class="settings__label">Название:</span>
            <span class="settings__data">{{ this.$store.state.company ? this.$store.state.company.name : "Не указано" }}</span>
          </div>
          <div class="settings__row">
            <span class="settings__label">Адрес:</span>
            <span class="settings__data">{{ this.$store.state.company ? this.$store.state.company.address : "Не указано" }}</span>
          </div>
          <div class="settings__row">
            <span class="settings__label">Email:</span>
            <span class="settings__data">{{ this.$store.state.company ? this.$store.state.company.email : "Не указано" }}</span>
          </div>
          <div class="settings__row">
            <span class="settings__label">Телефон:</span>
            <span class="settings__data">{{ this.$store.state.company ? this.$store.state.company.phone : "Не указано" }}</span>
          </div>
          <div class="settings__row">
            <span class="settings__label">О компании:</span>
            <span class="settings__data">{{ this.$store.state.company ? this.$store.state.company.description : "Не указано" }}</span>
          </div>
        </div>
      </div>

      <ModalOverlay/>
      <EditUser v-show="this.$store.state.openedModal=='edit-user-info'"/>
      <EditCompany v-show="this.$store.state.openedModal=='edit-company-info'"/>
      <InfoMessage  :message="this.$store.state.message.text" :type="this.$store.state.message.type">
    </main>
  </div>
</template>

<script>
import LeftMenu     from "../Components/LeftMenu";
import ModalOverlay from "../Components/ModalOverlay";
import PageTitle    from "../Components/PageTitle";
import EditUser     from "./Modals/EditUserModal";
import EditCompany  from "./Modals/EditCompanyModal";
import MobileHeader from "../Components/MobileHeader";
import InfoMessage from "../Components/InfoMessage";


export default {
  name: "Settings",
  components: {
    LeftMenu,
    PageTitle,
    EditUser,
    EditCompany,
    ModalOverlay,
    MobileHeader,
    InfoMessage
  },
  data() {
    return {
      modalOpened:       false,
      editCompamyOpened: false
    };
  },
  methods: {
    openModal(modalName) {
      this.$store.commit("openModal", modalName);
    }
  }
};
</script>

<style lang="scss">
.edit-button {
  display: inline-block;
  width: 25px;
  fill: #666;
  cursor: pointer;
  padding: 5px;
  transition: 0.3s;
  border-radius: 3px;
  margin-left: 7px;

  &:hover {
    background-color: rgba(#000, 0.05);
  }
}

.settings {
  .content {
    padding-right: 80px;
    padding-left: 80px;
  }

  &__inner {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    color: #e4485d;
    margin-bottom: 20px;
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__label {
    width: 20%;
    font-weight: 600;
    padding: 5px 7px;
    min-width: 150px;
  }

  &__data {
    padding: 5px 7px;
    width: 80%;
  }

  @media screen and (max-width: 1023px) {
    .content {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  @media screen and (max-width: 500px) {
    .settings {
      &__row {
        flex-direction: column;
      }

      &__label,
      &__data {
        width: 100%;
      }
    }

    .content {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
</style>