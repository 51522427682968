<template>
  <transition name="fade">
    <div class="modal-big">
      <h3 class="modal-big__header">Добавить партнера</h3>
      <div class="modal-big__body">
        <form action="#" class="modal-big__form">
          <div class="form__row">
            <label for="partnerName" class="form__label">ФИО:</label>
            <input
              class="form__input"
              type="text"
              id="partnerName"
              placeholder="Иванов Иван Иванович"
              v-model.trim="$v.partnerName.$model"
              :class="{
                errorInput: $v.partnerName.$anyError, 
                validInput: !$v.partnerName.$anyError && $v.partnerName.$dirty
              }"
            />
            <div
              class="error"
              v-if="!$v.partnerName.required && $v.partnerName.$dirty"
            >Это обязательное поле</div>
            <div class="error" v-if="!$v.partnerName.letters && $v.partnerName.$dirty">Введите ФИО</div>
          </div>
          <div class="form__row">
            <label for="partnerEmail" class="form__label">Email:</label>
            <input
              class="form__input"
              type="text"
              id="partnerEmail"
              placeholder="mail@mail.com"
              v-model.trim="$v.partnerEmail.$model"
              :class="{
                errorInput: $v.partnerEmail.$anyError, 
                validInput: !$v.partnerEmail.$anyError && $v.partnerEmail.$dirty
              }"
            />
            <div
              class="error"
              v-if="!$v.partnerEmail.required && $v.partnerEmail.$dirty"
            >Это обязательное поле</div>
            <div
              class="error"
              v-if="!$v.partnerEmail.email && $v.partnerEmail.$dirty"
            >Некорректный формат email</div>
            <div
              class="error"
              v-if="!$v.partnerEmail.minLength && $v.partnerEmail.$dirty && $v.partnerEmail.email"
            >Не менее {{ $v.partnerEmail.$params.minLength.min }} символов</div>
          </div>
          <div class="form__row">
            <label for="partnerPhone" class="form__label">Телефон:</label>
            <input
              class="form__input"
              type="text"
              id="partnerPhone"
              placeholder="+7 (999) 999 99 99"
              v-mask="'+7 (###) ### ## ##'"
              v-model.trim="$v.partnerPhone.$model"
              :class="{
                errorInput: $v.partnerPhone.$anyError, 
                validInput: !$v.partnerPhone.$anyError && $v.partnerPhone.$dirty
              }"
            />
            <div
              class="error"
              v-if="!$v.partnerPhone.required && $v.partnerPhone.$dirty"
            >Это обязательное поле</div>
            <div
              class="error"
              v-if="!$v.partnerPhone.phoneNumber && $v.partnerPhone.$dirty"
            >Введите номер телефона</div>
          </div>
          <div class="form__row">
            <label for="partnerPrefix" class="form__label">Префикс:</label>
            <input
              class="form__input"
              type="text"
              id="partnerPrefix"
              placeholder="Префикс"
              v-model.trim="$v.partnerPrefix.$model"
              :class="{
                errorInput: $v.partnerPrefix.$anyError, 
                validInput: !$v.partnerPrefix.$anyError && $v.partnerPrefix.$dirty
              }"
            />
            <div
              class="error"
              v-if="!$v.partnerPrefix.required && $v.partnerPrefix.$dirty"
            >Это обязательное поле</div>
          </div>
          <div class="form__row">
            <label for="partnerPassword" class="form__label">Пароль:</label>

            <div class="form__wr">
              <input
                class="form__input"
                :type="showPassword ?  'text' : 'password'"
                id="partnerPassword"
                v-model.trim="$v.partnerPassword.$model"
                :class="{
                errorInput: $v.partnerPassword.$anyError, 
                validInput: !$v.partnerPassword.$anyError && $v.partnerPassword.$model
              }"
              />
              <span
                class="password-gen__btn password-gen__btn--generate"
                @click="partnerPassword = partnerPasswordRepeat = generatePassword(8)"
                title="Сгенерировать пароль"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 49.7 49.7"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M27,13.85h9v8.964l13.7-9.964L36,2.886v8.964h-9c-7.168,0-13,5.832-13,13c0,6.065-4.935,11-11,11H1c-0.553,0-1,0.447-1,1
                    s0.447,1,1,1h2c7.168,0,13-5.832,13-13C16,18.785,20.935,13.85,27,13.85z M38,6.814l8.3,6.036L38,18.886V6.814z"
                    />
                    <path
                      d="M1,13.85h2c2.713,0,5.318,0.994,7.336,2.799c0.191,0.171,0.43,0.255,0.667,0.255c0.274,0,0.548-0.112,0.745-0.333
                    c0.368-0.412,0.333-1.044-0.078-1.412C9.285,13.025,6.206,11.85,3,11.85H1c-0.553,0-1,0.447-1,1S0.447,13.85,1,13.85z"
                    />
                    <path
                      d="M36,35.85h-9c-2.685,0-5.27-0.976-7.278-2.748c-0.411-0.365-1.044-0.327-1.411,0.089c-0.365,0.414-0.326,1.046,0.089,1.411
                    c2.374,2.095,5.429,3.248,8.601,3.248h9v8.964l13.7-9.964L36,26.886V35.85z M38,30.814l8.3,6.036L38,42.886V30.814z"
                    />
                  </g>
                </svg>
              </span>
              <span
                class="password-gen__btn password-gen__btn--show"
                @click="showPassword = !showPassword"
                v-if="$v.partnerPassword.$model"
                title="Показать пароль"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 59.2 59.2"
                  xml:space="preserve"
                  v-if="!showPassword"
                >
                  <g>
                    <path
                      d="M51.062,21.561c-11.889-11.889-31.232-11.889-43.121,0L0,29.501l8.138,8.138c5.944,5.944,13.752,8.917,21.561,8.917
                      s15.616-2.972,21.561-8.917l7.941-7.941L51.062,21.561z M49.845,36.225c-11.109,11.108-29.184,11.108-40.293,0l-6.724-6.724
                      l6.527-6.527c11.109-11.108,29.184-11.108,40.293,0l6.724,6.724L49.845,36.225z"
                    />
                    <path
                      d="M29.572,16.57c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S36.741,16.57,29.572,16.57z M29.572,40.57
                      c-6.065,0-11-4.935-11-11s4.935-11,11-11s11,4.935,11,11S35.638,40.57,29.572,40.57z"
                    />
                  </g>
                </svg>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 59.049 59.049"
                  xml:space="preserve"
                  v-if="showPassword"
                >
                  <g>
                    <path
                      d="M11.285,41.39c0.184,0.146,0.404,0.218,0.623,0.218c0.294,0,0.585-0.129,0.783-0.377c0.344-0.432,0.273-1.061-0.159-1.405
                    c-0.801-0.638-1.577-1.331-2.305-2.06l-7.398-7.398l7.629-7.629c7.334-7.333,18.003-9.836,27.839-6.534
                    c0.523,0.173,1.09-0.107,1.267-0.63c0.175-0.523-0.106-1.091-0.63-1.267c-10.562-3.545-22.016-0.857-29.89,7.016L0,30.368
                    l8.812,8.812C9.593,39.962,10.426,40.705,11.285,41.39z"
                    />
                    <path
                      d="M50.237,21.325c-1.348-1.348-2.826-2.564-4.394-3.616c-0.458-0.307-1.081-0.185-1.388,0.273
                    c-0.308,0.458-0.185,1.08,0.273,1.388c1.46,0.979,2.838,2.113,4.094,3.369l7.398,7.398l-7.629,7.629
                    c-7.385,7.385-18.513,9.882-28.352,6.356c-0.52-0.187-1.093,0.084-1.279,0.604c-0.186,0.52,0.084,1.092,0.604,1.279
                    c3.182,1.14,6.49,1.693,9.776,1.693c7.621,0,15.124-2.977,20.665-8.518l9.043-9.043L50.237,21.325z"
                    />
                    <path
                      d="M30.539,41.774c-2.153,0-4.251-0.598-6.07-1.73c-0.467-0.29-1.084-0.148-1.377,0.321c-0.292,0.469-0.148,1.085,0.321,1.377
                    c2.135,1.33,4.6,2.032,7.126,2.032c7.444,0,13.5-6.056,13.5-13.5c0-2.685-0.787-5.279-2.275-7.502
                    c-0.308-0.459-0.93-0.582-1.387-0.275c-0.459,0.308-0.582,0.929-0.275,1.387c1.267,1.893,1.937,4.102,1.937,6.39
                    C42.039,36.616,36.88,41.774,30.539,41.774z"
                    />
                    <path
                      d="M30.539,18.774c2.065,0,4.089,0.553,5.855,1.6c0.474,0.281,1.088,0.125,1.37-0.351c0.281-0.475,0.125-1.088-0.351-1.37
                    c-2.074-1.229-4.451-1.879-6.875-1.879c-7.444,0-13.5,6.056-13.5,13.5c0,2.084,0.462,4.083,1.374,5.941
                    c0.174,0.354,0.529,0.56,0.899,0.56c0.147,0,0.298-0.033,0.439-0.102c0.496-0.244,0.701-0.843,0.458-1.338
                    c-0.776-1.582-1.17-3.284-1.17-5.06C19.039,23.933,24.198,18.774,30.539,18.774z"
                    />
                    <path
                      d="M54.621,5.567c-0.391-0.391-1.023-0.391-1.414,0l-46.5,46.5c-0.391,0.391-0.391,1.023,0,1.414
                    c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l46.5-46.5C55.012,6.591,55.012,5.958,54.621,5.567z"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <div
              class="error"
              v-if="!$v.partnerPassword.required && $v.partnerPassword.$dirty"
            >Это обязательное поле</div>
          </div>
          <div class="form__row">
            <label for="partnerPasswordRepeat" class="form__label">Подтверждение пароля:</label>
            <input
              class="form__input"
              type="password"
              id="partnerPasswordRepeat"
              v-model.trim="$v.partnerPasswordRepeat.$model"
              :class="{
                errorInput: $v.partnerPasswordRepeat.$anyError, 
                validInput: !$v.partnerPasswordRepeat.$anyError && $v.partnerPasswordRepeat.$model
              }"
            />
            <div
              class="error"
              v-if="!$v.partnerPasswordRepeat.sameAsPassword && $v.partnerPasswordRepeat.$dirty"
            >Пароль и подтверждение пароля не совпадают</div>
            <div
              class="error"
              v-if="!$v.partnerPasswordRepeat.required && $v.partnerPasswordRepeat.$dirty"
            >Это обязательное поле</div>
          </div>

          <div class="form__row">
            <input
              class="checkbox-input"
              name="sendOrdersCreate"
              id="sendOrdersCreate"
              type="checkbox"
              v-model.trim="sendOrders"
            >
            <label class="chekbox" for="sendOrdersCreate">
              <span class="chekbox-label"></span>
              <span class="checkbox-text">Отправка заявок на email</span>
            </label>
          </div>

          <div class="form__row" v-if="sendOrders">
            <label class="form__label">Регион:</label>
            <multiselect
              v-model.trim="regions"
              :options="this.$store.state.filters.departments"
              :multiple="true"
              label="name"
              value="id"
              track-by="name"
              :show-labels="false"
              placeholder="Новосибирск"
            >
              <span slot="noResult">Совпадений не найдено</span>
            </multiselect>
          </div>

          <div class="form__row form__row--btns">
            <button class="btn btn__success" @click.prevent="createPartner">Добавить</button>
            <button class="btn btn__danger" @click.prevent="closeModal">Отменить</button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import {
  required,
  email,
  helpers,
  minLength,
  sameAs
} from "vuelidate/lib/validators";
const letters = helpers.regex("alpha", /[А-Яа-я_]/);
const phoneNumber = helpers.regex(
  "alpha",
  /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
);

export default {
  name: "CreatePartner",
  data() {
    return {
      partnerName: "",
      partnerEmail: "",
      partnerPhone: "",
      partnerPrefix: "",
      partnerPassword: "",
      partnerPasswordRepeat: "",
      sendOrders: false,
      regions: [],

      showPassword: false
    };
  },
  methods: {
    check() {
      console.log("click");
      console.log(this.sendOrders);
    },
    closeModal() {
      this.$store.commit("closeModal");
    },
    generatePassword(length) {
      let password = "";
      let symbols =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+=";
      for (var i = 0; i < length; i++) {
        password += symbols.charAt(Math.floor(Math.random() * symbols.length));
      }
      return password;
    },
    createPartner() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.access_token
          }
        });

        this.$store.commit("showPreloader");

        let email = {};

        if (this.sendOrders && this.regions.length) {
          email.status = this.sendOrders;
          email.data = [];
          this.regions.forEach(function(city) {
            email.data.push(city.id);
          });
        } else {
          email.status = false;
        }

        let dataToSend = {
          user: {
            phone: this.partnerPhone.replace(/\D/g, ""),
            email: this.partnerEmail,
            password: this.partnerPassword,
            password_confirmation: this.partnerPasswordRepeat,
            name: this.partnerPrefix
          },
          user_info: {
            name: this.partnerName
          },
          send_orders: email
        };

        instance
          .post("/api/post/admin/partners/create", dataToSend)
          .then(response => {
            this.$store.commit("hidePreloader");
            this.$store.commit("closeModal");
            this.$store.commit("addPartner", {
              //TODO: исправить id, процент, статус, конверсию
              id: response.data.id,
              phone: this.partnerPhone.replace(/\D/g, ""),
              email: this.partnerEmail,
              conversion: 0,
              stage: 1,
              user_info: {
                name: this.partnerName,
                phone: this.partnerPhone,
                persent: "1.5"
              }
            });

            this.partnerName = "";
            this.partnerEmail = "";
            this.partnerPhone = "";
            this.partnerPrefix = "";
            this.partnerPassword = "";
            this.partnerPasswordRepeat = "";
            this.sendOrders = false;
            this.regions = [];

            this.$store.commit("createMessage", {
              type: "success",
              text: "Партнер добавлен"
            });

            console.log(response);
          })
          .catch(error => {
            console.error(error);
            if (error.response.status === 401) {
              this.$store.commit("forceLogout");
            }
          });
      }
    }
  },
  validations: {
    partnerName: {
      required,
      letters
    },
    partnerEmail: {
      email,
      required,
      minLength: minLength(8)
    },
    partnerPhone: {
      required,
      phoneNumber
    },
    partnerPrefix: {
      required
    },
    partnerPassword: {
      required
    },
    partnerPasswordRepeat: {
      sameAsPassword: sameAs("partnerPassword").length,
      required
    }
  }
};
</script>
<style lang="scss">
.password-gen {
  margin-top: 5px;

  &__btn {
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    transition: 0.3s;
    fill: #666;

    &--show {
      position: absolute;
      right: 40px;
      top: 10px;
      width: 20px;
      padding: 0;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    &--generate {
      width: 30px;
      padding: 2px;
      padding: 2px;
      padding: 3px 2px;
      border-radius: 5px;
      background-color: #f5f5f5;
      border: 1px solid #d3d4d6;
      margin-left: 5px;
      position: relative;
      transition: 0.3s background-color;

      &:hover {
        background-color: #fff;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
      }
    }
  }
}

.form__wr {
  position: relative;
  display: flex;

  .form__input {
    padding-right: 35px;
  }
}
</style>