<template>
  <div class="partners__content">
    <div class="tabs">
      <div
        @click="changeActiveTab()"
        class="tab tab--partners"
        :class="{ active : isActiveTab || isEmpty }"
      >Партнеры</div>
      <div
        @click="changeActiveTab()"
        class="tab tab--request"
        :class="{ 
          active : !isActiveTab && !isEmpty,
          disabled : isEmpty
        }"
      >Заявки на партнерство</div>
    </div>
    <div class="table-partners table" v-if="this.isActiveTab && this.rowsPartners.length">
      <div class="table__row table__row--header">
        <div class="table__cell table__cell--checkbox">
          <input type="checkbox" id="main" v-model="selectAll" @click="select">
          <label for="main"></label>
        </div>
        <div
          class="table__cell table__cell--edit"
          :class="{ left : selectAll || selected.length > 1 }"
        >
          <span
            @click="openModal('DeletePartner', selected)"
            title="Удалить партнёров"
            class="partner-btn"
          >
            <svg
              version="1.1"
              id="Слой_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="-549 451 100 100"
              style="enable-background:new -549 451 100 100;"
              xml:space="preserve"
            >
              <path
                d="M-481.2,501l26.4-26.3c4.9-4.9,4.9-12.9,0-17.8s-12.9-4.9-17.8,0l-26.4,26.3l-26.3-26.4c-4.9-4.9-12.9-4.9-17.8,0l0,0
                  c-4.9,4.9-4.9,12.9,0,17.8l26.3,26.4l-26.4,26.3c-4.9,4.9-4.9,12.9,0,17.8s12.9,4.9,17.8,0l26.4-26.3l26.3,26.4
                  c4.9,4.9,12.9,4.9,17.8,0l0,0c4.9-4.9,4.9-12.9,0-17.8L-481.2,501z"
              ></path>
            </svg>
          </span>
        </div>
        <div class="table__cell table__cell--header">ФИО</div>
        <div class="table__cell table__cell--header">Телефон</div>
        <div class="table__cell table__cell--header">E-mail</div>
        <div class="table__cell table__cell--header">Процентная ставка</div>
        <div class="table__cell table__cell--header">Конверсия</div>
        <div class="table__cell table__cell--header">Состояние</div>
      </div>

      <div
        class="table__row"
        v-for="row in this.rowsPartners"
        :key="row"
        @click="getPartnerInfo(row.id)"
      >
        <div class="table__cell table__cell--checkbox" @click.stop>
          <input type="checkbox" :id="row.id" :value="row.id" v-model="selected">
          <label :for="row.id"></label>
        </div>
        <div
          class="table__cell table__cell--edit"
          :class="{ left : selected.includes(row.id) && !selectAll && selected.length === 1 }"
          @click.stop
        >
          <span
            @click="openModal('EditPartner', row)"
            title="Редактировать партнёра"
            class="partner-btn"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 383.947 383.947"
              style="enable-background:new 0 0 383.947 383.947;"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g>
                    <polygon
                      points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893 			"
                    ></polygon>
                    <path
                      d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04
                      C386.027,77.92,386.027,64.373,377.707,56.053z"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span
            @click="openModal('DeletePartner', row.id, 'deletePartner')"
            title="Удалить партнёра"
            class="partner-btn"
          >
            <svg
              version="1.1"
              id="Слой_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="-549 451 100 100"
              style="enable-background:new -549 451 100 100;"
              xml:space="preserve"
            >
              <path
                d="M-481.2,501l26.4-26.3c4.9-4.9,4.9-12.9,0-17.8s-12.9-4.9-17.8,0l-26.4,26.3l-26.3-26.4c-4.9-4.9-12.9-4.9-17.8,0l0,0
                  c-4.9,4.9-4.9,12.9,0,17.8l26.3,26.4l-26.4,26.3c-4.9,4.9-4.9,12.9,0,17.8s12.9,4.9,17.8,0l26.4-26.3l26.3,26.4
                  c4.9,4.9,12.9,4.9,17.8,0l0,0c4.9-4.9,4.9-12.9,0-17.8L-481.2,501z"
              ></path>
            </svg>
          </span>
        </div>
        <div class="table__cell">{{  row.user_info ? row.user_info.name : "Не указано" }}</div>
        <div class="table__cell">{{ row.phone ? `${phoneFormat(row.phone)}` : `Отсутствует` }}</div>
        <div class="table__cell">{{ row.email ? `${row.email}` : `Отсутствует` }}</div>
        <div class="table__cell">{{ row.user_info ? row.user_info.persent : "не указано" }}</div>
        <div class="table__cell">{{ `${row.conversion}%` }}</div>
        <div class="table__cell">{{ stages[row.stage] }}</div>
      </div>
    </div>

    <div class="table--empty" v-if="this.isActiveTab && !this.rowsPartners.length">
      <div class="table-partners table">
        <div class="table__row table__row--header">
          <div class="table__cell table__cell--header">ФИО</div>
          <div class="table__cell table__cell--header">Телефон</div>
          <div class="table__cell table__cell--header">E-mail</div>
          <div class="table__cell table__cell--header">Процентная ставка</div>
          <div class="table__cell table__cell--header">Конверсия</div>
          <div class="table__cell table__cell--header">Состояние</div>
        </div>

        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
        <div class="table__row">
          <div class="table__cell">Иванов Иван Иванович</div>
          <div class="table__cell">+ 7 (000) 000 00 00</div>
          <div class="table__cell">email@email.com</div>
          <div class="table__cell">1%</div>
          <div class="table__cell">5%</div>
          <div class="table__cell">Прошел модерацию</div>
        </div>
      </div>

      <div class="blur">
        <div class="blur-inner"></div>
        <div class="icon">
          <svg
            version="1.1"
            id="Слой_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-549 451 100 100"
            style="enable-background:new -549 451 100 100;"
            xml:space="preserve"
          >
            <g id="XMLID_160_">
              <g id="XMLID_161_">
                <path
                  id="XMLID_158_"
                  d="M-499,531.8c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                v-17.1c0,0-0.1,0-0.1,0.1C-463.2,525.2-479.3,531.8-499,531.8z"
                ></path>
                <path
                  id="XMLID_157_"
                  d="M-499,510.2c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                V495c0,0-0.1,0-0.1,0.1C-463.2,503.6-479.3,510.2-499,510.2z"
                ></path>
                <path
                  id="XMLID_156_"
                  d="M-499,488.5c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                v-17.1c0,0-0.1,0-0.1,0.1C-463.2,481.8-479.3,488.5-499,488.5z"
                ></path>
                <path
                  id="XMLID_155_"
                  d="M-499,484.7c20.7,0,37.4-7.4,37.4-16.4c0-9.1-16.7-16.4-37.4-16.4c-20.7,0-37.4,7.4-37.4,16.4
                        C-536.4,477.3-519.6,484.7-499,484.7z M-499,463.8c5.6,0,10.2,2,10.2,4.4c0,2.5-4.5,4.4-10.2,4.4s-10.2-2-10.2-4.4
                        C-509.2,465.7-504.6,463.8-499,463.8z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="text">Недостаточно данных</div>
      </div>
    </div>

    <div class="table-requests table" v-if="!this.isActiveTab">
      <div class="table__row table__row--header">
        <div class="table__cell table__cell--header">ФИО</div>
        <div class="table__cell table__cell--header">Телефон</div>
        <div class="table__cell table__cell--header">E-mail</div>
        <div class="table__cell table__cell--header">Процентная ставка</div>
        <div class="table__cell table__cell--header">Конверсия</div>
        <div class="table__cell table__cell--header">Состояние</div>
        <div class="table__cell table__cell--header"></div>
      </div>
      <div class="table__row" v-for="row in this.rowsOrders" :key="row">
        <div @click="openModal('PartnerInfo', row)" class="table__cell">{{ row.user_info ? row.user_info.name : "Не указано" }}</div>
        <div class="table__cell">{{ row.phone ? `${phoneFormat(row.phone)}` : `Отсутствует` }}</div>
        <div class="table__cell">{{ row.email ? `${row.email}` : `Отсутствует` }}</div>
        <div class="table__cell">{{ row.user_info ? row.user_info.persent : "не указано" }}</div>
        <div class="table__cell">{{ `${row.conversion}%` }}</div>
        <div class="table__cell">{{ stages[row.stage] }}</div>
        <div class="table__cell">
          <span class="btn btn__success" @click="accept(row)">Принять</span>
          <span
            class="btn btn__danger"
            @click="openModal('DeletePartner', row.id, 'deleteOrder', isActiveTab)"
          >Отклонить</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PartnersContent",
  props: {
    flag: Boolean
  },
  data() {
    return {
      isActiveTab: true,
      selectAll: false,
      selected: [],
      stages: ["На модерации", "Активен", "Забанен"],
      curPartner: null
    };
  },
  methods: {
    changeActiveTab() {
      if (this.isEmpty) {
        this.isActiveTab = true;
        return;
      }
      this.isActiveTab = !this.isActiveTab;
    },
    openModal(modalName, row, type) {
      this.$store.commit("openModal", modalName);
      this.$emit("getData", row, type);
      // this.selected = [];
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let row in this.rowsPartners) {
          this.selected.push(this.rowsPartners[row].id);
        }
      }
    },
    accept(row) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });

      let data = {
        id: row.id,
        user: {
          stage: 1
        }
      };

      instance
        .post("/api/post/admin/partners/update", data)
        .then(response => {
          let obj = row;
          obj.stage = 1;
          this.$store.commit("changePartnersData", {
            type: "updateOrder",
            data: obj
          });
          if (this.isEmpty) {
            this.isActiveTab = true;
            return;
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 422) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: "Некорректные данные"
            });
          }
          if (error.response.status === 500) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
          }
        });
    },
    phoneFormat(phone) {
      return (
        "+" +
        phone.substr(0, 1) +
        " " +
        "(" +
        phone.substr(1, 3) +
        ")" +
        " " +
        phone.substr(4, 3) +
        " " +
        phone.substr(7, 2) +
        " " +
        phone.substr(9)
      );
    },
    getPartnerInfo(partnerId) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });

      this.$store.commit("showPreloader");

      instance
        .get("/api/get/admin/partners?id=" + partnerId)
        .then(response => {
          this.curPartner = null;
          this.curPartner = response.data;
          console.log(response.data);
          this.$store.commit("hidePreloader");
          this.openModal("PartnerInfo", this.curPartner);
        })
        .catch(error => {
          console.error(error);
          if (error.response.status === 401) {
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 500) {
            this.$store.commit("hidePreloader");
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
          }
        });
    }
  },
  mounted() {
    const instance = axios.create({
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.access_token
      }
    });

    this.$store.commit("showPreloader");

    instance
      .get("/api/get/admin/partners")
      .then(response => {
        this.$store.commit("hidePreloader");
        this.$store.commit("setPartners", response.data);
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.$store.commit("forceLogout");
        }
        if (error.response.status === 422) {
          alert("Некорректные данные");
        }
        if (error.response.status === 500) {
          this.$store.commit("createMessage", {
            type: "danger",
            text: this.$store.state.messages.serverError
          });
        }
      });
  },
  updated() {
    if (this.isEmpty && this.flag) {
      this.isActiveTab = true;
    }
  },
  computed: {
    rowsPartners() {
      this.selectAll = false;
      this.selected = [];
      return this.$store.state.partnersData.partners;
    },
    rowsOrders() {
      return this.$store.state.partnersData.orders;
    },
    isEmpty() {
      return !this.$store.state.partnersData.orders.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  &__cell {
    width: calc(100% / 7);
    min-width: 180px;

    &--checkbox {
      min-width: 50px;
      max-width: 50px;
      z-index: 2;

      input {
        display: none;
      }

      label {
        display: inline-block;
        position: relative;
        width: 15px;
        height: 15px;
        border: 1px solid lightgray;
        border-radius: 3px;
        cursor: pointer;
        margin: auto;
      }

      input:checked + label:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background: #fff
          url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJfeDIwMTRfX0l0ZW1fMV8iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCINCgkgeT0iMHB4IiB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTIgMTEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEyIDExIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJjaGVjay5zdmciPg0KCTxnPg0KCQk8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzJFMzY0MCIgZD0iTTExLjQ3NiwwLjE1MWMtMC4zNzctMC4yOTYtMC45OTctMC4xMzktMS4zNTksMC4zMjRMNS4xNTMsNi41Mw0KCQkJQzUuMDc3LDYuNjI0LDQuODg0LDYuNjU3LDQuNzg1LDYuNTk1TDEuNzIzLDQuNjg4QzEuMjQsNC4zNDIsMC42MjQsNC4zNjYsMC4zNDksNC43NTNjLTAuMjY4LDAuMzc4LTAuMTgsMC45OTQsMC4yMDksMS40NjQNCgkJCWwzLjUwOSw0LjIzNEM0LjM2MSwxMC44MDUsNC43NDIsMTEsNS4xNCwxMWMwLjQ2NSwwLDAuODk1LTAuMjY2LDEuMTc3LTAuNzI4bDUuMjY0LTguNjE1QzExLjkxNiwxLjEwOSwxMS44NzEsMC40NjIsMTEuNDc2LDAuMTUxDQoJCQl6Ii8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=)
          no-repeat;
        background-position: center;
        background-size: 80%;
      }
    }

    &--edit {
      position: absolute;
      left: -100%;
      box-sizing: content-box;
      padding: 0 0 0 50px;
      width: 16.2%;

      background-color: #eee;
      z-index: 1;
      border-bottom: none;
      font-size: 20px;

      &.left {
        left: 0;
      }
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .tab {
    margin-right: 10px;
    padding-bottom: 7px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;

    &.active {
      padding-bottom: 5px;
      border-bottom: 2px solid #d02828;
    }

    &.disabled {
      color: grey;
    }
  }
}

.btn {
  word-break: keep-all;
  padding: 5px;
  width: fit-content;
  min-width: initial;
  font-size: 12px;

  &__success {
    background-color: #4caf50;
  }

  &__danger {
    background-color: #d02828;
  }
}

.partner-btn {
  cursor: pointer;
  width: 15px;
  fill: #777;
  position: relative;
  left: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.table--empty {
  position: relative;

  .table {
    filter: blur(5px);
  }
}
</style>
