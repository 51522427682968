<template>
  <div class="integration">
    <LeftMenu/>

    <main class="page-container">
      <MobileHeader/>

      <PageTitle :title="'Интеграция'"/>

      <div class="content">
        <div class="section">
          <div class="note">
            Здесь Вы можете создать webhooks для отправки статусов ваших заявок на ваши сервисы, а так
            же получить ключ доступа для использования http Api. Информация по интеграции доступна в
             <router-link :to="{name: 'api'}" class="link-blue">документации разработчика</router-link>
            <span
              class="note__triangle"
            ></span>
          </div>
        </div>

        <div class="section">
          <div class="section__title">
            <h3>Ключ API</h3>
          </div>

          <div class="note">
            Здесь указан Ваш ключ доступа для использования http API
            <span class="note__triangle"></span>
          </div>

          <div class="section__content">
            <span>Ваш ключ API:</span>
            <span class="token-inner">
              <span class="token-block" @click="copyOnClick">{{ this.$store.state.token.value }}</span>

              <transition name="fade">
                <span class="tippy" v-if="this.copied">Скопировано</span>
              </transition>
            </span>
          </div>
        </div>

        <div class="section">
          <div class="section__title">
            <h3>Добавочный номер</h3>
            <button v-if="this.$store.state.phones" class="btn" @click="openModal('delete-phone')">Удалить</button>
            <button v-else class="btn" @click="createPhone">Сгенерировать</button>
          </div>

          <div class="note">
            Здесь вы можете сгенерировать добавочный номер. Также можно удалить существующий
            <span
              class="note__triangle"
            ></span>
          </div>

          <div v-if="this.$store.state.phones" class="section__content">
            <span>Ваш добавочный номер:</span>
            <span class="number-block">{{ this.$store.state.phones.phone }}</span>
          </div>
        </div>

        <div class="section">
          <div class="section__title">
            <h3>Вебхуки</h3>

            <button class="btn" @click="openModal('add-webhook')">Добавить</button>
          </div>
          <div class="note">
            Здесь отображаются созданные вами webhooks, на данный момент мы поддерживаем только одно
            событие - смена статуса сделки. Подробнее о настройке вы можете узнать в нашей <router-link :to="{name: 'api'}" class="link-blue">документации</router-link>   
            <span
              class="note__triangle"
            ></span>
          </div>

          <div class="section__content" v-if="this.$store.state.webhooks.length">
            <div class="orders-table__table table">
              <div class="table__row table__row--header">
                <div class="table__cell table__cell--header">Идентификатор</div>
                <div class="table__cell table__cell--header">Адрес</div>
                <!-- <div class="table__cell table__cell--header">Событие</div> -->
                <div class="table__cell table__cell--header"></div>
              </div>

              <div v-for="(row, index) in this.$store.state.webhooks" class="table__row" :key="index">
                <div class="table__cell">{{row.name}}</div>
                <div class="table__cell">
                  <span class="status-badge in-progress">{{row.url}}</span>
                </div>
                <!-- <div class="table__cell">
                  {{row.event}}
                  
                </div>-->
                <div class="table__cell table__cell--btn">
                  <span class="delete-button" @click="openDelWebhookModal(row.id, index)">
                    <span></span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="section__content section__content--empty" v-else>
            <div class="orders-table__table table">
              <div class="table__row table__row--header">
                <div class="table__cell table__cell--header">Идентификатор</div>
                <div class="table__cell table__cell--header">Адрес</div>
                <div class="table__cell table__cell--header">Событие</div>
              </div>

              <div class="table__row">
                <div class="table__cell">1</div>
                <div class="table__cell">
                  <span class="status-badge in-progress">https://example.com/webhook</span>
                </div>
                <div class="table__cell">
                  статус
                  <span class="delete-button">
                    <span></span>
                  </span>
                </div>
              </div>
              <div class="table__row">
                <div class="table__cell">1</div>
                <div class="table__cell">
                  <span class="status-badge in-progress">https://example.com/webhook</span>
                </div>
                <div class="table__cell">
                  статус
                  <span class="delete-button">
                    <span></span>
                  </span>
                </div>
              </div>
              <div class="table__row">
                <div class="table__cell">1</div>
                <div class="table__cell">
                  <span class="status-badge in-progress">https://example.com/webhook</span>
                </div>
                <div class="table__cell">
                  статус
                  <span class="delete-button">
                    <span></span>
                  </span>
                </div>
              </div>
              <div class="table__row">
                <div class="table__cell">1</div>
                <div class="table__cell">
                  <span class="status-badge in-progress">https://example.com/webhook</span>
                </div>
                <div class="table__cell">
                  статус
                  <span class="delete-button">
                    <span></span>
                  </span>
                </div>
              </div>
              <div class="table__row">
                <div class="table__cell">1</div>
                <div class="table__cell">
                  <span class="status-badge in-progress">https://example.com/webhook</span>
                </div>
                <div class="table__cell">
                  статус
                  <span class="delete-button">
                    <span></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="blur">
              <div class="icon">
                <svg
                  version="1.1"
                  id="Слой_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="-549 451 100 100"
                  style="enable-background:new -549 451 100 100;"
                  xml:space="preserve"
                >
                  <g id="XMLID_160_">
                    <g id="XMLID_161_">
                      <path
                        id="XMLID_158_"
                        d="M-499,531.8c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                v-17.1c0,0-0.1,0-0.1,0.1C-463.2,525.2-479.3,531.8-499,531.8z"
                      ></path>
                      <path
                        id="XMLID_157_"
                        d="M-499,510.2c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                V495c0,0-0.1,0-0.1,0.1C-463.2,503.6-479.3,510.2-499,510.2z"
                      ></path>
                      <path
                        id="XMLID_156_"
                        d="M-499,488.5c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                v-17.1c0,0-0.1,0-0.1,0.1C-463.2,481.8-479.3,488.5-499,488.5z"
                      ></path>
                      <path
                        id="XMLID_155_"
                        d="M-499,484.7c20.7,0,37.4-7.4,37.4-16.4c0-9.1-16.7-16.4-37.4-16.4c-20.7,0-37.4,7.4-37.4,16.4
                                                C-536.4,477.3-519.6,484.7-499,484.7z M-499,463.8c5.6,0,10.2,2,10.2,4.4c0,2.5-4.5,4.4-10.2,4.4s-10.2-2-10.2-4.4
                                                C-509.2,465.7-504.6,463.8-499,463.8z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="text">Недостаточно данных</div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <ModalOverlay/>

    <AddWebhook v-if="this.$store.state.openedModal=='add-webhook'"/>
    <DeleteWebhook
      v-if="this.$store.state.openedModal=='delete-webhook'"
      @deleteWebhook="deleteWebhook"
    />
    <DeletePhone v-if="this.$store.state.openedModal=='delete-phone'" @deletePhone="deletePhone"/>

    <InfoMessage  :message="this.$store.state.message.text" :type="this.$store.state.message.type">
    
    <Preloader />
  </div>
</template>

<script>
import LeftMenu from "../Components/LeftMenu";
import ModalOverlay from "../Components/ModalOverlay";
import PageTitle from "../Components/PageTitle";
import AddWebhook from "./Modals/AddWebhook";
import DeleteWebhook from "./Modals/DeleteWebhook";
import DeletePhone from "./Modals/DeletePhone";
import MobileHeader from "../Components/MobileHeader";
import InfoMessage from "../Components/InfoMessage";
import axios from "axios";
import Preloader from "../Components/Preloader";

export default {
  name: "Integration",
  components: {
    LeftMenu,
    PageTitle,
    AddWebhook,
    DeleteWebhook,
    ModalOverlay,
    MobileHeader,
    DeletePhone,
    InfoMessage,
    Preloader
  },
  data() {
    return {
      copied:       null,
      webhookId:    null,
      webhookIndex: null
    };
  },
  methods: {
    openDelWebhookModal(id, index) {
      this.$store.commit("openModal", "delete-webhook");
      this.webhookId    = id;
      this.webhookIndex = index;
    },
    openModal(modalName) {
      this.$store.commit("openModal", modalName);
    },
    copyOnClick(e) {
      let element = e.target;
      let range, selection;

      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range     = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      try {
        document.execCommand("copy");
        this.copied = true;
        window.getSelection().removeAllRanges();

        setTimeout(() => {
          this.copied = null;
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    deletePhone() {
      this.$store.commit('deletePhone');
    },
    createPhone() {
      this.$store.commit('createPhone');
    },
    deleteWebhook() {
      this.$store.commit('deleteWebhook', {id: this.webhookId, index: this.webhookIndex})
    }
  },
  beforeMount() {
    this.$store.commit('getIntegrationData');
  }
};
</script>

<style lang="scss">
.integration {
  .table__cell {
    &:last-of-type {
      position: relative;
      max-width: 50px;
      min-width: 50px;
      // min-width: fit-content;
      border-left: none;
    }
  }
}

.link-blue {
  color: #419ef9;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}
.header {
  &__title {
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 1.7px;
    color: #313942;
    padding-left: 20px;
    margin-right: 10px;
  }
}

.section {
  color: #313942;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    letter-spacing: 1.7px;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 1em;
    h3 {
      font-size: inherit;
    }
  }

  &__content {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1.1px;
    display: flex;
    align-items: center;

    &--empty {
      position: relative;

      .table {
        filter: blur(5px);
        overflow: hidden;
      }
    }
  }

  .token-block,
  .number-block {
    display: inline-block;
    text-transform: none;
    margin-left: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-weight: 600;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .token-block {
    cursor: pointer;
  }
  .token-inner {
    display: inline-block;
    position: relative;
  }
}

.note {
  background: #fcfcfc;
  color: #92989b;
  line-height: 1.5;
  border-radius: 3px;
  padding: 1em 2em 1em 1.3em;
  border: 1px solid #ddd;
  font-size: 14px;
  position: relative;
  margin-bottom: 20px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 25px;
    top: 100%;
    border-left: 5px solid transparent;
    border-top: 5px solid #fff;
    border-right: 5px solid transparent;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 24px;
    top: 100%;
    border: 6px solid transparent;
    border-top-color: #ccc;
  }

  &__triangle {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 28px solid #ddd;
      border-right: 28px solid #f5f5f5;
      bottom: -1px;
      right: -1px;
    }
  }
}

.tippy {
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  font-weight: 400;
  border-radius: 5px;
  letter-spacing: initial;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 5px 0 5px;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  }
}

.delete-button {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  span {
    display: block;
    width: 20px;
    height: 20px;
    opacity: 0.3;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }

    &::before,
    &::after {
      position: absolute;
      left: 15px;
      content: "";
      height: 20px;
      width: 4px;
      background-color: rgb(253, 56, 66);
      border-radius: 5px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 768px) {
  .note {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .integration {
    .section__content {
      flex-direction: column;
      align-items: initial;

      .token-block,
      .number-block {
        margin-left: 0;
        margin-top: 10px;
        width: fit-content;
      }
    }
  }
}
</style>   