<template>
  <div class="modal">
    <div class="modal__title">
      <h3>Верификация телефона</h3>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form__row">
          <p>Введите код подтверждения из SMS-сообщения, отправленного на номер {{this.$store.state.newUserPhone}}</p>
        </div>
        <div class="form__row">
          <label for="codeInput" class="form__label">Код подтверждения:</label>
          <input
            type="text"
            class="form__input"
            v-model.trim="$v.code.$model"
            :class="{
                errorInput: $v.code.$anyError, 
                validInput: !$v.code.$anyError && $v.code.$dirty
            }"
            @input="submit"
          >
          <div class="error" v-if="!$v.code.required && $v.code.$dirty">Введите код подтверждения</div>
          <div
            class="error"
            v-if="!$v.code.minLength && $v.code.$dirty"
          >Код должен содержать не менее {{$v.code.$params.minLength.min}} символов</div>
        </div>
        <div class="form__row">
          <!-- <button class="btn" type="submit" @click.prevent="sendData">Отправить</button> -->
        </div>

        <!-- <div class="form__row">
          <p>Код действителен в течение {{seconds}} секунд</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "CodeModal",
  data() {
    return {
      code: null,
      // minutes: 4,
      // seconds: 200
    };
  },
  validations: {
    code: {
      required,
      minLength: minLength(6)
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.code.length === 6) {
          this.sendData();
        }
      }
    },
    sendData() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json"
        }
      });

      if (!this.$v.$invalid) {
        let dataToSend = this.$store.state.newUser;
        dataToSend.code = this.code;
        console.log(dataToSend);

        instance
          .post("/api/auth/create", dataToSend)
          .then(response => {
            this.$store.commit("setToken", response.data.access_token);

            let d = new Date(response.data.expires_at);
            let expires = "expires=" + d.toUTCString();
            document.cookie = "access_token=" + response.data.access_token + ";" + expires + ";path=/";

            this.$store.commit("closeModal");
            this.$router.push({ name: "leads" });
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.$store.commit("createMessage", {
                type: "danger",
                text: "Такой телефон уже зарегистрирован"
              });
            }
            if (error.response.status === 500) {
              this.$store.commit("createMessage", {
                type: "danger",
                text: this.$store.state.messages.serverError
              });
            }
          });
      }
    },
    // counter(seconds) {

    // }
  }
};
</script>
