<template>
  <div class="help">
    <LeftMenu/>
    <div class="page-container">
      <MobileHeader/>
      <PageTitle :title="'Справка'" feedbackButton/>
      <div>
        <div class="right-nav" :class="{opened: rightNavIsOpened}">
          <div class="open-right-nav" @click="rightNavIsOpened = !rightNavIsOpened">
            <span class="icon" :class="{opened: rightNavIsOpened}">
              <svg
                viewBox="0 -44.499 86 90.501"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M80.093,22.749c1.353,1.338,3.544,1.338,4.896,0c1.35-1.338,1.352-3.506,0-4.847L45.445-21.246  c-1.352-1.338-3.541-1.338-4.892,0L1.013,17.902c-1.35,1.341-1.352,3.507,0,4.847c1.352,1.338,3.543,1.338,4.895,0l37.091-35.703  L80.093,22.749z"
                ></path>
              </svg>
            </span>
          </div>
          <ul>
            <li>
              <a href="#auth">Lorem, ipsum dolor.</a>
            </li>
            <li>
              <a href="#send">Lorem, ipsum dolor.</a>
            </li>
          </ul>
        </div>
         <div class="content"></div>
      </div>
     
    </div>

    <Feedback v-if="this.$store.state.openedModal=='feedback-modal'"/>
    <ModalOverlay />
  </div>
</template>

<script>
import LeftMenu from "../Components/LeftMenu";
import PageTitle from "../Components/PageTitle";
import MobileHeader from "../Components/MobileHeader";
import ModalOverlay from "../Components/ModalOverlay";
import Feedback from "./Modals/Feedback";


export default {
  name: "Help",
  components: {
    LeftMenu,
    PageTitle,
    MobileHeader,
    ModalOverlay,
    Feedback
  },
  data() {
    return {
      rightNavIsOpened: false
    }
  }
};
</script>
