<template>
  <div class="modal-big">
    <div class="modal-big__header">
      <h3 class="modal-big__title">Добавить реквест</h3>
    </div>

    <div class="modal-big__body">
      <div class="form__row">
        <label class="form__label">Название запроса</label>
        <input
          type="text"
          class="form__input"
          v-model.trim="$v.name.$model"
          :class="{
              errorInput: $v.name.$anyError, 
              validInput: !$v.name.$anyError && $v.name.$dirty && $v.name.$model
            }"
        />
        <div class="error" v-if="!$v.name.required && $v.name.$dirty">Это обязательное поле</div>
      </div>

      <div class="form__row">
        <label class="form__label">Партнер:</label>
        <multiselect
          v-model="selectedUser"
          :options="this.$store.state.filters.users"
          label="name"
          :show-labels="false"
          value="id"
          :placeholder="'Название партнера'"
        >
          <span slot="noResult">Совпадений не найдено</span>
        </multiselect>
      </div>

      <div class="form__row">
        <input
          name="sendOrdersAddReq"
          id="sendOrdersAddReq"
          type="checkbox"
          class="checkbox-input"
          v-model="reqData.auth.status"
        />
        <label for="sendOrdersAddReq" class="chekbox">
          <span class="chekbox-label"></span>
          <span class="checkbox-text">Для отправки запроса требуется авторизация</span>
        </label>
      </div>

      <div class="form__request" v-if="reqData.auth.status">
        <div class="request__header" @click="toggleGroup('auth')">
          <p class="request__title">Авторизация</p>
          <span class="arr-down" :class="{closed: !isOpened.auth}">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 240.811 240.811"
              style="enable-background:new 0 0 240.811 240.811;"
              xml:space="preserve"
            >
              <path
                d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
              />
            </svg>
          </span>
        </div>

        <transition name="slide">
          <div class="request__body" v-show="isOpened.auth">
            <div class="form__row">
              <div class="form__label">Метод запроса:</div>
              <multiselect
                v-model.trim="$v.reqData.auth.method.$model"
                :options="this.methods"
                :multiple="false"
                :show-labels="false"
                placeholder="POST"
                :class="{
                  errorInput: $v.reqData.auth.method.$anyError, 
                  validInput: !$v.reqData.auth.method.$anyError && $v.reqData.auth.method.$dirty && $v.reqData.auth.method.$model
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
            </div>

            <div class="form__row">
              <div class="form__label">Тип запроса:</div>
              <multiselect
                v-model.trim="$v.reqData.auth.type.$model"
                :options="this.types"
                :multiple="false"
                :show-labels="false"
                placeholder="JSON"
                :class="{
                  errorInput: $v.reqData.auth.type.$anyError, 
                  validInput: !$v.reqData.auth.type.$anyError && $v.reqData.auth.type.$dirty && $v.reqData.auth.type.$model
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
            </div>

            <div class="form__row">
              <label for class="form__label">URL:</label>
              <input
                type="text"
                class="form__input"
                v-model.trim="$v.reqData.auth.url.$model"
                :class="{
                  errorInput: $v.reqData.auth.url.$anyError, 
                  validInput: !$v.reqData.auth.url.$anyError && $v.reqData.auth.url.$dirty && $v.reqData.auth.url.$model
                }"
                placeholder="https://api.sfgroup.ru/auth "
              />
            </div>

            <ModalInput :header="'Заголовки'" :inputs="reqData.auth.headers" @addField="addfield">
              <button class="btn" @click="addField({type:'auth', name: 'headers'})">+ Добавить поле</button>
            </ModalInput>

            <ModalInput :header="'Тело запроса'" :inputs="reqData.auth.data" @addField="addfield">
              <button class="btn" @click="addField({type:'auth', name: 'data'})">+ Добавить поле</button>
            </ModalInput>
          </div>
        </transition>
      </div>

      <div class="form__request">
        <div class="request__header" @click="toggleGroup('body')">
          <p class="request__title">Основной запрос</p>
          <span class="arr-down" :class="{closed: !isOpened.body}">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 240.811 240.811"
              style="enable-background:new 0 0 240.811 240.811;"
              xml:space="preserve"
            >
              <path
                d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
              />
            </svg>
          </span>
        </div>

        <transition name="slide">
          <div class="request__body" v-show="isOpened.body">
            <div class="form__row">
              <div class="form__label">Метод запроса:</div>
              <multiselect
                v-model.trim="$v.reqData.body.method.$model"
                :options="this.methods"
                :multiple="false"
                :show-labels="false"
                placeholder="POST"
                :class="{
                  errorInput: $v.reqData.body.method.$anyError, 
                  validInput: !$v.reqData.body.method.$anyError && $v.reqData.body.method.$dirty && $v.reqData.body.method.$model
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
            </div>

            <div class="form__row">
              <div class="form__label">Тип запроса:</div>
              <multiselect
                v-model.trim="$v.reqData.body.type.$model"
                :options="this.types"
                :multiple="false"
                :show-labels="false"
                placeholder="JSON"
                :class="{
                  errorInput: $v.reqData.body.type.$anyError, 
                  validInput: !$v.reqData.body.type.$anyError && $v.reqData.body.type.$dirty && $v.reqData.body.type.$model
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
            </div>

            <div class="form__row">
              <label for class="form__label">URL:</label>
              <input
                type="text"
                class="form__input"
                v-model.trim="$v.reqData.body.url.$model"
                :class="{
                  errorInput: $v.reqData.body.url.$anyError, 
                  validInput: !$v.reqData.body.url.$anyError && $v.reqData.body.url.$dirty && $v.reqData.body.url.$model
                }"
                placeholder="https://api.sfgroup.ru/order/create"
              />
            </div>

            <ModalInput :header="'Заголовки'" :inputs="reqData.body.headers" @addField="addfield">
              <button class="btn" @click="addField({type:'body', name: 'headers'})">+ Добавить поле</button>
            </ModalInput>

            <ModalInput :header="'Тело запроса'" :inputs="reqData.body.data" @addField="addfield">
              <button class="btn" @click="addField({type:'body', name: 'data'})">+ Добавить поле</button>
            </ModalInput>
          </div>
        </transition>
      </div>

      <div class="form__row">
        <div class="form__label">Выбор региона:</div>
        <multiselect
          v-model.trim="regions"
          :options="this.$store.state.filters.departments"
          :multiple="true"
          label="name"
          value="id"
          track-by="name"
          :show-labels="false"
          placeholder="Новосибирск"
        >
          <span slot="noResult">Совпадений не найдено</span>
        </multiselect>
      </div>

      <div class="form__row form__row--btns">
        <button class="btn btn__success" @click="addRequest">Добавить</button>
        <button class="btn btn__danger" @click="back">Отменить</button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInput from "../../Partners/Modals/ModalInputs/ModalInput";
import axios from "axios";
import {
  required,
  email,
  numeric,
  helpers,
  minLength,
  sameAs,
  between,
  requiredIf
} from "vuelidate/lib/validators";

export default {
  name: "AddRequestModal",
  components: {
    ModalInput
  },
  data() {
    return {
      name: "",
      user_id: null,
      reqData: {
        auth: {
          status: false,
          type: "json",
          method: "POST",
          url: "",
          headers: [],
          data: []
        },
        body: {
          type: "json",
          method: "POST",
          url: "",
          headers: [],
          data: []
        }
      },
      regions: [],
      methods: [
        "CONNECT",
        "DELETE",
        "GET",
        "HEAD",
        "OPTIONS",
        "PATCH",
        "POST",
        "PUT",
        "TRACE"
      ],
      types: ["json", "body"],
      isOpened: {
        auth: false,
        body: false
      },
      requiredAuth: false,
      users: [],
      selectedUser: {}
    };
  },
  methods: {
    back() {
      this.$store.commit("closeModal");
    },
    addField(obj) {
      this.reqData[obj.type][obj.name].push({ key: "", value: "" });
    },
    addRequest() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.access_token
          }
        });

        this.$store.commit("showPreloader");
        let departments = [];
        if (this.regions.length) {
          this.regions.forEach(function(city) {
            departments.push(city.id);
          });
        }

        let data = {};

        if (this.reqData.auth.status) {
          data.auth = {};

          data.auth.status = this.reqData.auth.status;
          data.auth.method = this.reqData.auth.method;
          data.auth.type = this.reqData.auth.type;
          data.auth.url = this.reqData.auth.url;

          data.auth.headers = {};
          this.reqData.auth.headers.forEach(function(obj) {
            data.auth.headers[obj.name] = obj.value;
          });

          data.auth.data = {};
          this.reqData.auth.data.forEach(function(obj) {
            data.auth.data[obj.name] = obj.value;
          });
        }

        data.body = {};

        data.body.method = this.reqData.body.method;
        data.body.type = this.reqData.body.type;
        data.body.url = this.reqData.body.url;

        data.body.headers = {};
        this.reqData.body.headers.forEach(function(obj) {
          data.body.headers[obj.name] = obj.value;
        });

        data.body.data = {};
        this.reqData.body.data.forEach(function(obj) {
          data.body.data[obj.name] = obj.value;
        });

        let dataToSend = {
          name: this.name,
          user_id: this.selectedUser.id,
          data: data,
          cities: departments
        };

        let regions = [];
        for (let i = 0; i < departments.length; i++) {
          this.$store.state.filters.departments.forEach(function(obj) {
            if (obj.id === i) {
              regions.push(obj);
            }
          });
        }

        console.log(dataToSend);

        instance
          .post("/api/post/admin/request/create", dataToSend)
          .then(response => {
            this.reqData = {
              auth: {
                status: null,
                type: [],
                method: [],
                url: "",
                headers: [],
                data: []
              },
              body: {
                type: null,
                method: null,
                url: "",
                headers: [],
                data: []
              }
            };

            instance
                    .get("api/post/admin/request/show")
                    .then(response => {
                      this.$store.commit("setRequests", response.data);
                      this.$store.commit("hidePreloader");
                      this.$store.commit("createMessage", {
                        type: "success",
                        text: "Запрос успешно добавлен"
                      });
                      this.$store.commit("closeModal");
                    })
                    .catch(error => {
                      if (error.response.status === 401) {
                        this.$store.commit("forceLogout");
                      }
                      if (error.response.status === 422) {
                        alert("422");
                      }
                      if (error.response.status === 500) {
                        this.$store.commit("createMessage", {
                          type: "danger",
                          text: this.$store.state.messages.serverError
                        });
                      }
                    });
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("forceLogout");
            }
            if (error.response.status === 500) {
              this.$store.commit("hidePreloader");
              this.$store.commit("createMessage", {
                type: "error",
                text: this.$store.state.serverError
              });
            }
          });
      }
    },
    toggleGroup(group) {
      this.isOpened[group] = !this.isOpened[group];
    },
  },
  computed: {},
  validations: {
    name: {
      required
    },
    reqData: {
      auth: {
        url: {
          required: requiredIf(function() {
            return this.reqData.auth.status;
          })
        },
        method: {
          required: requiredIf(function() {
            return this.reqData.auth.status;
          })
        },
        type: {
          required: requiredIf(function() {
            return this.reqData.auth.status;
          })
        }
      },
      body: {
        url: {
          required
        },
        method: {
          required
        },
        type: {
          required
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  &__request {
    border: 1px solid #ced4da;

    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
.request {
  &__header {
    position: relative;
    font-size: 17px;
    padding: 0.75rem;
  }

  &__body {
    padding: 0.75rem;
    border-top: 1px solid #ced4da;
  }
}

.arr-down {
  right: 0.75rem;
  top: 50%;
  width: 15px;
  height: 15px;
  transition: 0.3s;

  svg {
    transition: 0.3s;
  }

  &.closed {
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
