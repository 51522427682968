import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import HomePage from './Pages/HomePage/HomePage';
import Leads from './Pages/Leads/Leads';
import Integration from './Pages/Integration/Integration';
import Api from './Pages/Documentation/Api';
import Webhooks from './Pages/Documentation/Webhooks';
import Settings from './Pages/Settings/Settings';
import News from "./Pages/News/News.vue";
import Partners from "./Pages/Partners/Partners.vue";
import Help from "./Pages/Help/Help.vue";
import Requests from "./Pages/Requests/Requests.vue"
import store from './store.js';

Vue.config.productionTip = false;

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: HomePage,
    meta: {
      title: 'Партнерский кабинет',
    },
    name: 'home',
  },
  {
    path: '/leads',
    component: Leads,
    meta: {
      title: 'Заявки',
      requiresAuth: true
    },
    name: 'leads'
  },
  {
    path: '/integration',
    component: Integration,
    meta: {
      title: 'Интеграция',
      requiresAuth: true
    },
    name: 'integration'
  },
  {
    path: '/documentation-api',
    component: Api,
    meta: {
      title: 'Документация | API',
      requiresAuth: true
    },
    name: 'api'
  },
  {
    path: '/documentation-webhooks',
    component: Webhooks,
    meta: {
      title: 'Документация | Webhooks',
      requiresAuth: true
    },
    name: 'webhooks'
  },
  {
    path: '/news',
    component: News,
    meta: {
      title: 'Новости',
      requiresAuth: true
    },
    name: 'news'
  },
  {
    path: '/partners',
    component: Partners,
    meta: {
      title: 'Партнёры',
      requiresAuth: true
    },
    name: 'partners'
  },
  {
    path: '/requests',
    component: Requests,
    meta: {
      title: 'Реквесты',
      requiresAuth: true
    },
    name: 'requests'
  },
  {
    path: '/settings',
    component: Settings,
    meta: {
      title: 'Настройки',
      requiresAuth: true
    },
    name: 'settings'
  },
  {
    path: '/help',
    component: Help,
    meta: {
      title: 'Справка по сайту',
      requiresAuth: true
    },
    name: 'help'
  }

];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let matches = document.cookie.match(new RegExp("(?:^|; )" + "access_token".replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
    let token = matches ? decodeURIComponent(matches[1]) : "";
    store.commit("setToken", token);



    if (!store.state.access_token) {
      next({
        path: '/',
      });
    } else {
      next();
    }
    
  } else {
    store.commit('closeModal');
    next();
  }

  if (store.state.openedModal === 'mobileMenu') {
    store.commit('closeModal');
    next();
  } else {
    next();
  }
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app');

export {
  router
}