import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import moment from 'moment';
import "moment/locale/ru";
import "amcharts3";
import "amcharts3/amcharts/serial";
import { router } from './main';

Vue.use(Vuex);
Vue.use(axios);

function dateFormat(date) {
  return moment(date).format("YYYY-MM-DD");
}
// && !el.contains(e.target)
function newsListener(e) {
  let el = document.querySelector('.notifications');
  if (e.target != el) {
    store.commit('closeNotifications');
  }
}

function userListener(e) {
  let el = document.querySelector('.tooltip');
  if (e.target != el) {
    store.commit('closeUserTooltip');
  }
}

const store = new Vuex.Store({
  state: {
    // Токен доступа
    access_token: null,
    // Открытая модалка
    openedModal: null,
    // Данные для фильтров
    filters: {
      departments: [],
      products: [],
      stages: [],
      users: [],
      isSend: ""
    },
    // Тип заявки 
    orderTypes: [
      'Ручная заявка',
      'Переход по ссылке',
      'Программный интерфейс',
      'Звонок'
    ],
    // Информирующие сообщения
    messages: {
      webhookCreated: "Вебхук создан",
      webhookDeleted: "Вебхук удален",
      phoneDeleted: "Добавочный номер удален",
      phoneCreated: "Добавочный номер создан",
      loginError: "Неверный логин или пароль",
      serverError: "Внутренняя ошибка сервера",
      invalidData: "Некорректные данные"
    },
    newUserPhone: null,
    newUser: {},
    user: {
      name: ''
    },
    userId: null,
    // E-mail учетки
    userEmail: null,
    // Баланс
    balance: null,
    // Данные о компании
    company: {},
    // Данные для графика 
    chartData: [],
    // Кол-во заявок общее, выполенных и отклоненных
    counts: {},
    // Заявки
    orders: [],
    // Кол-во подгружаемых дполнительно заявок при скролле
    offset: 15,
    // Выбранные фильтры
    ordersFilter: null,
    // Вею-хуки
    webhooks: [],
    // Данные о партнерах: partners - подтвержденные, orders - еще нет
    partnersData: {
      partners: null,
      orders: []
    },
    // Токен в интеграции
    token: {},
    // Телефоны в интеграции
    phones: {},
    // Данные об информирующем сообщении
    message: {
      text: '',
      type: null,
      isVisible: false
    },
    // Параметр поиска
    searchParam: '',
    // Все новости
    allNews: [],
    // Текущая новость
    curNews: {},
    // Модерация пользователя
    userStage: null,
    // Права пользователя
    isAdmin: null,
    // Оффсет для новостей
    newsOffset: 15,

    notificationNews: {
      count: null,
      news: []
    },
    requests: [],
    notificationsOpened: false,
    userTooltipOpened: false,
    preloaderIsVisible: false,
    curRequestId: null,
    curReqInfo: [],
    curReq: null
  },
  mutations: {
    // Открыть модальное окно
    openModal(state, modal) {
      state.openedModal = modal;
      // document.body.style.overflow = "hidden";
      // document.getElementById('app').style.overflow = 'hidden';
      document.documentElement.style.overflow = "hidden";
    },
    // Закрыть модальное окно
    closeModal(state) {
      state.openedModal = null;
      // document.body.removeAttribute("style");
      // document.getElementById('app').removeAttribute("style");
      document.documentElement.removeAttribute("style");
    },
    // Принудительный выход при 401 ошибке
    forceLogout(state) {
      state.access_token = null;
      document.cookie = "access_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      router.push({ name: 'home' });
    },
    // Вывод информирующего сообщения
    createMessage(state, data) {
      state.message.text = data.text;
      state.message.type = data.type;
      state.message.isVisible = true;

      setTimeout(() => {
        store.commit('deleteMessage');
      }, 5000);
    },
    // Скрыть информирующее сообщение
    deleteMessage(state) {
      state.message.text = '';
      state.message.type = null;
      state.message.isVisible = false;
    },
    // Сохранить токен доступа
    setToken(state, token) {
      state.access_token = token;
    },
    // Заявки: получение изначальных данных
    getData(state) {
      const instance = axios.create({
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.access_token
        }
      });

      store.commit('showPreloader');

      instance.get('/api/get/app')
        .then(response => {
          state.filters = response.data.filters;
          state.userId = response.data.id;
          state.user = response.data.user_info;
          state.userEmail = response.data.email;
          state.balance = response.data.balance;
          state.company = response.data.company;
          state.orders = response.data.order;
          state.counts = response.data.count;
          state.chartData = response.data.graph.chart.data;
          state.userStage = response.data.stage;
          state.isAdmin = response.data.isAdmin;
          state.notificationNews = response.data.news;

          store.commit('hidePreloader');
        })
        .catch(error => {
          if (error.response.status === 401) {
            store.commit('forceLogout');
          }
          if (error.response.status === 500) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
            store.commit('forceLogout');
          }
        });
    },
    // Заявки: данные после фильтров
    updateOrders(state, data) {
      if (Array.isArray(data.orders)) {
        state.orders = data.orders;
        state.counts = data.counts;
      } else {
        state.orders = [];
        state.counts = data.counts;
      }
    },
    // Заявки: подгрузка заявок при скролле
    addNewOrders(state, data) {
      if (Array.isArray(data)) {
        for (let order of data) {
          state.orders.push(order);
        }

        state.offset += 15;
      } else {
        // TODO: Вывод ошибки
      }
    },
    resetOffset(state) {
      store.state.offset = 15;
    },
    resetFilters(state) {
      store.state.ordersFilter = "";
    },
    // Заявки: выбранные фильтры
    addFilter(state, data) {
      state.ordersFilter = "";
      console.log(data)
      // Период
      if (data.range && data.range.start) {
        let from = dateFormat(data.range.start);
        let to = dateFormat(data.range.end);
        state.ordersFilter += `filters[interval][date][from]=${from}&filters[interval][date][to]=${to}&`;
      }
      // Регион
      if (data.regions && data.regions.length) {
        for (let region of data.regions) {
          state.ordersFilter += `filters[department][]=${region.id}&`;
        }
      }
      //Тип заявки
      if (data.product && data.product.id) {
        state.ordersFilter += `filters[product][]=${data.product.id}&`;
      }
      // Статус заявки
      if (data.stage && data.stage.id) {
        state.ordersFilter += `filters[stages][]=${data.stage.id}&`;
      }

      if (data.users) {
        state.ordersFilter += `filters[users][]=${data.users.id}&`;
      }

      if(data.isSend || store.state.isSend){
        state.ordersFilter += "type=send&"; 
      }

      return state.ordersFilter;
    },

    // Интеграция: получить данные об интеграции
    getIntegrationData(state) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.state.access_token
        }
      });

      store.commit('showPreloader');

      instance.get("/api/get/integration")
        .then(response => {
          state.phones = response.data.phones;
          store.state.webhooks = response.data.webhook;
          state.token = response.data.tokens;
          store.commit('hidePreloader');
        })
        .catch(error => {
          if (error.response.status === 401) {
            store.commit('forceLogout');
          }
        })
    },
    // Партнёры: получение данных партнёров
    setPartners(state, data) {
      state.partnersData.partners = [];
      state.partnersData.orders = [];
      for (let partner of data) {
        if (partner.stage === 1 || partner.stage === 2) {
          state.partnersData.partners.push(partner);
        }
        if (partner.stage === 0) {
          state.partnersData.orders.push(partner);
        }
      }
    },
    // Партнёры: редактирование данных партнёров и удаление партнёров
    changePartnersData(state, data) {
      if (data.type === 'deletePartner') {
        data.data.id.forEach((id) => {
          for (let partner in state.partnersData.partners) {
            if (state.partnersData.partners[partner].id === id) {
              state.partnersData.partners.splice(partner, 1);
              break;
            }
          }
        });
      }
      if (data.type === 'deleteOrder') {
        data.data.id.forEach((id) => {
          for (let order in state.partnersData.orders) {
            if (state.partnersData.orders[order].id === id) {
              state.partnersData.orders.splice(order, 1);
              break;
            }
          }
        });
      }
      if (data.type === 'update') {
        for (let partner in state.partnersData.partners) {
          if (state.partnersData.partners[partner].id === data.data.id) {
            data.data.conversion = state.partnersData.partners[partner].conversion;
            state.partnersData.partners.splice(partner, 1);
            state.partnersData.partners.splice(partner, 0, data.data);
            break;
          }
        }
      }
      if (data.type === 'updateOrder') {
        for (let partner in state.partnersData.orders) {
          if (state.partnersData.orders[partner].id === data.data.id) {
            data.data.conversion = state.partnersData.orders[partner].conversion;
            state.partnersData.orders.splice(partner, 1);
            state.partnersData.partners.push(data.data);
            break;
          }
        }
      }
      if (data.type === 'PartnerToOrder') {
        for (let partner in state.partnersData.partners) {
          if (state.partnersData.partners[partner].id === data.data.id) {
            data.data.conversion = state.partnersData.partners[partner].conversion;
            state.partnersData.partners.splice(partner, 1);
            state.partnersData.orders.push(data.data);
            break;
          }
        }
      }
    },
    addPartner(state, data) {
      state.partnersData.partners.push(data);
    },
    // Интеграция: Создать веб-хук
    createWebhook(state, data) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + store.state.access_token
        }
      });

      store.commit('showPreloader');

      let sendObj = {
        name: data.name,
        url: data.url
      };

      instance.post("/api/post/integration/webhook/create", sendObj)
        .then(response => {
          state.webhooks.push({ name: data.name, url: data.url, id: response.data.id });
          store.commit('hidePreloader');
          store.commit("closeModal");
          store.commit('createMessage', { type: 'success', text: state.messages.webhookCreated });
        })
        .catch(error => {
          if (error.response.status === 401) {
            store.commit('forceLogout');
          }
          if (error.response.status === 500) {
            store.commit('createMessage', { type: 'danger', text: state.messages.serverError });
          }
        });
    },
    // Интеграция: Удалить веб-хук
    deleteWebhook(state, data) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.state.access_token
        }
      });

      store.commit('showPreloader');

      instance.post("/api/post/integration/webhook/delete", { id: data.id })
        .then(response => {
          if (response.data.result) {
            state.webhooks.splice(data.index, 1);
            store.commit("closeModal");
            store.commit('createMessage', { type: 'success', text: state.messages.webhookDeleted });
          } else {
            store.commit('createMessage', { type: 'danger', text: response.data.message });
          }

          data.id = null;
          data.index = null;
        })
        .catch(error => {
          if (error.response.status === 401) {
            store.commit('forceLogout');
          }
          if (error.response.status === 500) {
            store.commit('createMessage', { type: 'danger', text: state.messages.serverError });
          }
        });
    },
    // Интеграция: Сгенерировать номер 
    createPhone(state) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.state.access_token
        }
      });

      instance.post("/api/post/integration/phone/create")
        .then(response => {
          state.phones = response.data.phones;
          store.commit('createMessage', { type: 'success', text: state.messages.phoneCreated });
        });
    },
    // Интеграция: Удаление номера
    deletePhone(state) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.state.access_token
        }
      });

      store.commit('showPreloader');

      instance.post("/api/post/integration/phone/delete")
        .then(() => {
          state.phones = null;
          store.commit('hidePreloader');
          store.commit("closeModal");
          store.commit('createMessage', { type: 'success', text: state.messages.phoneDeleted });
        })
        .catch(error => {
          if (error.response.status === 401) {
            store.commit('createMessage', { type: 'danger', text: state.messages.loginError });

          }
          if (error.response.status === 500) {
            store.commit('createMessage', { type: 'danger', text: state.messages.serverError });
          }
        });
    },
    //Заявки: Живой поиск
    search(state, data) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.state.access_token
        }
      });

      store.commit('setSearchParam', data);

      let filter = '';
      
      if(store.state.isSend){
        store.commit('addFilter', {isSend: true});
      }

      if (store.state.ordersFilter) {
        filter += store.state.ordersFilter;
      }
      if (store.state.searchParam) {
        filter += store.state.searchParam;
      }
      // if(store.state.isSend) {
      //   filter += "&type=send"
      // }

      instance.get(`/api/get/orders?${filter}`)
        .then(response => {
          state.orders = response.data.orders;
          state.counts = response.data.counts;
        })
      //.catch(error =>{})
    },
    setSearchParam(state, data) {
      state.searchParam = 'filters[search]=' + data;
    },
    setNewUserData(state, data) {
      state.newUser.name = data.name;
      state.newUser.company = data.company;
      state.newUser.phone = data.phone;
      state.newUser.password = data.password;
      state.newUser.password_confirmation = data.password_confirmation;
    },
    setNewUserPhone(state, data) {
      state.newUserPhone = data;
    },
    // Новости: получить все новости
    setAllNews(state, data) {
      if (!data.message) {
        state.allNews = data;
      }
    },
    // Новости: назначить текущую новость
    setCurNews(state, data) {
      state.curNews = data;
    },
    // Новости: удалить новость из всех
    delFromAllNews(state, data) {
      for (let i in state.allNews) {
        if (state.allNews[i].id === data.id) {
          state.allNews.splice(i, 1);
          break;
        }
      }
    },
    // Новости: изменить новость
    editNewsInAllNews(state, data) {
      state.allNews[data.index] = data.data;
    },
    // Новости: добавить новую новость к старым
    addNewNews(state, data) {
      if (Array.isArray(data)) {
        for (let news of data) {
          state.allNews.push(news);
        }

        state.newsOffset += 15;
      } else if (!data.message) {
        state.allNews.unshift(data);
      }
    },
    updateCompanyInfo(state, data) {
      if (data.name) {
        state.company.name = data.name;
      }
      if (data.address) {
        state.company.address = data.address;
      }
      if (data.phone) {
        state.company.phone = data.phone;
      }
      if (data.email) {
        state.company.email = data.email;
      }
      if (data.description) {
        state.company.description = data.description;
      }
    },
    updateUserInfo(state, data) {
      if (data.name) {
        state.user.name = data.name;
      }
      if (data.email) {
        state.userEmail = data.email;
      }
    },
    openNotifications(state) {
      state.notificationsOpened = true;

      setTimeout(function () {
        document.addEventListener('click', newsListener);
      }, 100);

    },
    closeNotifications(state) {
      state.notificationsOpened = false;

      setTimeout(function () {
        document.removeEventListener('click', newsListener);
      }, 100);
    },
    openUserTooltip(state) {
      state.userTooltipOpened = true;

      setTimeout(function () {
        document.addEventListener('click', userListener);
      }, 100);
    },
    closeUserTooltip(state) {
      state.userTooltipOpened = false;

      setTimeout(function () {
        document.removeEventListener('click', userListener);
      }, 100);
    },
    showPreloader(state) {
      state.preloaderIsVisible = true;
    },
    hidePreloader(state) {
      state.preloaderIsVisible = false;
    },
    setRequests(state, data) {
      state.requests = data;
    },
    setCurRequestId(state, id) {
      state.curRequestId = id;
    },
    setCurReqInfo(state, data) {
      state.curReqInfo = data;
    },
    deleteFromRequests(state) {
      state.requests.forEach((request, index) => {
        if (request.id === state.curRequestId) {
          state.requests.splice(index, 1);
        }
      });
    },
    addRequest(state, data) {
      state.requests.push(data);
    },
    setCurReq(state, data) {
      state.curReq = data;
    }
  }
});


export default store
