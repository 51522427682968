<template>
  <transition name="fade">
    <div v-if="this.$store.state.message.isVisible" class="info-message" :class="type">
      <div class="info-message__header">
        <div class="close-button" @click="closeMessage">
          <span></span>
        </div>
      </div>
      <div class="info-message__content">
        <div class="info-message__text">{{ message }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "InfoMessage",
  props: {
    message: String,
    type: String
  },
  methods: {
    closeMessage() {
      this.$store.commit('deleteMessage');
    }
  },
};
</script>

<style lang="scss">
.info-message {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  width: 90vw;
  max-width: 400px;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 0.875em;
  border: 1px solid;

  &__header {
    position: relative;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &.danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;

    .close-button {
      span {
        &:before,
        &:after {
          background-color: #a94442 !important;
        }
      }
    }
  }

  &.success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;

    .close-button {
      span {
        &:before,
        &:after {
          background-color: #3c763d !important;
        }
      }
    }
  }

  &.info {
    background-color: #d9edf7;
    color: #31708f;
    border-color: #bce8f1;

    .close-button {
      span {
        &:before,
        &:after {
          background-color: #31708f !important;
        }
      }
    }
  }

  .close-button {
    width: 15px;
    height: 15px;
    top: 0;
    right: 0;
    opacity: 1;

    span {
      &:before,
      &:after {
        width: 3px;
        border-radius: 3px;
      }
    }
  }
}

@media (max-width: 768px) {
  // .info-message {
  //   width: 90vw;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
}
</style>