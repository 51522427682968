<template>
  <div class="chartContainer">
    <div class="chartContainer__inner" v-if="this.chartData && this.chartData.length">
      <div class="switch-chart">
        <p class="switch-chart-day" @click="showDaysChart" :class="{ active : this.curChart === 'day' }">По дням</p>
        <p class="switch-chart-month" @click="showMonthChart" :class="{ active : this.curChart === 'month' }">По месяцам</p>
      </div>
      <div id="chartdiv" style="width: 100%; height: 400px;"></div>
      <div class="chart-legend">
        <p class="chart-legend-title">Выбрано меток:</p>
        <p
          class="chart-legend-item"
          v-for="(graph, index) in this.chartSettings.graphs"
          :key="index"
          :style="'background-color: '+ graph.lineColor + ';'"
          :id="index"
          @click="graphToggle"
        >{{ graph.title }}</p>
      </div>
    </div>

    <div class="chartContainer__empty" v-else>
      <div class="blur">
        <div class="icon">
          <svg
            version="1.1"
            id="Слой_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-549 451 100 100"
            style="enable-background:new -549 451 100 100;"
            xml:space="preserve"
          >
            <g id="XMLID_160_">
              <g id="XMLID_161_">
                <path
                  id="XMLID_158_"
                  d="M-499,531.8c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                    v-17.1c0,0-0.1,0-0.1,0.1C-463.2,525.2-479.3,531.8-499,531.8z"
                ></path>
                <path
                  id="XMLID_157_"
                  d="M-499,510.2c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                    V495c0,0-0.1,0-0.1,0.1C-463.2,503.6-479.3,510.2-499,510.2z"
                ></path>
                <path
                  id="XMLID_156_"
                  d="M-499,488.5c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                    v-17.1c0,0-0.1,0-0.1,0.1C-463.2,481.8-479.3,488.5-499,488.5z"
                ></path>
                <path
                  id="XMLID_155_"
                  d="M-499,484.7c20.7,0,37.4-7.4,37.4-16.4c0-9.1-16.7-16.4-37.4-16.4c-20.7,0-37.4,7.4-37.4,16.4
                                    C-536.4,477.3-519.6,484.7-499,484.7z M-499,463.8c5.6,0,10.2,2,10.2,4.4c0,2.5-4.5,4.4-10.2,4.4s-10.2-2-10.2-4.4
                                    C-509.2,465.7-504.6,463.8-499,463.8z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="text">Недостаточно данных</div>
      </div>
    </div>
  </div>
</template>

<script>
import "amcharts3";
import "amcharts3/amcharts/serial";
import axios from "axios";

export default {
  name: "OrderChart",
  data() {
    return {      
      chartSettings: {
        type: "serial",
        theme: "light",
        pathToImages: "http://cdn.amcharts.com/lib/3/images/",
        language: "ru",
        dataDateFormat: "YYYY-MM-DD",
        valueAxes: [
          {
            id: "v1",
            axisAlpha: 0,
            position: "left",
            ignoreAxisWidth: true
          }
        ],
        balloon: {
          borderThickness: 1,
          shadowAlpha: 0
        },
        graphs: [
          {
            id: "g4",
            lineColor: "#f44336",
            balloon: {
              drop: true,
              adjustBorderColor: false,
              color: "#ffffff"
            },
            bullet: "round",
            bulletBorderAlpha: 1,
            bulletColor: "#FFFFFF",
            bulletSize: 5,
            hideBulletsCount: 50,
            lineThickness: 2,
            title: "Api",
            useLineColorForBulletBorder: true,
            valueField: "api",
            balloonText:
              '<span style="font-size:18px; color: #ffff">[[value]]</span>'
          },
          {
            id: "g2",
            lineColor: "#2196f3",
            balloon: {
              drop: true,
              adjustBorderColor: false,
              color: "#ffffff"
            },
            bullet: "round",
            bulletBorderAlpha: 1,
            bulletColor: "#FFFFFF",
            bulletSize: 5,
            hideBulletsCount: 50,
            lineThickness: 2,
            title: "Звонки",
            useLineColorForBulletBorder: true,
            valueField: "phone",
            balloonText:
              '<span style="font-size:18px; color: #ffff">[[value]]</span>'
          },
          {
            id: "g3",
            lineColor: "#ff9800",
            balloon: {
              drop: true,
              adjustBorderColor: false,
              color: "#ffffff"
            },
            bullet: "round",
            bulletBorderAlpha: 1,
            bulletColor: "#FFFFFF",
            bulletSize: 5,
            hideBulletsCount: 50,
            lineThickness: 2,
            title: "Ручные",
            useLineColorForBulletBorder: true,
            valueField: "hands",
            balloonText:
              '<span style="font-size:18px; color: #ffff">[[value]]</span>'
          }
        ],
        chartScrollbar: {
          graph: "g4",
          oppositeAxis: false,
          offset: 30,
          scrollbarHeight: 80,
          backgroundAlpha: 0,
          selectedBackgroundAlpha: 0.1,
          selectedBackgroundColor: "#888888",
          graphFillAlpha: 0,
          graphLineAlpha: 0.5,
          selectedGraphFillAlpha: 0,
          selectedGraphLineAlpha: 1,
          autoGridCount: true,
          color: "#AAAAAA",
        },
        dataProvider: [],
        chartCursor: {
          pan: true,
          valueLineEnabled: true,
          valueLineBalloonEnabled: true,
          cursorAlpha: 0.1,
          cursorColor: "#168deac7",
          limitToGraph: "g3",
          valueLineAlpha: 0.2,
          valueZoomable: true
        },
        categoryField: "dates",
        categoryAxis: {
          parseDates: true,
          dashLength: 1,
          minorGridEnabled: true
        }
      },
      chart: {},
      curChart: 'day',
      lock: false
    };
  },
  methods: {
    showDaysChart() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.access_token
        }
      });

      this.$store.commit('showPreloader');

      instance.get("/api/get/orders/graph?type=day").then(response => {
        this.curChart = 'day';
        this.chart.dataProvider = response.data.chart.data;
        this.chart.validateData();
        this.chart.animateAgain();
        this.$store.commit('hidePreloader');
      });
    },
    showMonthChart() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.access_token
        }
      });

      this.$store.commit('showPreloader');

      instance.get("/api/get/orders/graph?type=month").then(response => {
        this.curChart = 'month';
        this.chart.dataProvider = response.data.chart.data;
        this.chart.validateData();
        this.chart.animateAgain();
        this.$store.commit('hidePreloader');        
      });
    },
    createOrderChart(chartData) {
      this.chartSettings.dataProvider = chartData;
      this.chart = AmCharts.makeChart("chartdiv", this.chartSettings);
      let chart = this.chart;

      chart.addListener("rendered", function() {
        return chart.zoomToIndexes(
          chart.dataProvider.length - 40,
          chart.dataProvider.length - 1
        );
      });

      chart.zoomToIndexes(
        chart.dataProvider.length - 40,
        chart.dataProvider.length - 1
      );

      // console.log('chart created');
      // console.log( chart.dataProvider);
    },
    graphToggle(e) {
      let chart = this.chart;
      let id    = e.target.id;
      e.target.classList.toggle("notactive");

      if (!e.target.classList.contains("notactive")) {
        chart.showGraph(chart.graphs[id]);
      } else {
        chart.hideGraph(chart.graphs[id]);
      }
    }
  },
  computed: {
    chartData() { 
      return this.$store.state.chartData;
    }
  },
  updated() {
    if (!this.lock) {
      this.lock = true;
      this.createOrderChart(this.chartData); 
    }
  },
  mounted() {
    if (this.$store.state.chartData.length) {
      this.createOrderChart(this.chartData);
      this.lock = !this.lock;
    }
  },

};
</script>

<style lang="scss">
.chartContainer {
  padding: 20px;
  padding-top: 40px;
  border: 1px solid #ddd;
  position: relative;

  &__inner {
    display: flex;
  }
}

.switch-chart {
  position: absolute;
  width: 100%;
  top: 19px;
  left: 0;
  display: flex;
  justify-content: center;

  &-day,
  &-month {
    font-size: 12px;
    width: 100px;
    padding: 3px 10px;
    background-color: #eee;
    color: #666;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 3px;
    cursor: pointer;

    &.active {
      background-color: #fff;
      color: #000;
    }
  }

  &-day {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  &-month {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.chart-legend {
  width: 130px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.amChartsLegend {
  display: none;
}

.chart-legend-item {
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.chart-legend-title {
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

.notactive {
  background-color: #bbb !important;
}

.chartContainer__empty {
  height: 300px;
}
</style>
