<template>
  <div class="documentation">
    <LeftMenu/>
    <main class="page-container">
      <MobileHeader/>
      <PageTitle :title="'Методы API'"/>
      <div>
        <div class="right-nav" :class="{opened: rightNavIsOpened}">
          <div class="open-right-nav" @click="rightNavIsOpened = !rightNavIsOpened">
            <span class="icon" :class="{opened: rightNavIsOpened}">
              <svg
                viewBox="0 -44.499 86 90.501"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M80.093,22.749c1.353,1.338,3.544,1.338,4.896,0c1.35-1.338,1.352-3.506,0-4.847L45.445-21.246  c-1.352-1.338-3.541-1.338-4.892,0L1.013,17.902c-1.35,1.341-1.352,3.507,0,4.847c1.352,1.338,3.543,1.338,4.895,0l37.091-35.703  L80.093,22.749z"
                ></path>
              </svg>
            </span>
          </div>
          <ul>
            <li>
              <a href="#auth">Авторизация</a>
            </li>
            <li>
              <a href="#send">Отправка заявки</a>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="section">
            <div class="section__content">
              HTTP API Sfgroup по принципу авторизации похож на Amazon REST API. API ключ полученный вами в панели управления используется для получения закрытого ключа , все дальнейшие запросы не связанные с авторизацией должны осуществляться при помощи него.
              Также необходимо добавить специальные HTTP заголовки в запрос. Тело запроса должно быть в формате "application/json".
            </div>
          </div>
          <div class="section section-1" id="auth">
            <h3 class="section__title">Авторизация</h3>
            <div class="section__body">
              <div class="section__subtitle">Получение ключа</div>
              <table>
                <thead>
                  <th>Параметр</th>
                  <th>Тип</th>
                  <th>Описание</th>
                </thead>
                <tbody>
                  <tr>
                    <td>token</td>
                    <td>string</td>
                    <td>Ваш ключ Api, который Вы можете получить в разделе интеграции в личном кабинете</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="section__body">
              <div class="section__subtitle">Запрос</div>
              <pre class="language-js">
  <code> URL: https://api.sfgroup.ru/auth</code> 
</pre>
              <pre class="language-js">  <code>
{
  "token": YOUR_API_KEY
}
  </code></pre>
            </div>
            <div class="section__body">
              <div class="section__subtitle">Ответ</div>
              <pre class="language-js"><code>
{
  "status": 200,
  "data": {
    "key": 	 PRIVATE_KEY
  }
}
  </code></pre>
            </div>
            <div class="section__body">
              <p>
                Все последующие запросы должны совершаться с передачей закрытого ключа в заголовке -
                <b>x-sfg-authentication</b>.
              </p>
            </div>
          </div>

          <div class="section" id="sendOrder">
            <div class="section__title" id="send">Отправка заявки</div>
            <div class="section__body">
              <div class="section__subtitle">Параметры</div>
              <div class="section__content">
                <table class="red">
                  <thead>
                    <th>Название</th>
                    <th>Тип</th>
                    <th>Примечание</th>
                    <th>Описание</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>request</td>
                      <td>array|object</td>
                      <td></td>
                      <td>Тело запроса</td>
                    </tr>
                    <tr>
                      <td>clients</td>
                      <td>array|object</td>
                      <td></td>
                      <td>Массив с данными о клиенте (Его телефон, почта, ФИО)</td>
                    </tr>
                    <tr>
                      <td>clients.name</td>
                      <td>string</td>
                      <td>Обязательное поле</td>
                      <td>ФИО клиента</td>
                    </tr>
                    <tr>
                      <td>clients.phone</td>
                      <td>string</td>
                      <td>Обязательное поле</td>
                      <td>Номер телефона в формате 79999999999</td>
                    </tr>
                    <tr>
                      <td>clients.email</td>
                      <td>string</td>
                      <td></td>
                      <td>Почта клиента, если есть</td>
                    </tr>
                    <tr>
                      <td>clients.region</td>
                      <td>integer</td>
                      <td></td>
                      <td>Регион клиента <span class="doc-link" @click="openModal('regions-list')">(список регионов и их id)</span></td>
                    </tr>
                    <tr>
                      <td>product</td>
                      <td>array|object</td>
                      <td></td>
                      <td>Массив содержащий информацию о продукте</td>
                    </tr>
                    <tr>
                      <td>product.id</td>
                      <td>integer</td>
                      <td></td>
                      <td>Идентификатор продукта <span class="doc-link" @click="openModal('products-list')">(список продуктов и их id)</span></td>
                    </tr>
                    <tr>
                      <td>amount</td>
                      <td>integer</td>
                      <td></td>
                      <td>Требуемая сумма</td>
                    </tr>
                    <tr>
                      <td>comment</td>
                      <td>string</td>
                      <td></td>
                      <td>Комментарий к заявке</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="section__body">
              <div class="section__subtitle">Отправка запроса</div>
              <div class="section__content">
                <pre class="language-js"><code>
URL: https://api.sfgroup.ru/order/create

</code></pre>

                <pre class="language-js"><code>
POST: https://api.sfgroup.ru/order/create HTTP/1.1

Host: api.sfgroup.ru
User-Agent: Mozilla/5.0 (X11; Linux x86_64; rv:42.0) Gecko/20100101 Firefox/42.0
Accept: application/json
Accept-Language: en-US,en;q=0.5
Accept-Encoding: gzip, deflate
Content-Type: application/json;
x-sfg-authentication: YOUR_PRIVATE_KEY 
</code></pre>

                <pre class="language-js"><code>
{
  "request": {
    "client": {
      "name": "test",
      "region": "3",
      "phone": "79999999999",
      "email": "info@example.com"
    },
    "amount": 10000,
    "product": {
      "id": 1234
    }
  },
  "comment": "Здесь какой-то комментарий. "
}
</code></pre>
              </div>
            </div>
            <div class="section__body">
              <div class="section__subtitle">Ответ</div>
              <div class="section__content">
                <pre class="language-js"><code>
{
  "status": 200,
  "id": 6326
}
</code></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <ModalOverlay/>
    <Products v-show="this.$store.state.openedModal=='products-list'"/>
    <Regions v-show="this.$store.state.openedModal=='regions-list'"/>
  </div>
</template>

<script>
import LeftMenu from "../Components/LeftMenu";
import PageTitle from "../Components/PageTitle";
import MobileHeader from "../Components/MobileHeader";
import ModalOverlay from "../Components/ModalOverlay";
import Regions from "./Modals/Regions";
import Products from "./Modals/Products";

export default {
  name: "Documentation",
  data() {
    return {
      rightNavIsOpened: false
    };
  },
  components: {
    LeftMenu,
    PageTitle,
    MobileHeader,
    ModalOverlay,
    Regions,
    Products
  },
  methods: {
    openModal(data) {
      this.$store.commit('openModal', data);
    }
  }
};
</script>

<style lang="scss">

.doc-link {
  color: #419ef9;
  text-decoration: underline;
  cursor: pointer;
}
table {
  background-color: #fff;
  border-collapse: collapse;
  overflow: auto;
  width: 100%;
  border: 1px solid #2472a4;

  thead {
    background-color: #2980b9;
    color: #fff;
  }

  tbody {
    tr:nth-child(2n) {
      background-color: #e9e9e9;
    }

    tr:nth-child(2n + 1) {
      background-color: #f6f6f6;
    }
  }

  th {
    padding: 10px;
    border-bottom: none;
    font-weight: 400;
    position: relative;

    &:not(:last-child) {
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        background-color: #2472a4;
        right: 0;
        top: 0;
      }
    }
  }

  td {
    padding: 10px;
    position: relative;

    &:not(:last-child) {
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        background-color: #e4e4e4;
        right: 0;
        top: 0;
      }
    }
  }
}
table.red {
  td:nth-child(3) {
    color: red;
  }
}
.section {
  &__title {
    margin-bottom: 20px;
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: 600;
  }

  &__subtitle {
    margin-bottom: 10px;
    font-size: 1em;
    letter-spacing: 1px;
    font-weight: 600;
  }

  &__body {
    margin-bottom: 50px;
  }

  &__content {
    line-height: 1.5;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .img {
    width: 100%;
    max-width: 300px;
    float: left;
    border: 1px solid #e4e4e4;
    margin-right: 20px;
  }
}

.documentation .content {
  padding-right: 240px;

  .section__content {
    font-weight: 400;
    display: block;
    overflow: auto;
  }
}

.open-right-nav {
  display: none;
}

.right-nav {
  position: fixed;
  z-index: 5;
  right: 20px;
  top: 90px;
  width: 200px;
  font-size: 14px;
  border-left: 3px solid #e4485d;

  ul {
    padding: 10px 15px;

    li {
      color: #419ef9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.section {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .documentation,
  .help {
    .content {
      padding-right: 20px;
    }

    .open-right-nav {
      position: absolute;
      right: 100%;
      top: 15px;
      display: block;
      padding: 10px 2px 10px 5px;
      background-color: #e4485d;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      fill: #fff;

      .icon {
        display: block;
        width: 20px;
        height: 20px;
        transition: transform 0.3s;
        transform: rotate(-90deg);

        &.opened {
          transform: rotate(90deg);
        }
      }
    }

    .right-nav {
      transition: right 0.3s;
      top: 70px;
      right: -197px;
      background-color: #f5f5f5;
      height: calc(100vh - 70px);

      &.opened {
        right: 0;
      }
    }
  }
}

@media (max-width: 500px) {
  .section {
    .img {
      display: none;
    }
  }
}
</style>
