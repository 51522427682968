<template>
    <div class="modal-small" @click.stop> 
      <div class="modal-small__header">
        <h3 class="modal-small__title">Удалить запрос</h3>
      </div>
      <div class="modal-small__body">
        <div class="form__row">
          <span>Вы точно хотите удалить данный запрос? Действие нельзя будет отменить.</span>
        </div>
        <div class="form__row form__row--btns">
          <button class="btn btn__success" @click.prevent="deleteRequest()">Да</button>
          <button class="btn btn__danger" @click.prevent="close">Нет</button>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'DeleteRequestModal',
  methods: {
    close() {
      this.$store.commit('closeModal');
      this.$store.commit('setCurRequestId', null);
    },
    deleteRequest() {
      console.log(this.$store.state.curRequestId);
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.access_token
        }
      });

      instance
        .post("api/post/admin/request/delete", { id: this.$store.state.curRequestId })
        .then(response => {
          this.$store.commit("createMessage", {
            type: "success",
            text: "Запрос успешно удален"
          });
          this.$store.commit('closeModal');
          this.$store.commit('deleteFromRequests');
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 422) {
            alert("Некорректные данные");
          }
          if (error.response.state === 500) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
          }
        });
    }
  }
}
</script>
