<template>
  <div class="delete-webhook modal add-news">
    <div class="add-news__header">
      <div @click="close" class="close-button">
       <span></span>
      </div>
      <label for="titleNews" class="add-news__label">
        Заголовок:
        <input id="titleNews" class="add-news__input" v-model="title">
      </label>
    </div>
    <div class="add-news__content">
      <div class="add-news__description">
        <vue-editor v-model="text" :editorOptions="options"></vue-editor>
      </div>
      <div class="add-news__btns">
        <span @click="addNews" class="edit-btn btn btn__success">Добавить</span>
        <span @click="close" class="edit-btn btn btn__danger">Отменить</span>
     </div>
    </div>

    <InfoMessage :message="this.$store.state.message.text" :type="this.$store.state.message.type"/>
  </div>
</template>

<script>
  import { VueEditor, Quill } from 'vue2-editor';
  import axios                from 'axios';
  import moment               from 'moment';
  import InfoMessage          from '../Components/InfoMessage';
  import 'moment/locale/ru';

  moment.locale('ru');

  export default {
    name: "AddNews",
    components: { 
      VueEditor,
      InfoMessage
    },
    data() {
      return {
        text: '',
        title: '',
        options: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'align': [] }],
              ['blockquote', 'code-block'],
              ['clean'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],              
              ['link', 'image', 'video'],
            ]
          }
        }
      };
    },
    methods: {
      addNews() {
        if (this.title.length >= 8 && this.title.length <= 255) { 
          const instance = axios.create({
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
              "Authorization": "Bearer " + this.$store.state.access_token
            }
          });

          let sendObj = {
            title: this.title,
            body: this.text
          };

          instance.post('/api/post/admin/news/create', sendObj)
                  .then(response => {
                    let data = {
                      id: response.data.id,
                      title: sendObj.title,
                      text: sendObj.body,
                      created_at: moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')
                    };
                    this.$store.commit('addNewNews', data);
                    this.$store.commit('closeModal');
                  })
                  .catch(error => {
                    if (error.response.status === 401) {
                      this.$store.commit('forceLogout');
                    }
                    if(error.response.status === 422) {
                      alert('422 AddNews');
                    }
                    if(error.response.status === 500) {
                      alert('500 AddNews');
                    }
                  });
        } else {
          this.$store.commit('createMessage', {
            text: 'Длина названия новости может быть только от 8 до 255 символов',
            type: 'danger'
          });
        }
      },
      close() {
        this.$store.commit('closeModal');
      }
    }
  };
</script>

<style lang="scss">

</style>
