var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-big"},[_vm._m(0),_c('div',{staticClass:"modal-big__body"},[_c('form',{staticClass:"modal-big__form form"},[_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"companyName"}},[_vm._v("Название:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.companyName.$model),expression:"$v.companyName.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
                          errorInput: _vm.$v.companyName.$anyError, 
                          validInput: !_vm.$v.companyName.$anyError && _vm.$v.companyName.$dirty && _vm.$v.companyName.$model
                      },attrs:{"type":"text","placeholder":"Название Компании","id":"companyName"},domProps:{"value":(_vm.$v.companyName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.companyName, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"companyAddress"}},[_vm._v("Адрес:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.companyAddress.$model),expression:"$v.companyAddress.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
                          errorInput: _vm.$v.companyAddress.$anyError, 
                          validInput: !_vm.$v.companyAddress.$anyError && _vm.$v.companyAddress.$dirty && _vm.$v.companyAddress.$model
                      },attrs:{"type":"text","placeholder":"г. Новосибирск, ул. Каменская, д. 51","id":"companyAddress"},domProps:{"value":(_vm.$v.companyAddress.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.companyAddress, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"companyEmail"}},[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.companyEmail.$model),expression:"$v.companyEmail.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
                          errorInput: _vm.$v.companyEmail.$anyError, 
                          validInput: !_vm.$v.companyEmail.$anyError && _vm.$v.companyEmail.$dirty && _vm.$v.companyEmail.$model
                      },attrs:{"type":"text","placeholder":"mail@mail.com","id":"companyEmail"},domProps:{"value":(_vm.$v.companyEmail.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.companyEmail, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.companyEmail.email && _vm.$v.companyEmail.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Некорректный формат email")]):_vm._e()]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"companyPhone"}},[_vm._v("Телефон:")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ### ## ##'),expression:"'+7 (###) ### ## ##'"},{name:"model",rawName:"v-model.trim",value:(_vm.$v.companyPhone.$model),expression:"$v.companyPhone.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
                          errorInput: _vm.$v.companyPhone.$anyError, 
                          validInput: !_vm.$v.companyPhone.$anyError && _vm.$v.companyPhone.$dirty && _vm.$v.companyPhone.$model
                      },attrs:{"type":"text","placeholder":"+7 (000) 000 00 00","id":"companyPhone"},domProps:{"value":(_vm.$v.companyPhone.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.companyPhone, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.companyPhone.phoneNumber && _vm.$v.companyPhone.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Введите нормер телефона")]):_vm._e()]),_c('div',{staticClass:"form__row"},[_c('label',{staticClass:"form__label",attrs:{"for":"companyAbout"}},[_vm._v("О компании:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.companyAbout.$model),expression:"$v.companyAbout.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
                          errorInput: _vm.$v.companyAbout.$anyError, 
                          validInput: !_vm.$v.companyAbout.$anyError && _vm.$v.companyAbout.$dirty && _vm.$v.companyAbout.$model
                      },attrs:{"name":"aboutCompany","id":"companyAbout"},domProps:{"value":(_vm.$v.companyAbout.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.companyAbout, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.companyAbout.maxLength && _vm.$v.companyAbout.$dirty)?_c('div',{staticClass:"error"},[_vm._v("Не более "+_vm._s(_vm.$v.companyAbout.$params.maxLength.max)+" символов")]):_vm._e()]),_c('div',{staticClass:"form__row form__row--btns"},[_c('button',{staticClass:"btn btn__success",on:{"click":function($event){$event.preventDefault();return _vm.changeSettings($event)}}},[_vm._v("Сохранить")]),_c('button',{staticClass:"btn btn__danger",on:{"click":function($event){$event.preventDefault();return _vm.close($event)}}},[_vm._v("Отменить")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-big__header"},[_c('h3',{staticClass:"modal-big__title"},[_vm._v("Редактирование компании")])])}]

export { render, staticRenderFns }