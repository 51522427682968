<template>
  <v-infinite-scroll
    :loading="loading"
    @top="prevPage"
    @bottom="nextPage"
    :offset="15"
    style="max-height: 100vh; overflow-y: scroll; -webkit-overflow-scrolling: touch;"
  >
    <div class="leads">
      <LeftMenu/>
      <main class="page-container">
        <MobileHeader/>

        <PageTitle :title="'Заявки'" purse notice/>

        <div class="content">
          <div class="orders">
            <div class="order-counter total">
              <h2 class="order-counter__title">Всего:</h2>
              <div class="order-counter__content">
                <span class="order-counter__icon">
                  <i class="fas fa-file-alt"></i>
                </span>
                <span class="order-counter__count">{{this.$store.state.counts.all ? this.$store.state.counts.all : 0}}</span>
              </div>
            </div>

            <div class="order-counter success">
              <h2 class="order-counter__title">Выполнено:</h2>
              <div class="order-counter__content">
                <span class="order-counter__icon">
                  <i class="fas fa-file-alt"></i>
                </span>
                <span class="order-counter__count">{{this.$store.state.counts.success ? this.$store.state.counts.success : 0}}</span>
              </div>
            </div>

            <div class="order-counter fail">
              <h2 class="order-counter__title">Отклонено:</h2>
              <div class="order-counter__content">
                <span class="order-counter__icon">
                  <i class="fas fa-file-alt"></i>
                </span>
                <span class="order-counter__count">{{this.$store.state.counts.fail ? this.$store.state.counts.fail : 0}}</span>
              </div>
            </div>
          </div>

          <div class="analytics-block">
            <OrderChart/>
          </div>

          <div class="orders-table">
            <div class="orders-table__top">
              <div class="left-block" >
                <p class="title-text">
                  Заявки
                  <span class="counter-orders">{{this.$store.state.counts.all ? this.$store.state.counts.all : 0}}</span>
                </p>
                <form class="form-search">
                  <div class="search">
                    <input v-model="searchParam" class="search__input" type="search" :disabled="this.$store.state.userStage === 0" :class="{disabled: !this.$store.state.userStage === 0}">
                    <span class="search__btn" :class="{disabled: this.$store.state.userStage === 0}">
                      <svg
                        version="1.1"
                        id="Слой_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        style="enable-background:new 0 0 100 100;"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            d="M7,98.9L1.1,93c-1.4-1.4-1.4-3.8,0-5.2L34,55.1c1.4-1.4,3.8-1.4,5.2,0L45,61c1.4,1.4,1.4,3.8,0,5.2L12.1,98.9
                                                    C10.7,100.4,8.4,100.4,7,98.9z"
                          ></path>
                          <path
                            d="M66.7,0C48.3,0,33.3,14.9,33.3,33.3s14.9,33.3,33.3,33.3S100,51.7,100,33.3S85.1,0,66.7,0z M66.7,56.5
                                                    c-12.8,0-23.1-10.4-23.1-23.1c0-12.8,10.4-23.1,23.1-23.1c12.8,0,23.1,10.4,23.1,23.1S79.4,56.5,66.7,56.5z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                </form>
              </div>

              <div class="right-block">
                <button class="btn" @click="openModal('filter-order')" :disabled="this.$store.state.userStage === 0">Фильтр</button>
                <button class="btn" @click="openModal('add-order')" :disabled="this.$store.state.userStage === 0" v-if="!this.$store.state.isAdmin">Создать</button>
              </div>
            </div>
            <div class="order-table__tabs" v-if="this.$store.state.isAdmin">
              <span class="order-tab" :class="{active : activeTab}" @click="getIncomingOrders">Входящие</span>
              <span class="order-tab" :class="{active : !activeTab}" @click="getOutcomingOrders">Исходящие</span>
            </div>

            <div class="orders-table__table table" v-if="this.$store.state.orders.length && !this.$store.state.isAdmin">
             <div class="table__row table__row--header">
                <div class="table__cell table__cell--header">Дата</div>
                <div class="table__cell table__cell--header">Статус</div>
                <div class="table__cell table__cell--header">ФИО</div>
                <div class="table__cell table__cell--header">Телефон</div>
                <div class="table__cell table__cell--header">E-mail</div>
              </div>
              <div
                v-for="order in this.$store.state.orders"
                class="table__row"
                :key="order.id"
                @click="getOrderInfo(order.id)"
              >
                <div class="table__cell">{{dateFormat(order.created_at)}}</div>
                <div class="table__cell">
                  <span
                    class="status-badge"
                    :style="'background-color:' + order.stage.color + ';' "
                  >{{order.stage.name}}</span>
                </div>
                <div class="table__cell">{{order.name}}</div>
                <div class="table__cell">{{phoneFormat(order.phone)}}</div>
                <div class="table__cell">{{order.email || 'Не указан'}}</div>
              </div>
              

              
            </div>

            <div class="orders-table__table table" v-else-if="this.$store.state.orders.length && this.$store.state.isAdmin">
             <div class="table__row table__row--header">
                <div class="table__cell table__cell--header">Дата</div>
                <div class="table__cell table__cell--header">Партнер</div>
                <div class="table__cell table__cell--header">Статус</div>
                <div class="table__cell table__cell--header">ФИО</div>
                <div class="table__cell table__cell--header">Телефон</div>
                <div class="table__cell table__cell--header">E-mail</div>
              </div>
              <div
                v-for="order in this.$store.state.orders"
                class="table__row"
                :key="order.id"
                @click="getOrderInfo(order.id)"
              >
                <div class="table__cell">{{dateFormat(order.created_at)}}</div>
                <div class="table__cell">{{getPartnerName(order.user_id)}}</div>
                <div class="table__cell">
                  <span
                    class="status-badge"
                    :style="'background-color:' + order.stage.color + ';' "
                  >{{order.stage.name}}</span>
                </div>
                <div class="table__cell">{{order.name}}</div>
                <div class="table__cell">{{phoneFormat(order.phone)}}</div>
                <div class="table__cell">{{order.email || 'Не указан'}}</div>
              </div>
              

              
            </div>

            <div class="orders-table__table table table__empty" v-else>
              <div class="table__inner">
                  <div class="table__row table__row--header">
                    <div class="table__cell table__cell--header">Дата</div>
                    <div class="table__cell table__cell--header">Статус</div>
                    <div class="table__cell table__cell--header">ФИО</div>
                    <div class="table__cell table__cell--header">Телефон</div>
                    <div class="table__cell table__cell--header">E-mail</div>
                  </div>
                  <div class="table__row">
                    <div class="table__cell">01 января 2019 00:00</div>
                    <div class="table__cell">
                      <span class="status-badge in-progress">В работе</span>
                    </div>
                    <div class="table__cell">Имя Фамилия Отчество</div>
                    <div class="table__cell">+7 (000) 000 00 00</div>
                    <div class="table__cell">email@email.com</div>
                  </div>
                  <div class="table__row">
                    <div class="table__cell">01 января 2019 00:00</div>
                    <div class="table__cell">
                      <span class="status-badge in-progress">В работе</span>
                    </div>
                    <div class="table__cell">Имя Фамилия Отчество</div>
                    <div class="table__cell">+7 (000) 000 00 00</div>
                    <div class="table__cell">email@email.com</div>
                  </div>
                  <div class="table__row">
                    <div class="table__cell">01 января 2019 00:00</div>
                    <div class="table__cell">
                      <span class="status-badge in-progress">В работе</span>
                    </div>
                    <div class="table__cell">Имя Фамилия Отчество</div>
                    <div class="table__cell">+7 (000) 000 00 00</div>
                    <div class="table__cell">email@email.com</div>
                  </div>
                  <div class="table__row">
                    <div class="table__cell">01 января 2019 00:00</div>
                    <div class="table__cell">
                      <span class="status-badge in-progress">В работе</span>
                    </div>
                    <div class="table__cell">Имя Фамилия Отчество</div>
                    <div class="table__cell">+7 (000) 000 00 00</div>
                    <div class="table__cell">email@email.com</div>
                  </div>
                  <div class="table__row">
                    <div class="table__cell">01 января 2019 00:00</div>
                    <div class="table__cell">
                      <span class="status-badge in-progress">В работе</span>
                    </div>
                    <div class="table__cell">Имя Фамилия Отчество</div>
                    <div class="table__cell">+7 (000) 000 00 00</div>
                    <div class="table__cell">email@email.com</div>
                  </div>
                  <div class="table__row">
                    <div class="table__cell">01 января 2019 00:00</div>
                    <div class="table__cell">
                      <span class="status-badge in-progress">В работе</span>
                    </div>
                    <div class="table__cell">Имя Фамилия Отчество</div>
                    <div class="table__cell">+7 (000) 000 00 00</div>
                    <div class="table__cell">email@email.com</div>
                  </div>
                </div>

                <div class="blur">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Слой_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="-549 451 100 100"
                      style="enable-background:new -549 451 100 100;"
                      xml:space="preserve"
                    >
                      <g id="XMLID_160_">
                        <g id="XMLID_161_">
                          <path
                            id="XMLID_158_"
                            d="M-499,531.8c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                    v-17.1c0,0-0.1,0-0.1,0.1C-463.2,525.2-479.3,531.8-499,531.8z"
                          ></path>
                          <path
                            id="XMLID_157_"
                            d="M-499,510.2c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                    V495c0,0-0.1,0-0.1,0.1C-463.2,503.6-479.3,510.2-499,510.2z"
                          ></path>
                          <path
                            id="XMLID_156_"
                            d="M-499,488.5c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                    v-17.1c0,0-0.1,0-0.1,0.1C-463.2,481.8-479.3,488.5-499,488.5z"
                          ></path>
                          <path
                            id="XMLID_155_"
                            d="M-499,484.7c20.7,0,37.4-7.4,37.4-16.4c0-9.1-16.7-16.4-37.4-16.4c-20.7,0-37.4,7.4-37.4,16.4
                                                    C-536.4,477.3-519.6,484.7-499,484.7z M-499,463.8c5.6,0,10.2,2,10.2,4.4c0,2.5-4.5,4.4-10.2,4.4s-10.2-2-10.2-4.4
                                                    C-509.2,465.7-504.6,463.8-499,463.8z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="text">Недостаточно данных</div>
                </div>
            </div>

            <div class="table__preloader" v-if="this.preloaderIsVisible">
               <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

          </div>
        </div>

        <ModalOverlay/>

        <AddOrder v-if="this.$store.state.openedModal=='add-order'"/>
        <OrderInfo
          v-if="this.$store.state.openedModal=='order-info'"
          :id="this.currentOrder.id"
          :email="this.currentOrder.email"
          :name="this.currentOrder.name"
          :created_at="this.currentOrder.created_at"
          :amount="this.currentOrder.amount"
          :phone="this.currentOrder.phone"
          :product_id="this.currentOrder.product"
          :stage="this.currentOrder.stage"
          :closed_at="this.currentOrder.closed_at"
          :closure_comment="this.currentOrder.closure_comment"
          :closure="this.currentOrder.closure"
          :department="this.currentOrder.department"
          :files="this.currentOrder.file"
          :type_order="this.currentOrder.type_order"
          :partner="getPartnerName(this.currentOrder.user_id)"
        />
        <FilterOrder v-show="this.$store.state.openedModal=='filter-order'"/>
      </main>

      <InfoMessage  :message="this.$store.state.message.text" :type="this.$store.state.message.type">
    </div>
    
    <Preloader />
  </v-infinite-scroll>

  
</template>

<script>
import AddOrder       from "./Components/AddOrder";
import OrderInfo      from "./Components/OrderInfo";
import FilterOrder    from "./Components/FilterOrder";
import LeftMenu       from "../Components/LeftMenu";
import PageTitle      from "../Components/PageTitle";
import ModalOverlay   from "../Components/ModalOverlay";
import MobileHeader   from "../Components/MobileHeader";
import InfoMessage    from "../Components/InfoMessage";
import OrderChart     from "./Components/Chart";
import axios          from "axios";
import Vue            from "vue";
import InfiniteScroll from "v-infinite-scroll";
import _              from 'lodash';
import "v-infinite-scroll/dist/v-infinite-scroll.css";
import Preloader from "../Components/Preloader";
import moment               from 'moment';
moment.locale('ru');

Vue.use(InfiniteScroll);

export default {
  name: "Leads",
  data() {
    return {
      currentOrder: null,
      page: 1,
      loading: false,
      searchParam: '',
      activeTab: true,
      preloaderIsVisible: false
    };
  },
  components: {
    LeftMenu,
    PageTitle,
    AddOrder,
    OrderInfo,
    FilterOrder,
    MobileHeader,
    OrderChart,
    ModalOverlay,
    InfoMessage,
    Preloader
  },
  methods: {
    openModal: function(modalName) {
      this.$store.commit("openModal", modalName);
    },
    getOrderInfo(orderId) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });
      
      this.$store.commit('showPreloader');

      instance.get("/api/get/orders?id=" + orderId).then(response => {
        this.currentOrder = null;
        this.currentOrder = response.data;
        console.log(response.data);
        this.$store.commit('hidePreloader');
        this.curName = this.getPartnerName(this.currentOrder.user_id);
        console.log(this.curName);
        this.openModal("order-info");
        
      });

    },
    getPartnerName(id) {
      let userName = "Пользователь удален";

      this.$store.state.filters.users.forEach(user => {
        
        if(user.id === id) {
          userName = (user.name || user.user_info.name || user.email );
        }
      });

      return userName;
    },
    prevPage() {
      if (this.page == 1) {
        return;
      }
      --this.page;
    },
    nextPage() {
      ++this.page;
      this.api();
    },
    api() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.access_token
        }
      });

      this.loading = true;
      this.preloaderIsVisible = true;
    
      let filter = '';
     if(this.$store.state.isSend) {
      this.$store.commit('addFilter', {isSend: true});
     }
      console.log(this.$store.state.ordersFilter);
      if(this.$store.state.ordersFilter) {
        filter += this.$store.state.ordersFilter;
      }
      if(this.$store.state.searchParam) {
        filter += this.$store.state.searchParam;
      }
      // if(this.$store.state.isSend) {
      //   filter += "&type=send"
      // }

    //  if (this.$store.state.orders.length) {
        instance.get(`/api/get/orders?offset=${this.$store.state.offset}&${filter}`)
              .then(response => {
                this.$store.commit("addNewOrders", response.data.orders);
                this.loading = false;
              })
    //  }
      this.preloaderIsVisible = false;
              
    },
    search(param) {
      this.$store.commit('search', param);
    },
    searchAfterDebounce: _.debounce(
      function () {
        this.search(this.searchParam)
      }, 500
    ),
    phoneFormat(phone) {
      return ('+' + phone.substr(0, 1) + ' ' + '('+ phone.substr(1, 3) +')' + ' ' + phone.substr(4, 3) + ' ' + phone.substr(7, 2) + ' ' + phone.substr(9));
    },
    dateFormat(date) {
       let stillUtc = moment.utc(date).toDate();
        let local = moment(stillUtc).local().format('DD MMMM YYYY HH:mm:ss');       
        return local;
    },
    changeTab() {
      this.activeTab = !this.activeTab;
      this.searchParam = '';
      this.$store.commit('resetFilters');
      this.$store.commit('resetOffset');
    },
    // Исходящие заявки
    getOutcomingOrders() {
      this.changeTab();

       const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });

      this.$store.commit('showPreloader');
      this.$store.state.isSend = true;

      this.$store.commit('setSendOrders');


      instance.get("api/get/orders?type=send").then(response => {
        this.$store.commit('hidePreloader');
        this.$store.commit('updateOrders', response.data);
      }).catch(error => {
         if (error.response.status === 401) {
          this.$store.commit("forceLogout");
        }
        if (error.response.status === 422) {
          this.$store.commit("createMessage", {
            type: "danger",
            text: "Некорректные данные"
          });
        }
        if (error.response.status === 500) {
          this.$store.commit("createMessage", {
            type: "danger",
            text: this.$store.state.messages.serverError
          });
        }
      });
    },
    // Входящие заявки
    getIncomingOrders() {
      this.changeTab();
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });

      this.$store.commit('resetOffset');
      this.$store.state.isSend = false;
      this.$store.commit('showPreloader');
      this.$store.commit('setSendOrders', "");

      instance.get("api/get/orders").then(response => {
        this.$store.commit('hidePreloader');
        this.$store.commit('updateOrders', response.data);
      }).catch(error => {
         if (error.response.status === 401) {
          this.$store.commit("forceLogout");
        }
        if (error.response.status === 422) {
          this.$store.commit("createMessage", {
            type: "danger",
            text: "Некорректные данные"
          });
        }
        if (error.response.status === 500) {
          this.$store.commit("createMessage", {
            type: "danger",
            text: this.$store.state.messages.serverError
          });
        }
      });
    }
  },
  watch: {
    searchParam(val) {
      this.searchAfterDebounce();
    }
  },
};
</script>

<style lang="scss">

.order-table__tabs {
  margin-bottom: 10px;
  border-bottom: 1px solid #ced4da;

  .order-tab { 
    padding: 7px 20px;
    border: 1px solid #ced4da;
    border-bottom: 0;
    display: inline-block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    color: #888;
    background-color: #ddd;
    font-size: 15px;

    

    &.active {
      background-color: transparent;
      color: inherit;


      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background-color: #f5f5f5;
        position: absolute;
        top: 100%; 
        left: 0;
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:not(.active) {
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: lighten($color: #ddd, $amount: 5%);
        color: darken($color: #888, $amount: 5%);
      }
    }
  }

}



.page-container {
  padding-left: 280px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.content {
  padding: 20px;
}

@media (max-width: 1439px) {
  .page-container {
    padding-left: 70px;
  }
}

@media (max-width: 768px) {
  .page-container {
    padding-left: 0;
    padding-top: 70px;
  }

  .sidebar {
    left: -100%;
  }

  .analytics-block {
    display: none;
  }
}

.btn__icon {
  display: inline-block;
  width: 15px;
  position: relative;
  margin-right: 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.orders {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order-counter {
  width: 32%;
  border-radius: 5px;
  color: #fff;
  padding: 10px;

  &__title {
    margin-bottom: 20px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__icon {
    font-size: 30px;
  }

  &__count {
    font-size: 30px;
  }

  &.total {
    background-color: #2884d0;
  }
  &.success {
    background-color: #4caf50;
  }
  &.fail {
    background-color: #d02828;
  }
}

@media (max-width: 768px) {
  .orders {
    flex-direction: column;
  }

  .order-counter {
    width: 100%;
    margin-bottom: 10px;

    &__title {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 768px) {
  .orders-table {
    &__top {
      flex-direction: column;
    }

    .left-block {
      align-items: initial;
      flex-direction: column;

      .title-text,
      .form-search {
        margin-bottom: 10px;
      }
    }
  }
}

.orders-table {
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    
  }

  .table__cell {
      width: calc(100% / 5);
      max-width: calc(100% / 5);
      min-width: 180px;
      overflow: hidden;
    }

  .status-badge {
    display: block;
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 22px);
  }
}


.blur {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.5);
  color: #5c5c5c;
  fill: #5c5c5c;
  border: 1px solid #ddd;

  .icon {
    width: 100px;
    margin-bottom: 10px;
  }
}

.left-block {
  display: flex;
  align-items: center;

  .title-text {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0;
    margin-right: 1em;
  }

  .counter-orders {
    color: #a6a19e;
    margin-left: 8px;
  }
}

.active {
  display: block;
}

.analytics-block {
  margin-bottom: 20px;
}

.search {
  display: flex;

  &__input {
    border: none;
    outline: none;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-right: none;
    border-radius: 0;
    -webkit-appearance: none;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    transition: border-color 0.3s;
    height: 35px;
    &[disabled] {
      background-color: #eee;
    }


    &:focus {
      border-color: #9acffa;

      & + .search {
        &__btn {
          border-color: #9acffa;

          svg {
            fill: #9acffa;
          }
        }
      }
    }

    &.disabled {
      background-color: #eee;
    }

  }

  &__btn {
    position: relative;
    display: block;
    width: 30px;
    border: 1px solid #ced4da;
    border-left: none;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background-color: #fff;
    transition: border-color 0.3s;

    &.disabled {
      background-color: #eee;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      fill: #ced4da;
      transition: fill 0.3s;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 768px) {
  .orders-table {
    .right-block {
      justify-content: flex-start;
    }
  }
}

// Прелоадер: живой поиск
.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid #9acffa;
  border-color: #9acffa transparent #9acffa transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


// Прелоадер: подгрузка заказов
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #666;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}


</style>
