<template>
  <div class="modal-small__overlay" @click="close">
    <div class="modal-small" @click.stop> 
      <div class="modal-small__header">
        <h3 class="modal-small__title">Удалить внешний запрос</h3>
      </div>
      <div class="modal-small__body">
        <div class="form__row">
          <span>Вы точно хотите удалить данный запрос? Действие нельзя будет отменить.</span>
        </div>
        <div class="form__row form__row--btns">
          <button class="btn btn__success" @click.prevent="deleteRequest()">Да</button>
          <button class="btn btn__danger" @click.prevent="close">Нет</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DeletePartnerRequest",
  props: {
    id: Number
  },
  methods: {
    close() {
      this.$emit('closeAcceptModal');
    },
    deleteRequest() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });
      console.log(this.id);
      console.log(this.$parent.data);

      instance
        .post("api/post/admin/request/delete", { id: this.id })
        .then(response => {
          this.$store.commit("createMessage", {
            type: "success",
            text: "Внешний запрос удален"
          });
          this.updateData();
          this.$parent.curRequest = null;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 422) {
            alert("Некорректные данные");
          }
          if (error.response.state === 500) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
          }
        });
    },
    updateData() {
      this.$emit("updateData", this.id);
    }
  }
};
</script>

<style lang="scss">
.modal-small__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(#000, 0.3);
}
</style>
