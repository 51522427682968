<template>
  <div class="news-card">
    <div class="news-card__header">
      <div class="news-card__title" :class="{editBtns: this.$store.state.isAdmin}">{{ newsData.title }}</div>
      <div class="news-card__options" v-if="this.$store.state.isAdmin">
        <span @click="edit" class="edit-btn">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 383.947 383.947"
            style="enable-background:new 0 0 383.947 383.947;"
            xml:space="preserve"
          >
            <g>
              <g>
                <g>
                  <polygon
                    points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893 			"
                  ></polygon>
                  <path
                    d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04
				            C386.027,77.92,386.027,64.373,377.707,56.053z"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span @click="del" class="delete-btn">
          <svg
            version="1.1"
            id="Слой_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-549 451 100 100"
            style="enable-background:new -549 451 100 100;"
            xml:space="preserve"
          >
            <path
              d="M-481.2,501l26.4-26.3c4.9-4.9,4.9-12.9,0-17.8s-12.9-4.9-17.8,0l-26.4,26.3l-26.3-26.4c-4.9-4.9-12.9-4.9-17.8,0l0,0
                c-4.9,4.9-4.9,12.9,0,17.8l26.3,26.4l-26.4,26.3c-4.9,4.9-4.9,12.9,0,17.8s12.9,4.9,17.8,0l26.4-26.3l26.3,26.4
                c4.9,4.9,12.9,4.9,17.8,0l0,0c4.9-4.9,4.9-12.9,0-17.8L-481.2,501z"
            ></path>
          </svg>
        </span>
      </div>
      <div class="news-card__date">{{ date }}</div>
    </div>
    <div class="news-card__preview">{{createNewsDesc(newsData.text)}}</div>
    <div class="news-card__actions">
      <span @click="handle" class="btn btn--more">Подробнее</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");

export default {
  name: "NewsCard",
  props: {
    newsData: Object
  },
  computed: {
    date() {
      let stillUtc = moment.utc(this.newsData.created_at).toDate();
      let local = moment(stillUtc)
        .local()
        .format("DD MMMM YYYY HH:mm:ss");
      return local;
    }
  },
  methods: {
    handle() {
      this.$store.commit("setCurNews", this.newsData);
      this.$store.commit("openModal", "curNews");
    },
    edit() {
      this.$store.commit("setCurNews", this.newsData);
      this.$store.commit("openModal", "EditNews");
    },
    del() {
      this.$store.commit("setCurNews", this.newsData);
      this.$store.commit("openModal", "DeleteNews");
    },
    createNewsDesc(text) {
      let prev = text;
      let reg = /(<([^>]+)>)/gi;
      prev = prev.replace(reg, "");
      if (prev.length > 200) {
        prev = prev.substr(0, 200) + "...";
      }

      return prev;
    }
  }
};
</script>

<style lang="scss">
.news-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 5px rgba(0,0,0,.2);
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  color: #333;

  &__header {
    width: 100%;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 30px;
    margin-bottom: 5px;

    &.editBtns {
      padding-right: 50px;
    }
  }

  &__options {
    position: absolute;
    top: 20px;
    right: 20px;

    span {
      display: inline-block;
      fill: #777;
      width: 15px;
      cursor: pointer;
      opacity: 0.8;
      transition: 0.2s;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__date {
    // margin: 10px 0;
    font-size: 12px;
    color: #bbb;
  }

  &__description {
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__preview {
    margin-bottom: 20px;
    line-height: 1.5;
  }

  
}

@media (max-width: 768px) {
  .news-card {
    &__title {
      font-size: 20px;
    }
  }
}
</style>
