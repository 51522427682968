<template>
  <div class="request">
    <div class="request__header" @click="isOpened = !isOpened">
      <div class="request__title">{{request.name ? request.name : 'Название запроса' }}</div>

      <div class="request__controls" @click.stop>
        <span class="delete" @click="setCurRequest(request.id)" title="Удалить запрос">
          <svg
            version="1.1"
            id="Слой_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-549 451 100 100"
            style="enable-background:new -549 451 100 100;"
            xml:space="preserve"
          >
            <path
              d="M-481.2,501l26.4-26.3c4.9-4.9,4.9-12.9,0-17.8s-12.9-4.9-17.8,0l-26.4,26.3l-26.3-26.4c-4.9-4.9-12.9-4.9-17.8,0l0,0
                c-4.9,4.9-4.9,12.9,0,17.8l26.3,26.4l-26.4,26.3c-4.9,4.9-4.9,12.9,0,17.8s12.9,4.9,17.8,0l26.4-26.3l26.3,26.4
                c4.9,4.9,12.9,4.9,17.8,0l0,0c4.9-4.9,4.9-12.9,0-17.8L-481.2,501z"
            ></path>
          </svg>
        </span>
      </div>

      <span class="arr-down" :class="{closed: !isOpened}">
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 240.811 240.811"
          style="enable-background:new 0 0 240.811 240.811;"
          xml:space="preserve"
        >
          <path
            id="Expand_More"
            d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
          ></path>
        </svg>
      </span>
    </div>
    <transition name="slide">
      <div class="request__body" v-show="isOpened">
        <div class="request__group" v-if="request.value.auth">
          <div class="request__name">Запрос на авторизацию</div>
          <pre class="language-js">
              <code>
METHOD: {{request.value.auth.method}}
URL: {{request.value.auth.url}}
              </code>
            </pre>
          <pre class="language-js">
              <code>
{{request.value.auth.headers}}
              </code>
            </pre>
          <pre class="language-js">
            <code>
{{request.value.auth.data}}
            </code>
          </pre>
        </div>

        <div class="request__group" v-if="request.value.body">
          <div class="request__name">Основной запрос</div>
          <pre class="language-js">
            <code>
METHOD: {{request.value.body.method}}
URL: {{request.value.body.url}}
            </code>
          </pre>
          <pre
            class="language-js"
            v-if="request.value.body.headers && request.value.body.headers.length"
          >
              <code>
{{request.value.body.headers}}
              </code>
            </pre>
          <pre class="language-js" v-if="request.value.body.data">
            <code>
{{request.value.body.data}}
            </code>
          </pre>
        </div>

        <div class="request__group" v-if="request.departments.length">
          <div class="request__name">Регионы:</div>
          <ol class="request__list">
            <li v-for="(city, index) in request.departments" :key="index">{{city.name}}</li>
          </ol>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PartnerRequest",
  props: {
    request: Object
  },
  data() {
    return {
      isOpened: false
    };
  },
  methods: {
    setCurRequest(id) {
      this.$parent.curRequest = id;
    }
  }
};
</script>
<style lang="scss" scoped>
ol {
  margin: 0;
  padding: 15px ;
  background: #fff;
  border-radius: 5px;

  & > li {
    list-style: initial;
    list-style-type: decimal;
    margin-left: 15px;
  }
}
pre[class*="language-"] {
  background-color: #fff;
  border-radius: 5px;
  padding-top: 0;
  padding-bottom: 0;
}

.request {
  &__controls {
    .delete {
      fill: #fd77a4;
      opacity: 0.6;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>

