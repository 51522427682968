<template>
  <div class="partners">
    <LeftMenu />
    <main class="page-container">
      <MobileHeader />
      <PageTitle :title="'Реквесты'" purse notice createRequest/>
      <div class="content">
        <RequestsContent @getData="getData" :flag="flag"/>
      </div>
      <ModalOverlay />
      <AddRequestModal v-show="this.$store.state.openedModal === 'AddRequestModal'"/>
      <InfoMessage :message="this.$store.state.message.text" :type="this.$store.state.message.type"/>
      <DeleteRequestModal v-show="this.$store.state.openedModal === 'DeleteRequestModal'"/>
      <EditRequestModal v-if="this.$store.state.openedModal === 'EditRequestModal'"/>
      <LogModal v-if="this.$store.state.openedModal === 'LogModal'" />
      <Preloader/>
    </main>
  </div>  
</template>

<script>
  import LeftMenu           from '../Components/LeftMenu.vue';
  import PageTitle          from '../Components/PageTitle.vue';
  import ModalOverlay       from '../Components/ModalOverlay.vue';
  import MobileHeader       from '../Components/MobileHeader.vue';
  import InfoMessage        from '../Components/InfoMessage';
  import RequestsContent    from './RequestsContent.vue';
  import axios              from 'axios';
  import Preloader          from '../Components/Preloader';
  import AddRequestModal    from './Modals/AddRequestModal';
  import DeleteRequestModal from './Modals/DeleteRequestModal';
  import EditRequestModal   from './Modals/EditRequestModal';
  import LogModal           from './Modals/LogModal';

  export default {
    name: 'requests',
    components: {
      LeftMenu,
      EditRequestModal,
      PageTitle,
      MobileHeader,
      ModalOverlay,
      RequestsContent,
      Preloader,
      DeleteRequestModal,
      InfoMessage,
      LogModal,
      AddRequestModal
    }
  }
</script>

<style>

</style>
