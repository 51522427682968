<template>
  <transition name="fade">
    <div class="modal-big">
      <div class="modal-big__header">
        <h3 class="modal-big__title">Добавить заявку</h3>
      </div>

      <div class="modal-big__body">
        <form name="addOrder" class="addOrder form modal-big__form" @submit.prevent="submit">
          <div class="form__row">
            <label class="form__label" for="inputName">ФИО:</label>
            <input
              name="name"
              class="form__input"
              type="text"
              placeholder="Иванов Иван Иванович"
              id="inputName"
              v-model.trim="$v.name.$model"
              :class="{
                errorInput: $v.name.$anyError, 
                validInput: !$v.name.$anyError && $v.name.$dirty
              }"
            />
            <div class="error" v-if="!$v.name.required && $v.name.$dirty">Это обязательное поле</div>
            <div class="error" v-if="!$v.name.letters && $v.name.$dirty">Введите ФИО</div>
          </div>
          <div class="form__row form__row--multi">
            <div class="form__col">
              <label class="form__label" for="inputPhone">Телефон:</label>
              <input
                name="phone"
                v-mask="'+7 (###) ### ## ##'"
                v-model.trim="$v.phone.$model"
                class="form__input"
                type="text"
                placeholder="+7 (000) 000 00 00"
                id="inputPhone"
                :class="{
                  errorInput: $v.phone.$anyError, 
                  validInput: !$v.phone.$anyError && $v.phone.$dirty
                }"
              />
              <div class="error" v-if="!$v.phone.required && $v.phone.$dirty">Это обязательное поле</div>
              <div
                class="error"
                v-if="!$v.phone.phoneNumber && $v.phone.$dirty"
              >Введите номер телефона</div>
            </div>
            <div class="form__col">
              <label class="form__label" for="inputEmail">Email:</label>
              <input
                name="email"
                class="form__input"
                type="text"
                placeholder="mail@mail.com"
                id="inputEmail"
                v-model.trim="$v.email.$model"
                :class="{
                  errorInput: $v.email.$anyError,
                  validInput: !$v.email.$anyError && $v.email.$dirty &&  $v.email.$model
                }"
              />
              <div class="error" v-if="!$v.email.email && $v.email.$dirty">Некорректный формат email</div>
            </div>
          </div>
          <div class="form__row">
            <label class="form__label" for="inputType">Тип заявки:</label>
            <multiselect
              name="type"
              :options="this.$store.state.filters.products"
              label="name"
              :show-labels="false"
              placeholder="ПТС"
              v-model.trim="$v.product.$model"
              :class="{
                errorInput: $v.product.$anyError, 
                validInput: !$v.product.$anyError && $v.product.$dirty
              }"
            >
              <span slot="noResult">Совпадений не найдено</span>
            </multiselect>
            <div
              class="error"
              v-if="!$v.product.required && $v.product.$dirty"
            >Это обязательное поле</div>
          </div>

          <div class="form__row form__row--multi">
            <div class="form__col">
              <label class="form__label" for="inputCity">Город:</label>
              <multiselect
                name="department"
                v-model.trim="$v.region.$model"
                :options="this.$store.state.filters.departments"
                label="name"
                :show-labels="false"
                placeholder="Новосибирск"
                :class="{
                  errorInput: $v.region.$anyError, 
                  validInput: !$v.region.$anyError && $v.region.$dirty
                }"
              >
                <span slot="noResult">Совпадений не найдено</span>
              </multiselect>
              <div
                class="error"
                v-if="!$v.region.required && $v.region.$dirty"
              >Это обязательное поле</div>
            </div>
            <div class="form__col">
              <label class="form__label" for="inputAddress">Адрес:</label>
              <input
                name="address"
                v-model.trim="$v.address.$model"
                class="form__input"
                type="text"
                placeholder="ул. Каменская 51"
                id="inputAddress"
                :class="{
                  errorInput: $v.address.$anyError, 
                  validInput: !$v.address.$anyError && $v.address.$dirty && $v.address.$model
                }"
              />
            </div>
          </div>

          <div class="form__row">
            <label class="form__label" for="inputSumm">Желаемая сумма:</label>
            <input
              name="amount"
              v-model.trim="$v.summ.$model"
              class="form__input"
              type="text"
              placeholder="30 000"
              id="inputSumm"
              :class="{
                errorInput: $v.summ.$anyError, 
                validInput: !$v.summ.$anyError && $v.summ.$dirty && $v.summ.$model
              }"
            />
            <div class="error" v-if="!$v.summ.numeric && $v.summ.$dirty">Введите сумму</div>
          </div>

          <div class="form__row">
            <label class="form__label form__label--file btn" for="inputFile">Загрузить файлы</label>
            <input
              name="files[]"
              class="form__input form__input--file"
              type="file"
              id="inputFile"
              multiple
              @change="uploadFiles"
            />
          </div>

          <div class="form__row form__row--preview" v-if="files.length">
            <div
              class="preview"
              v-for="(file, index) in files"
              :key="index"
              :title="file.name + ' ' + getFileSize(file.size)"
            >
              <div class="preview__delete" @click="deleteFile(index)">
                <span></span>
              </div>
              <div class="preview__img" :style="'background-image: url('+ setIcon(file) + ')'"></div>
              <div class="preview__name">{{file.name}}</div>
            </div>

            <div class="preview__error" v-if="!validateFileSize(this.files)">
              <p class="error">Размер загружаемых файлов не должен превышать 2 MB</p>
            </div>
          </div>

          <div class="form__row form__row--btns">
            <button class="btn btn__success" @click.prevent="submit">Добавить</button>
            <button class="btn btn__danger" @click.prevent="close">Отменить</button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { required, email, numeric, helpers } from "vuelidate/lib/validators";
import axios from "axios";

const letters = helpers.regex("alpha", /[А-Яа-я_]/);
const phoneNumber = helpers.regex(
  "alpha",
  /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
);

export default {
  name: "AddOrder",
  data() {
    return {
      name: null,
      phone: null,
      email: null,
      region: null,
      address: null,
      summ: null,
      files: [],
      product: [],
      newOrder: {}
    };
  },
  methods: {
    uploadFiles(e) {
      let uploadedFiles = this.files;
      let addedFiles = e.target.files;

      for (let i = 0; i < addedFiles.length; i++) {
        if (this.validateFileExtension(addedFiles.item(i))) {
          uploadedFiles.push(addedFiles.item(i));
        }
      }
    },
    deleteFile(fileIndex) {
      this.files.splice(fileIndex, 1);
    },
    validateFileExtension(file) {
      let fileTypes = [
        "doc",
        "docx",
        "pdf",
        "xml",
        "fb2",
        "txt",
        "ppt",
        "pptx",
        "xls",
        "xlam",
        "xlsx",
        "rar",
        "zip",
        "png",
        "jpeg",
        "jpg",
        "png",
        "gif"
      ];

      let fileExtension = file.name.split(".").pop();

      for (let k in fileTypes) {
        if (fileExtension === fileTypes[k]) {
          return fileExtension;
        }
      }
      return false;
    },
    getFileSize(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      let k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    validateFileSize(fileList) {
      let filesSize = 0;
      for (let file of fileList) {
        filesSize += file.size;
      }

      return filesSize / 1024 / 1024 <= 10;
    },
    setIcon(file) {
      let fileExtension = file.name.split(".").pop();
      let fileTypeIcons = {
        doc: "/img/icons/icons8-doc-50.png",
        docx: "/img/icons/icons8-word-50.png",
        pdf: "/img/icons/icons8-pdf-2-50.png",
        xml: "/img/icons/icons8-xml-50.png",
        fb2: "/img/icons/icons8-fb-2-50.png",
        txt: "/img/icons/icons8-txt-50.png",
        ppt: "/img/icons/icons8-ppt-50.png",
        pptx: "/img/icons/icons8-ppt-50.png",
        xls: "/img/icons/icons8-xls-50.png",
        xlam: "/img/icons/icons8-xls-50.png",
        xlsx: "/img/icons/icons8-xls-50.png",
        rar: "/img/icons/icons8-rar-50.png",
        zip: "/img/icons/icons8-zip-50.png"
      };
      if (file.type.indexOf("image/") !== -1) {
        return window.URL.createObjectURL(file);
      } else {
        for (let fileType in fileTypeIcons) {
          if (fileExtension === fileType) {
            return fileTypeIcons[fileType];
          }
        }
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid || !this.validateFileSize(this.files)) {
      } else {
        this.$store.commit("showPreloader");
        this.newOrder = new FormData(document.querySelector(".addOrder"));

        this.newOrder.set("department_id", this.region.id);
        this.newOrder.set("product_id", this.product.id);

        for (var [key, value] of this.newOrder.entries()) {
          if (value === "") {
            this.newOrder.delete(key);
          }
        }

        if (this.newOrder.has("amount") && this.newOrder.get("amount") === "") {
          this.newOrder.delete("amount");
        }

        if (
          this.newOrder.has("address") &&
          this.newOrder.get("address") === ""
        ) {
          this.newOrder.delete("address");
        }

        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.access_token
          }
        });

        instance
          .post("/api/post/orders/create", this.newOrder)
          .then(response => {
            this.$store.commit("hidePreloader");
            let orderInfo = {};
            orderInfo.name = this.name;
            orderInfo.phone = this.phone.replace(/\D/g, "");
            orderInfo.email = this.email;
            orderInfo.type = 0;
            orderInfo.department = this.region;
            orderInfo.address = this.address;
            orderInfo.amount = this.summ;
            orderInfo.files = this.files;
            orderInfo.created_at = new Date();
            orderInfo.id = response.data.id;
            orderInfo.stage = { name: "Новая заявка", color: "#ff6868" };
            orderInfo.product_id = this.product.id;

            this.$store.state.orders.unshift(orderInfo);

            this.$store.commit("closeModal");

            this.newOrder = {};
            orderInfo = {};

            this.name = null;
            this.phone = null;
            this.email = null;
            this.orderType = 0;
            this.region = null;
            this.address = null;
            this.summ = null;
            this.files = [];
            this.submitStatus = null;
            this.product = {};
            this.newOrder = {};
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("createMessage", {
                type: "danger",
                text: this.$store.state.messages.loginError
              });
              this.$store.commit("hidePreloader");
              this.$store.commit("forceLogout");
            }
            if (error.response.status === 422) {
              this.$store.commit("hidePreloader");
              this.$store.commit("createMessage", {
                type: "danger",
                text: this.$store.state.messages.invalidData
              });
            }
            if (error.response.status === 500) {
              this.$store.commit("hidePreloader");
              this.$store.commit("createMessage", {
                type: "danger",
                text: this.$store.state.messages.serverError
              });
            }
            if (error.response.status === 413) {
              this.$store.commit("hidePreloader");
              this.$store.commit("createMessage", {
                type: "danger",
                text: 'Превышен размер файла'
              });
            }
          });
      }
    },
    close() {
      this.$store.commit("closeModal");
    }
  },
  validations: {
    name: {
      required,
      letters
    },
    phone: {
      required,
      phoneNumber
    },
    email: {
      email
    },
    orderType: {},
    region: {
      required
    },
    address: {},
    summ: {
      numeric
    },
    product: {
      required
    }
  }
};
</script>

<style lang="scss">
.preview {
  margin: 5px 7px;
  width: 50px;
  position: relative;
  padding-top: 20px;

  &__delete {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    opacity: 0.8;
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    span {
      display: inline-block;
      position: relative;
      right: -2px;
      width: 15px;
      height: 15px;

      &:before,
      &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 4px;
        background-color: #f57f6c;
        border-radius: 3px;
        left: 5px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &__img {
    width: 100%;
    height: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 5px;
  }

  &__name {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__error {
    width: 100%;
    margin-top: 15px;
    width: 100%;

    .error {
      position: initial;
    }
  }
}
</style>
