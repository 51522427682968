<template>
  <div class="partners">
    <LeftMenu />
    <main class="page-container">
      <MobileHeader />
      <PageTitle :title="'Партнёры'" purse notice createNewPartner/>
      <div class="content">
        <PartnersContent @getData="getData" :flag="flag"/>
      </div>
      <ModalOverlay />
      <PartnerInfo :data="data" v-if="this.$store.state.openedModal=='PartnerInfo'"/>
      <EditPartner :partner="data" v-if="this.$store.state.openedModal=='EditPartner'"/>
      <AddRequest :partner="data" v-if="this.$store.state.openedModal == 'request-modal'"/>
      <DeletePartner :data="data" :type="type" :flag="flag" @getData="getData" v-if="this.$store.state.openedModal=='DeletePartner'"/>
      <InfoMessage :message="this.$store.state.message.text" :type="this.$store.state.message.type"/>
      <Preloader/>
      <CreatePartner  v-show="this.$store.state.openedModal=='create-partner-modal'"/>
    </main>
  </div>  
</template>

<script>
  import LeftMenu        from '../Components/LeftMenu.vue';
  import PageTitle       from '../Components/PageTitle.vue';
  import ModalOverlay    from '../Components/ModalOverlay.vue';
  import MobileHeader    from '../Components/MobileHeader.vue';
  import PartnersContent from './PartnersContent.vue';
  import PartnerInfo     from './Modals/PartnerInfo.vue';
  import EditPartner     from './Modals/EditPartner.vue';
  import DeletePartner   from './Modals/DeletePartner.vue';
  import axios           from "axios";
  import InfoMessage     from '../Components/InfoMessage';
  import Preloader       from "../Components/Preloader";
  import CreatePartner   from './Modals/CreatePartner';
  import AddRequest      from './Modals/AddRequest';


  export default {
    name: 'Partners',
    components: {
      LeftMenu,
      PageTitle,
      MobileHeader,
      ModalOverlay,
      PartnersContent,
      PartnerInfo,
      EditPartner,
      DeletePartner,
      InfoMessage,
      Preloader,
      CreatePartner,
      AddRequest
    },
    data() {
      return {
        data: null,
        type: '',
        flag: true
      }
    },
    methods: {
      getData(data = null, type = '', flag = true) {
        this.data = data;
        this.type = type;
        this.flag = flag;
      },
    }
  }
</script>