<template>
  <transition name="fade" mode="in-out">
    <div class="modal-big" @click.stop>
      <div class="modal-big__header">
        <h3 class="modal-big__title">Добавить вебхук</h3> 
        </div>
      <div class="modal-big__body">
        <form class="form">
          <div class="form__row">
            <label class="form__label" for="webhookName">Название:</label>
            <input
              type="text"
              class="form__input"
              placeholder="Основной"
              v-model.trim="$v.webhookName.$model"
              :class="{
                errorInput: $v.webhookName.$anyError, 
                validInput: !$v.webhookName.$anyError && $v.webhookName.$dirty
              }"
            >
            <div
              class="error"
              v-if="!$v.webhookName.required && $v.webhookName.$dirty"
            >Это обязательное поле</div>
          </div>
          <div class="form__row">
            <label for="webhookUrl" class="form__label">Url - адрес:</label>
            <input
              type="text"
              class="form__input"
              placeholder="http://example.com/webhooks"
              v-model.trim="$v.webhookUrl.$model"
              :class="{
                errorInput: $v.webhookUrl.$anyError, 
                validInput: !$v.webhookUrl.$anyError && $v.webhookUrl.$dirty
              }"
            >
            <div
              class="error"
              v-if="!$v.webhookUrl.required && $v.webhookUrl.$dirty"
            >Это обязательное поле</div>
            <div
              class="error"
              v-if="!$v.webhookUrl.url && $v.webhookUrl.$dirty"
            >Некорректный формат Url</div>
          </div>
          <div class="form__row--btns">
            <button class="btn btn__success" @click.prevent="createWebhook">Добавить</button>
            <button class="btn btn__danger" @click.prevent="closeModal">Отменить</button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { required, url } from "vuelidate/lib/validators";
import axios             from "axios";

export default {
  name: "AddWebhook",
  data() {
    return {
      webhookName: null,
      webhookUrl:  null
    };
  },
  validations: {
    webhookName: {
      required
    },
    webhookUrl: {
      required,
      url
    }
  },
  methods: {
    createWebhook() {
      this.$store.commit('createWebhook', {name: this.webhookName, url: this.webhookUrl});
      this.webhookName = null;
      this.webhookUrl  = null;
    }, 
    closeModal() {
      this.$store.commit("closeModal");
    }
  }
};
</script>

<style lang="scss">
</style>
