<template>
  <transition name="fade" mode="in-out">
    <div class="modal-big">
      <div class="modal-big__header">
        <h3 class="modal-big__title">Обратная связь</h3>
      </div>
      <div class="modal-big__body">
        <form class="form" name="feedback">
          <div class="form__row">
            <label class="form__label">Заголовок сообщения:</label>
            <input
              v-model.trim="$v.title.$model"
              :class="{
                errorInput: $v.title.$anyError, 
                validInput: !$v.title.$anyError && $v.title.$dirty
              }"
              type="text"
              class="form__input"
              placeholder="Введите Ваше сообщение..."
            >
            <div class="error" v-if="!$v.title.required && $v.title.$dirty">Это обязательное поле</div>
          </div>

          <div class="form__row">
            <label class="form__label">Текст сообщения:</label>
            <textarea
              v-model.trim="$v.message.$model"
              :class="{
                errorInput: $v.message.$anyError, 
                validInput: !$v.message.$anyError && $v.message.$dirty
              }"
              class="form__input"
              placeholder="Введите Ваше сообщение..."
            ></textarea>
            <div
              class="error"
              v-if="!$v.message.required && $v.message.$dirty"
            >Это обязательное поле</div>
          </div>

          <div class="form__row form__row--btns">
            <button class="btn btn__success" @click.prevent="submit">Oтправить</button>
            <button class="btn btn__danger" @click.prevent="closeModal">Отменить</button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { required, url } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "FeedbackModal",
  data() {
    return {
      title: "",
      message: ""
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("closeModal");
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ошибка");
      } else {
        console.log("title: " + this.title, "message: " + this.message);

        this.$store.commit("closeModal");
      }
    }
  },
  validations: {
    title: {
      required
    },
    message: {
      required
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  height: 100px;
}
</style>