<template>
  <div class="notifications">
    <div class="notifications__full" v-if="this.$store.state.notificationNews.news.length">
      <!-- <router-link :to="'/news#' + n.id" class="notifications__item" v-for="n in this.$store.state.notificationNews.news" :key="n.id">
        <p class="notifications__title">{{ n.title }}</p>
      </router-link> -->
      <div class="notifications__item" v-for="n in this.$store.state.notificationNews.news" :key="n.id" @click="openNews(n)">
        <p class="notifications__title">{{ n.title }}</p>
      </div>
    </div>
    <div class="notifications__empty" v-else>
      <p>Новостей пока нет</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
    }
  },
  methods: {
    openNews(data) {
      this.$router.push({name: 'news'});
      this.$store.commit("setCurNews", data);
      this.$store.commit("openModal", "curNews");
    }
  },
};
</script>

<style lang="scss">
.notifications {
  position: absolute;
  top: calc(100% + 15px);
  right: -20px;
  width: 280px;
  min-height: 60px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  padding: 10px;
  font-size: 12px;
  z-index: 10;

  &:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    bottom: 100%;
    right: 25px;
  }

  &__item {
    display: block;
    padding: 7px 10px;
    transition: background-color 0.3s;
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:hover {
      background-color: rgba(#000, 0.07);
    }
  }

  &__title {
    color: #333;
    font-size: 1.2em;
    font-weight: 600;
    transition: color 0.3s;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #777;
  }

  &__empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
    color: #999;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .notifications {
    right: -10px;

    &:after {
      right: 15px;
    }
  }
}
</style>
