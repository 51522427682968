<template>
  <div class="partners__content">
    <div class="table-partners table" v-if="this.$store.state.requests.length">
      <div class="table__row table__row--header">
        <div class="table__cell table__cell--header">Название</div>
        <div class="table__cell table__cell--header">Партнеры</div>
        <div class="table__cell table__cell--header">Регионы</div>
        <div class="table__cell table__cell--header">Действия</div>
      </div>

      <div
        class="table__row"
        v-for="request in this.$store.state.requests"
        :key="request.id"
        @click="requestInfo(request.id)"
      >
        <div class="table__cell">{{request.name || "Без названия"}}</div>

        <div class="table__cell" v-if="request.users">{{request.users.name || request.users.email}}</div>
        <div class="table__cell" v-else>Партнер не привязан</div>

        <div class="table__cell" v-if="request.departments.length">
          <span
            class="region"
            v-for="region in request.departments"
            :key="region.id"
          >{{region.name}}</span>
        </div>
        <div class="table__cell" v-else>Регион не назначен</div>

        <div class="table__cell" @click.stop>
          <span class="btn btn__success" @click="edit(request)">Изменить</span>
          <span class="btn btn__danger" @click="del(request.id)">Удалить</span>
          <span class="btn" @click="log(request.id)">Лог</span>
        </div>
      </div>
    </div>

    <div class="table--empty" v-else>
      <div class="blur">
        <div class="blur-inner"></div>
        <div class="icon">
          <svg
            version="1.1"
            id="Слой_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-549 451 100 100"
            style="enable-background:new -549 451 100 100;"
            xml:space="preserve"
          >
            <g id="XMLID_160_">
              <g id="XMLID_161_">
                <path
                  id="XMLID_158_"
                  d="M-499,531.8c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                v-17.1c0,0-0.1,0-0.1,0.1C-463.2,525.2-479.3,531.8-499,531.8z"
                />
                <path
                  id="XMLID_157_"
                  d="M-499,510.2c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                V495c0,0-0.1,0-0.1,0.1C-463.2,503.6-479.3,510.2-499,510.2z"
                />
                <path
                  id="XMLID_156_"
                  d="M-499,488.5c-20,0-36.3-6.9-37.4-15.6c0,0,0,0-0.1,0v17.5c0,9.1,16.7,16.4,37.4,16.4s37.4-7.4,37.4-16.4
                                                v-17.1c0,0-0.1,0-0.1,0.1C-463.2,481.8-479.3,488.5-499,488.5z"
                />
                <path
                  id="XMLID_155_"
                  d="M-499,484.7c20.7,0,37.4-7.4,37.4-16.4c0-9.1-16.7-16.4-37.4-16.4c-20.7,0-37.4,7.4-37.4,16.4
                        C-536.4,477.3-519.6,484.7-499,484.7z M-499,463.8c5.6,0,10.2,2,10.2,4.4c0,2.5-4.5,4.4-10.2,4.4s-10.2-2-10.2-4.4
                        C-509.2,465.7-504.6,463.8-499,463.8z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div class="text">Недостаточно данных</div>
      </div>

      <div class="table-partners table">
        <div class="table__row table__row--header">
          <div class="table__cell table__cell--header">Название</div>
          <div class="table__cell table__cell--header">Партнеры</div>
          <div class="table__cell table__cell--header">Регионы</div>
          <div class="table__cell table__cell--header">Действия</div>
        </div>

        <div class="table__row">
          <div class="table__cell">Тестовый запрос</div>
          <div class="table__cell">Партнер</div>
          <div class="table__cell">Новосибирск, Омск, Томск</div>
          <div class="table__cell">
            <span class="btn btn__success">Изменить</span>
            <span class="btn btn__danger">Удалить</span>
            <span class="btn">Лог</span>
          </div>
        </div>
        <div class="table__row">
          <div class="table__cell">Тестовый запрос</div>
          <div class="table__cell">Партнер</div>
          <div class="table__cell">Новосибирск, Омск, Томск</div>
          <div class="table__cell">
            <span class="btn btn__success">Изменить</span>
            <span class="btn btn__danger">Удалить</span>
            <span class="btn">Лог</span>
          </div>
        </div>
        <div class="table__row">
          <div class="table__cell">Тестовый запрос</div>
          <div class="table__cell">Партнер</div>
          <div class="table__cell">Новосибирск, Омск, Томск</div>
          <div class="table__cell">
            <span class="btn btn__success">Изменить</span>
            <span class="btn btn__danger">Удалить</span>
            <span class="btn">Лог</span>
          </div>
        </div>
        <div class="table__row">
          <div class="table__cell">Тестовый запрос</div>
          <div class="table__cell">Партнер</div>
          <div class="table__cell">Новосибирск, Омск, Томск</div>
          <div class="table__cell">
            <span class="btn btn__success">Изменить</span>
            <span class="btn btn__danger">Удалить</span>
            <span class="btn">Лог</span>
          </div>
        </div>
        <div class="table__row">
          <div class="table__cell">Тестовый запрос</div>
          <div class="table__cell">Партнер</div>
          <div class="table__cell">Новосибирск, Омск, Томск</div>
          <div class="table__cell">
            <span class="btn btn__success">Изменить</span>
            <span class="btn btn__danger">Удалить</span>
            <span class="btn">Лог</span>
          </div>
        </div>
        <div class="table__row">
          <div class="table__cell">Тестовый запрос</div>
          <div class="table__cell">Партнер</div>
          <div class="table__cell">Новосибирск, Омск, Томск</div>
          <div class="table__cell">
            <span class="btn btn__success">Изменить</span>
            <span class="btn btn__danger">Удалить</span>
            <span class="btn">Лог</span>
          </div>
        </div>
      </div>
    </div>

    <RequestInfo :data="curRequest" v-if="this.$store.state.openedModal === 'request-info'" />
  </div>
</template>

<script>
import axios from "axios";
import RequestInfo from "./Modals/RequestInfo";

export default {
  name: "RequestsContent",
  components: {
    RequestInfo
  },
  data() {
    return {
      curRequest: null
    };
  },
  methods: {
    edit(request) {
      this.$store.commit("openModal", "EditRequestModal");
      this.$store.commit("setCurReq", request);
    },
    del(id) {
      this.$store.commit("openModal", "DeleteRequestModal");
      this.$store.commit("setCurRequestId", id);
    },
    log(id) {
      this.$store.commit("openModal", "LogModal");
      this.$store.commit("setCurRequestId", id);
    },
    requestPreview(request) {
      let string = JSON.stringify(request);
      if (string.length > 50) {
        string = string.substr(0, 50) + "...";
      }
      return string;
    },
    requestInfo(id) {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });

      instance
        .get("api/post/admin/request/show?id=" + id)
        .then(response => {
          this.$store.commit("hidePreloader");
          this.curRequest = response.data;
          this.$store.commit("openModal", "request-info");
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 422) {
            alert("422 News");
          }
          if (error.response.status === 500) {
            alert("500 News");
          }
        });
    }
  },
  mounted() {
    const instance = axios.create({
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.access_token
      }
    });

    this.$store.commit("showPreloader");

    instance
      .get("api/post/admin/request/show")
      .then(response => {
        this.$store.commit("setRequests", response.data);
        this.$store.commit("hidePreloader");
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.$store.commit("forceLogout");
        }
        if (error.response.status === 422) {
          alert("422 News");
        }
        if (error.response.status === 500) {
          this.$store.commit("createMessage", {
            type: "danger",
            text: this.$store.state.messages.serverError
          });
        }
      });
  }
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: unset;

  @media screen and (max-width: 800px) {
    font-size: 10px;
    margin-right: 2px;
    padding: 5px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 10px;
  }
}

.region {
  padding-right: 5px;

  &:not(:last-child) {
    &::after {
      content: ",";
    }
  }
}

.table--empty {
  position: relative;

  .table {
    filter: blur(5px);
  }

  .blur {
    z-index: 2;
  }
}
</style>
