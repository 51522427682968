<template>
  <transition name="fade">
    <div class="modal-big">
      <div class="modal-big__header">
        <h3 class="modal-big__title">Фильтр</h3>
      </div>
      <div class="modal-big__body">
        <form class="modal-big__form form">
          <label class="form__label" for>Период:</label>
          <div class="form__row">
            <v-date-picker mode="range" v-model="range" :input-props="{readonly: true}">
              <input
                id="date"
                slot-scope="{ inputProps, inputEvents }"
                class="form__input"
                v-bind="inputProps"
                v-on="inputEvents"
                placeholder="За все время"
              >
            </v-date-picker>
            <button
              type="button"
              @click="range = null"
              class="clear-button close-button"
              v-if="range"
            >
              <span></span>
            </button>
          </div>

          <div class="form__row">
            <label class="form__label">Регион:</label>
            <multiselect
              v-model="regionValue"
              :options="this.$store.state.filters.departments"
              :multiple="true"
              label="name"
              value="id"
              track-by="name"
              :show-labels="false"
              placeholder="Новосибирск"
            >
              <span slot="noResult">Совпадений не найдено</span>
            </multiselect>
          </div>

          <div class="form__row">
            <label class="form__label">Продукт:</label>
            <multiselect
              v-model="orderType"
              :options="this.$store.state.filters.products"
              label="name"
              value="id"
              :show-labels="false"
              :placeholder="'ПТС'"
            >
              <span slot="noResult">Совпадений не найдено</span>
            </multiselect>
          </div>

          <div class="form__row">
            <label class="form__label">Статус:</label>
            <multiselect
              v-model="status"
              :options="this.$store.state.filters.stages"
              label="name"
              :show-labels="false"
              valie="id"
              :placeholder="'Новая заявка'"
            >
              <span slot="noResult">Совпадений не найдено</span>
            </multiselect>
          </div>

          <div class="form__row" v-if="this.$store.state.isAdmin">
            <label class="form__label">Партнер:</label>
            <!-- Добавить партнера -->
            <multiselect
              v-model="users"
              :options="this.$store.state.filters.users"
              label="name"
              :show-labels="false"
              valie="id"
              :placeholder="'Название партнера'"
            >
              <span slot="noResult">Совпадений не найдено</span>
            </multiselect>
          </div>

          <div class="form__row form__row--btns">
            <button type="submit" class="btn btn__success" @click.prevent="filter">Применить</button>
            <button class="btn btn__danger" @click.prevent="close">Отменить</button>
            <button class="btn clear-filter" @click.prevent="clearFilter" v-if="filterStatus" title="Очистить фильтр">
              <svg
                height="512pt"
                viewBox="0 0 512 512"
                width="512pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m368.3125 0h-351.261719c-6.195312-.0117188-11.875 3.449219-14.707031 8.960938-2.871094 5.585937-2.3671875 12.3125 1.300781 17.414062l128.6875 181.28125c.042969.0625.089844.121094.132813.183594 4.675781 6.3125 7.203125 13.957031 7.21875 21.816406v147.796875c-.027344 4.378906 1.691406 8.582031 4.777344 11.6875 3.085937 3.105469 7.28125 4.847656 11.65625 4.847656 2.226562 0 4.425781-.445312 6.480468-1.296875l72.3125-27.574218c6.480469-1.976563 10.78125-8.089844 10.78125-15.453126v-120.007812c.011719-7.855469 2.542969-15.503906 7.214844-21.816406.042969-.0625.089844-.121094.132812-.183594l128.683594-181.289062c3.667969-5.097657 4.171875-11.820313 1.300782-17.40625-2.832032-5.511719-8.511719-8.9726568-14.710938-8.960938zm-131.53125 195.992188c-7.1875 9.753906-11.074219 21.546874-11.097656 33.664062v117.578125l-66 25.164063v-142.742188c-.023438-12.117188-3.910156-23.910156-11.101563-33.664062l-124.933593-175.992188h338.070312zm0 0"
                ></path>
                <path
                  d="m373.332031 512c-76.457031 0-138.664062-62.207031-138.664062-138.667969 0-76.457031 62.207031-138.664062 138.664062-138.664062 76.460938 0 138.667969 62.207031 138.667969 138.664062 0 76.460938-62.207031 138.667969-138.667969 138.667969zm0-245.332031c-58.816406 0-106.664062 47.847656-106.664062 106.664062 0 58.816407 47.847656 106.667969 106.664062 106.667969 58.816407 0 106.667969-47.851562 106.667969-106.667969 0-58.816406-47.851562-106.664062-106.667969-106.664062zm0 0"
                ></path>
                <path
                  d="m414.804688 430.804688c-4.09375 0-8.191407-1.558594-11.304688-4.691407l-82.964844-82.964843c-6.253906-6.253907-6.253906-16.386719 0-22.636719 6.25-6.25 16.382813-6.25 22.632813 0l82.964843 82.964843c6.25 6.25 6.25 16.386719 0 22.636719-3.136718 3.132813-7.210937 4.691407-11.328124 4.691407zm0 0"
                ></path>
                <path
                  d="m331.863281 430.804688c-4.097656 0-8.195312-1.558594-11.308593-4.691407-6.25-6.25-6.25-16.386719 0-22.636719l82.964843-82.964843c6.25-6.25 16.382813-6.25 22.636719 0 6.25 6.25 6.25 16.382812 0 22.636719l-82.96875 82.964843c-3.15625 3.132813-7.230469 4.691407-11.324219 4.691407zm0 0"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "moment/locale/ru";

export default {
  name: "FilterOrder",
  data() {
    return {
      range: null,
      regionValue: [],
      orderType: null,
      status: null,
      users: null
    };
  },
  methods: {
    close() {
      this.$store.commit("closeModal");
    },
    filter() {
      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.access_token
        }
      });

      this.$store.commit("addFilter", {
        range: this.range,
        regions: this.regionValue,
        product: this.orderType,
        stage: this.status,
        users: this.users
      });

      let filter = "";
      let request = "";

      this.$store.commit("showPreloader");

      if (this.$store.state.ordersFilter) {
        filter += this.$store.state.ordersFilter;
      }
      if (this.$store.state.searchParam) {
        filter += this.$store.state.searchParam;
      }
      // if(this.$store.state.isSend) {
      //   filter += "&type=send";
      // }

      instance
        .get(`/api/get/orders?${filter}`)
        .then(response => {
          if (response.data.orders.message) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: response.data.orders.message
            });
          }
          this.$store.commit("hidePreloader");
          this.$store.commit("updateOrders", response.data);
          this.$store.commit("closeModal");
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.loginError
            });
            this.$store.commit("forceLogout");
          }
          if (error.response.status === 422) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.invalidData
            });
          }
          if (error.response.status === 500) {
            this.$store.commit("createMessage", {
              type: "danger",
              text: this.$store.state.messages.serverError
            });
          }
        });
    },
    clearFilter() {
      this.range = null;
      this.regionValue = [];
      this.orderType = null;
      this.status = null;
      this.users = null;

      this.filter();
    }
  },
  computed: {
    filterStatus() {
      return !!(
        this.range ||
        this.regionValue.length ||
        (this.orderType && this.orderType.id) ||
        (this.status && this.status.id) ||
        this.users
      );
    }
  }
};
</script>

<style lang="scss" >
button {
  background-color: transparent;
}
.form__input {
  width: 100%;
}
.clear-button {
  top: 50%;
  right: 10px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);

  span {
    width: 15px;
    height: 15px;

    &:before,
    &:after {
      width: 3px;
      border-radius: 3px;
      opacity: 1;
      background-color: #fd77a4;
    }
  }
}

.clear-filter {
  position: absolute;
  height: 100%;
  right: 0;
  width: 40px;
  min-width: initial;
  fill: #888;
  padding: 0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 80%;
    max-height: 70%
  }
}

.vc-shadow {
  box-shadow: none;
}

.vc-border {
  border: 1px solid #ced4da;
}

.focus\:vc-shadow-outline:focus {
  box-shadow: none;
}

.vc-grid-container.grid {
  grid-template-columns: minmax(220px, 1fr) !important;
}

.multiselect__tag {
  background: #e4485d;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: darken($color: #e4485d, $amount: 10%);
}

.multiselect__tag-icon:after {
  color: darken($color: #e4485d, $amount: 25%);
}

@media (max-width: 400px) {
  .clear-filter {
    width: 35px;
    position: relative;
    left: initial;
    right: initial;
  }
}
</style>