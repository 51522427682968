<template>
  <div class="modal-big">
    <div class="modal-big__header">
      <h3 class="modal-big__title">Список регионов</h3>
      <div class="close-button" @click="close">
        <span></span>
      </div>
    </div>
    <div class="modal-big__body">
      <ul>
        <li v-for="region in this.$store.state.filters.departments" :key="region.id"><span>{{region.id}}</span>-<span>{{region.name}}</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Regions",
  methods: {
    close() {
      this.$store.commit("closeModal");
    }
  }
};
</script>
<style lang="scss" scoped>
ul {
  li {
    margin-bottom: 10px;

    span {
      display: inline-block;

      &:first-child {
        min-width: 25px;
        padding-right: 20px;
      }

      &:last-child {
        padding-left: 20px;
      }
    }
  }
}
</style>