<template>
  <div class="form__group">
    <div class="form__row form__row--header" @click="isOpened = !isOpened">
      <p>{{header}}</p>
      <span class="arr-down" :class="{closed: !isOpened}">
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 240.811 240.811"
          style="enable-background:new 0 0 240.811 240.811;"
          xml:space="preserve"
        >
          <path
            d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
                    c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
                    c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
          ></path>
        </svg>
      </span>
    </div>
    <transition name="slide">
      <div v-show="isOpened">
        <div class="form__row form__row--constr" v-for="(input, index) in inputs" :key="index">
          <input
            type="text"
            :value="input.name"
            class="form__input"
            @change="show(inputs)"
            v-model="inputs[index].name"
            :class="{
              errorInput: $v.input.name.$anyError, 
              validInput: !$v.input.name.$anyError && $v.input.name.$dirty && $v.input.name.$model
            }"
            :readonly="requiredField"
          >

          <span>:</span>
          <input :value="input.value" class="form__input" v-model="inputs[index].value">
          <span class="remove-btn close-button" @click="removeField(inputs, index)" v-if="!requiredField">
            <span></span>
          </span>
        </div>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "FormInput",
  data() {
    return {
      isOpened: false
    };
  },
  props: {
    header: String,
    inputs: Object,
    requiredField: Boolean
  },
  methods: {
    removeField(array, index) {
      array.splice(index, 1);
    },
    show(array) {
      console.log(array);
    }
  },
  validations: {
    input: {
      name: {
        required
      },
      value: {
        required: requiredIf(function() {
          return this.input.name;
        })
      }
    }
  }
};
</script>

<style lang="scss">
.form__row--header {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: calc(100% - 30px);
    height: 1px;
    background-color: #ced4da;    
  }

  p {
    background-color: #f5f5f5;
    padding-right: 20px;
    position: relative;
    z-index: 1;
  }
}

.remove-btn {
  width: 15px;
  height: 15px;
  position: relative;
  right: 0;
  top: 0;

  span {
    &:before,
    &:after {
      width: 3px;
      border-radius: 3px;
      background-color: #fd77a4;
    }
  }
}
</style> 
