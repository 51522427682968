<template>
  <transition name="fade">
    <div class="order-info" @click.stop>
      <div class="order-info__header">
        <p>
          Заявка №
          <span class="order-info__id">{{ id }}</span>
        </p>
        <div class="close-button" @click="close">
          <span></span>
        </div>
      </div>
      <div class="order-info__body">
        <div class="order-info__row">
          <span class="order-info__title">ФИО:</span>
          <span class="order-info__data">{{ name ? name : 'Не указано' }}</span>
        </div>
        <div class="order-info__row" v-if="this.$store.state.isAdmin">
          <span class="order-info__title">Партнер:</span>
          <span class="order-info__data">{{ partner ? partner : 'Не указано' }}</span>
        </div>
        <div class="order-info__row">
          <span class="order-info__title">Сумма заявки:</span>
          <span class="order-info__data">{{ amount ? amount : 'Не указано' }}</span>
        </div>
        <div class="order-info__row">
          <span class="order-info__title">Регион:</span>
          <span class="order-info__data">{{ department ? department.name : 'Не указан' }}</span>
        </div>
        <div class="order-info__row">
          <span class="order-info__title">Email:</span>
          <span class="order-info__data">{{ email ? email : 'Не указано' }}</span>
        </div>
        <div class="order-info__row">
          <span class="order-info__title">Источник заявки:</span>
          <span
            class="order-info__data"
          >{{ type_order ? this.$store.state.orderTypes[type_order] : 'Не указано' }}</span>
        </div>
        <div class="order-info__row">
          <span class="order-info__title">Тип продукта:</span>
          <span class="order-info__data">{{ product_id ? product_id.name : 'ПТС' }}</span>
        </div>
        <div class="order-info__row">
          <span class="order-info__title">Дата создания:</span>
          <span class="order-info__data">{{ dateFormat(created_at) }}</span>
        </div>
        <div class="order-info__row">
          <span class="order-info__title">Телефон:</span>
          <span class="order-info__data">{{ phone ? phoneFormat(phone) : 'Не указано' }}</span>
        </div>
        <div class="order-info__row">
          <span class="order-info__title">Статус:</span>
          <span class="order-info__data">{{ stage ? stage.name : 'Не указано' }}</span>
        </div>
        <div class="order-info__row" v-if="closed_at">
          <span class="order-info__title">Дата закрытия:</span>
          <span class="order-info__data">{{ dateFormat(closed_at) }}</span>
        </div>
        <div class="order-info__row" v-if="closure">
          <span class="order-info__title">Причина закрытия:</span>
          <span class="order-info__data">{{ closure.name }}</span>
        </div>
        <div class="order-info__row" v-if="closure_comment">
          <span class="order-info__title">Комментарий:</span>
          <span class="order-info__data">{{ closure_comment }}</span>
        </div>
        <div class="order-info__row order-info__row--files" v-if="files.length">
          <span class="order-info__title">Файлы:</span>
          <div class="order-info__data">
            <div class="preview" v-for="(file, index) in files" :key="index" :title="file.name">
              <a :href="`https://static.sfgroup.ru/public/files/docOrder/${file.link}`" class="preview__link">
                <div class="preview__img" :style="'background-image: url('+ setIcon(file) + ')'"></div>
                <div class="preview__name">{{ file.name }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
moment.locale("ru");

export default {
  name: "OrderInfo",
  props: {
    id: String,
    email: String,
    partner: String,
    name: String,
    created_at: String,
    amount: String,
    type_order: String,
    phone: String,
    product_id: String,
    stage: Object,
    closed_at: String,
    closure_comment: String,
    closure: Object,
    department: Object,
    files: Object
  },
  methods: {
    close() {
      this.$store.commit("closeModal");
      console.log(this.partner);
    },
    setIcon(file) {
      let fileExtension = file.name.split(".").pop();
      let images = ["jpeg", "bmp", "png", "jpg"];
      let fileTypeIcons = {
        doc: "/img/icons/icons8-doc-50.png",
        docx: "/img/icons/icons8-word-50.png",
        pdf: "/img/icons/icons8-pdf-2-50.png",
        xml: "/img/icons/icons8-xml-50.png",
        fb2: "/img/icons/icons8-fb-2-50.png",
        txt: "/img/icons/icons8-txt-50.png",
        ppt: "/img/icons/icons8-ppt-50.png",
        pptx: "/img/icons/icons8-ppt-50.png",
        xls: "/img/icons/icons8-xls-50.png",
        xlam: "/img/icons/icons8-xls-50.png",
        xlsx: "/img/icons/icons8-xls-50.png",
        rar: "/img/icons/icons8-rar-50.png",
        zip: "/img/icons/icons8-zip-50.png"
      };

      if (
        fileExtension === "jpeg" ||
        fileExtension === "bmp" ||
        fileExtension === "png" ||
        fileExtension === "jpg"
      ) {
        return "https://static.sfgroup.ru/public/files/docOrder/" + file.link;
      } else {
        for (let fileType in fileTypeIcons) {
          if (fileExtension === fileType) {
            return fileTypeIcons[fileType];
          }
        }
      }
    },
    phoneFormat(phone) {
      return (
        "+" +
        phone.substr(0, 1) +
        " " +
        "(" +
        phone.substr(1, 3) +
        ")" +
        " " +
        phone.substr(4, 3) +
        " " +
        phone.substr(7, 2) +
        " " +
        phone.substr(9)
      );
    },
    dateFormat(date) {
      let stillUtc = moment.utc(date).toDate();
      let local = moment(stillUtc)
        .local()
        .format("DD MMMM YYYY HH:mm:ss");
      return local;
    }
  }
};
</script>

<style lang="scss">
.close-button {
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;

  span {
    display: block;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }

    &::before,
    &::after {
      position: absolute;
      left: 50%;
      content: "";
      height: 100%;
      width: 2px;
      background-color: #fff;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.close-button {
  span {
    &:before,
    &:after {
      background-color: rgba(#000, 0.3);
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.order-info {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 780px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  font-size: 16px;
  max-height: 95vh;
  z-index: 10;
  overflow: auto;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 65%;
    bottom: 30px;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.0625);
  }

  &__header {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
  }

  &__id {
    color: #888888;
  }

  &__title {
    margin-right: 10px;
    font-weight: bold;
  }

  &__data {
    color: #888888;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__row {
    width: 46%;
    margin: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
    margin-bottom: 10px;
    word-break: break-word;
  }

  .preview {
    padding-top: 0;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &__link {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .order-info {
    &:before {
      display: none;
    }

    &__row {
      width: 100%;
    }
  }
}
</style>