<template>
  <div class="header">
    <div class="left-block">
      <div class="header__title">{{ title }}</div>
    </div>

    <div class="right-block">
      <div class="purse" v-if="purse && !this.$store.state.isAdmin">
        <span class="purse__icon">
          <svg
            version="1.1"
            id="Слой_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background:new 0 0 100 100;"
            xml:space="preserve"
          >
            <g id="XMLID_2_">
              <path
                id="XMLID_11_"
                d="M94.3,32.1H92l-3-16.3c-0.2-1.2-1.4-2-2.6-1.8l-7.1,1.3c0.1-0.5,0-1.1-0.3-1.6l-8-12.1c-0.7-1-2-1.3-3-0.6
                                    l-34,22.6l-27.5,5c-0.2,0-0.1,1.3,0.1,3.4H5.7c-1.5,0-2.6,1.2-2.6,2.7v61.7c0,1.5,1.2,2.7,2.6,2.7h88.5c1.5,0,2.6-1.2,2.6-2.7V34.9
                                    C96.9,33.3,95.7,32.1,94.3,32.1z M64.3,12.1c1-0.7,2.4-0.4,3,0.6l2.9,4.3l-18.5,3.4L64.3,12.1z M78.8,22.8c1.2-0.2,2.3,0.6,2.6,1.8
                                    l1.4,7.6H27.8L78.8,22.8z M94.3,73.5c0,1.7-1.3,3.1-2.8,3.1H64.1c-1.6,0-2.8-1.4-2.8-3.1V60.4c0-1.7,1.3-3.1,2.8-3.1h27.3
                                    c1.6,0,2.8,1.4,2.8,3.1V73.5z"
              ></path>
              <path
                id="XMLID_1_"
                d="M66.1,67.1c-0.1-3.1,2.6-5.7,5.7-5.7c3,0.1,5.6,2.6,5.5,5.7c-0.1,3-2.6,5.4-5.6,5.4
                                    C68.7,72.5,66.2,70.1,66.1,67.1z"
              ></path>
            </g>
          </svg>
        </span>
        {{ this.$store.state.balance ? this.$store.state.balance : 0}} руб.
      </div>
      <div class="notice" v-if="notice && !this.$store.state.isAdmin" @click="toggleNotifications">
        <span class="notice__icon">
          <svg
            version="1.1"
            id="Слой_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background:new 0 0 100 100;"
            xml:space="preserve"
          >
            <path
              d="M88.4,74.3H88c-2.4-2.3-4.9-5.3-7-8.8c-4.5-7.3-5.6-14-5.7-18.6c0.2-1.5,0.3-3,0.3-4.5c0-14.6-8.5-26.7-19.9-29.9
                                c1-1.3,1.6-2.8,1.6-4.6c0-4.1-3.3-7.4-7.4-7.4S42.5,3.8,42.5,8c0,1.7,0.6,3.2,1.5,4.5c-11.6,3.1-20.2,15.4-20.2,30
                                c0,1.5,0.1,3,0.3,4.5c-0.1,4.7-1.2,11.4-5.7,18.6c-2.2,3.5-4.7,6.4-7,8.8v12.9H38c0,0.1,0,0.1,0,0.2c0,6.6,5.4,12,12,12
                                s12-5.4,12-12c0-0.1,0-0.1,0-0.2h26.6V74.3H88.4z M50.7,95.3c-2.5,1.6-5.8,0.9-7.5-1.6c-1.4-2.1-1.2-4.8,0.3-6.6
                                c0,1.4,0.3,2.7,1.1,3.9c1.7,2.5,4.7,3.6,7.5,2.9C51.7,94.4,51.3,94.9,50.7,95.3z"
            ></path>
          </svg>
        </span>
        <span
          class="notice__count"
          v-if="this.$store.state.notificationNews.count"
        >{{this.$store.state.notificationNews.count}}</span>

        <Notifications v-show="this.$store.state.notificationsOpened"/>
      </div>
      <div class="feedback-button" v-if="feedbackButton && !this.$store.state.isAdmin">
        <button class="btn desktop" @click.prevent="openFeedbackModal">Написать сообщение</button>
        <button class="btn mobile" @click.prevent="openFeedbackModal">Написать</button>
      </div>
      <div class="feedback-button" v-if="createNewsButton && this.$store.state.isAdmin">
        <button class="btn desktop" @click.prevent="openAddNewsModal">Добавить новость</button>
        <button class="btn mobile" @click.prevent="openAddNewsModal">
          <span>
             <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 383.947 383.947"
            style="enable-background:new 0 0 383.947 383.947;"
            xml:space="preserve"
          >
            <g>
              <g>
                <g>
                  <polygon
                    points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893 			"
                  ></polygon>
                  <path
                    d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04
				            C386.027,77.92,386.027,64.373,377.707,56.053z"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
          </span>
        </button>
      </div>
      <div class="feedback-button" v-if="createNewPartner && this.$store.state.isAdmin">
        <button class="btn desktop" @click.prevent="openCreatePartnerModal">Добавить партнера</button>
        <button class="btn mobile" @click.prevent="openCreatePartnerModal">
          <span class="">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <g>
                <g>
                  <path d="M367.57,256.909c-9.839-4.677-19.878-8.706-30.093-12.081C370.56,219.996,392,180.455,392,136C392,61.01,330.991,0,256,0
                    c-74.991,0-136,61.01-136,136c0,44.504,21.488,84.084,54.633,108.911c-30.368,9.998-58.863,25.555-83.803,46.069
                    c-45.732,37.617-77.529,90.086-89.532,147.743c-3.762,18.066,0.745,36.622,12.363,50.908C25.222,503.847,42.365,512,60.693,512
                    H307c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H60.693c-8.538,0-13.689-4.766-15.999-7.606
                    c-3.989-4.905-5.533-11.29-4.236-17.519c20.755-99.695,108.691-172.521,210.24-174.977c1.759,0.068,3.526,0.102,5.302,0.102
                    c1.793,0,3.578-0.035,5.354-0.104c31.12,0.73,61.05,7.832,89.044,21.14c9.977,4.74,21.907,0.499,26.649-9.478
                    C381.789,273.582,377.547,261.651,367.57,256.909z M260.878,231.877c-1.623-0.029-3.249-0.044-4.878-0.044
                    c-1.614,0-3.228,0.016-4.84,0.046C200.465,229.35,160,187.312,160,136c0-52.935,43.065-96,96-96s96,43.065,96,96
                    C352,187.299,311.555,229.329,260.878,231.877z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M492,397h-55v-55c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v55h-55c-11.046,0-20,8.954-20,20
                    c0,11.046,8.954,20,20,20h55v55c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20v-55h55c11.046,0,20-8.954,20-20
                    C512,405.954,503.046,397,492,397z"/>
                </g>
              </g>
              <g>
            </svg>
          </span>
        </button>
      </div>
      <div class="feedback-button" v-if="createRequest && this.$store.state.isAdmin">
        <button class="btn desktop" @click.prevent="openRequestModal">Добавить реквест</button>
        <button class="btn mobile" @click.prevent="openRequestModal">
          <span class="">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1280.000000 1280.000000"
                preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M5655 11235 c-38 -8 -108 -33 -155 -56 -69 -34 -102 -59 -175 -133
              -96 -97 -136 -160 -177 -281 l-23 -70 -3 -1552 -2 -1553 -1558 -2 -1557 -3
              -78 -28 c-100 -35 -175 -83 -255 -160 -123 -121 -183 -246 -203 -423 -7 -65
              -9 -351 -7 -824 4 -810 1 -770 78 -925 75 -153 219 -278 389 -339 l86 -31
              1553 -3 1552 -2 0 -1513 c1 -1615 0 -1592 50 -1717 55 -139 182 -278 318 -348
              155 -81 108 -77 1002 -77 885 0 848 -3 995 70 172 84 317 263 360 446 13 56
              15 265 15 1602 l0 1537 1520 0 c1697 0 1603 -4 1755 70 141 68 244 172 316
              315 69 141 69 129 69 988 0 841 0 841 -60 972 -75 164 -236 309 -410 368 l-65
              22 -1560 5 -1560 5 -5 1560 -5 1560 -22 65 c-69 200 -238 370 -436 437 l-82
              28 -795 2 c-646 1 -808 -1 -865 -12z"/>
              </g>
            </svg>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from "./Notifications";

export default {
  name: "PageTitle",
  components: {
    Notifications
  },
  props: {
    title:            String,
    purse:            Boolean,
    notice:           Boolean,
    feedbackButton:   Boolean,
    createNewsButton: Boolean,
    createNewPartner: Boolean,
    createRequest:    Boolean
  },
  data() {
    return {
      notificationsOpened: this.$store.state.notificationsOpened
    };
  },
  methods: {
    toggleNotifications() {
      if (this.$store.state.notificationsOpened) {
        this.$store.commit("closeNotifications");
      } else {
        this.$store.commit("openNotifications");
      }
    },
    openFeedbackModal() {
      this.$store.commit("openModal", "feedback-modal");
    },
    openAddNewsModal() {
      this.$store.commit("openModal", "AddNews");
    },
    openCreatePartnerModal() {
      this.$store.commit("openModal", "create-partner-modal");
    },
    openRequestModal() {
      this.$store.commit("openModal", "AddRequestModal");
    }
  }
};
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 20px;
  position: relative;
  background-color: #f5f5f5;
  position: relative;

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 100%;
    width: calc(100% - 40px);
    height: 2px;
    background-color: #e4e4e4;
  }

  .btn.mobile {
    display: none;
  }

  .right-block {
    padding-right: 20px;
  }
}
.right-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  fill: gray;

  i {
    font-size: 20px;
    color: gray;
  }
}

.purse {
  border-radius: 0.3em;
  background-color: #fff;
  padding: 0 0.6em;
  line-height: 30px;
  margin-right: 15px;

  &__icon {
    display: inline-block;
    width: 25px;
    line-height: 30px;

    svg {
      position: relative;
      top: 5px;
    }
  }
}
.notice {
  position: relative;
  cursor: pointer;

  &__icon {
    display: inline-block;
    width: 25px;
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background-color: red;
    color: #fff;
    font-size: 9px;
  }
}

@media (max-width: 768px) {
  .header .right-block {
    .purse,
    .notice {
      display: none;
    }

    .btn.mobile {
      display: block;
      min-width: 60px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        svg {
          width: 20px;
        }
      }
    }

    .btn.desktop {
      display: none;
    }
  }
}
</style>
