<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import Multiselect from "vue-multiselect";
import VuePrism from "vue-prism";
import VCalendar from "v-calendar";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import moment from "moment";
import "moment/locale/ru";
import "prismjs/themes/prism.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import store from "./store.js";

Vue.use(require("vue-moment"), {
  moment
});

Vue.use(VueRouter);
Vue.use(axios);
Vue.component("multiselect", Multiselect);
Vue.use(VuePrism);
Vue.use(VCalendar);
Vue.use(Vuelidate);
Vue.use(VueTheMask);

export default {
  name: "app",
  store,
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Партнерский кабинет";
    }
  },
  mounted() {
    this.$store.commit('getData');
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }
};

</script>

<style lang="scss">
@import "./scss/index";

%container {
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  %container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  %container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  %container {
    width: 1170px;
  }
}

* {
  box-sizing: border-box;
}

body {
  position:relative;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  font-size: 16px;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: inherit;
  outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

input,
button {
  outline: none;
  border: none;
}

.container {
  @extend %container;
}

.bold {
  font-weight: 600;
}

.colored {
  color: #e31c3b;
}

.big-text {
  font-size: 2em;
}

.section-title {
  width: 70%;
  margin: 0 auto;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 1.5em;

  h3 {
    width: fit-content;
    margin: 0 auto;

    &:after {
      content: "";
      display: block;
      width: 70%;
      height: 2px;
      background-color: #e31c3b;
      margin: auto;
      margin-top: 7px;
    }
  }
}

.section-content {
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
}

img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .section-title {
    font-size: 22px;
    width: 100%;
  }
}

.btn {
  cursor: pointer;
  display: inline-block;
  line-height: 1.5;
  font-size: 16px;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  border: 1px solid #d3d4d6;
  outline: none;
  color: #666;
  font-weight: 600;
  transition: background-color 0.2s, color 0.2s;
  width: fit-content;
  min-width: 100px;

  &:hover {
    background-color: #fff;
    color: #333;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &__success {
    background: #00bf9a;
    color: #fff;
    border-color: transparent;
    font-weight: normal;

    &:hover {
      background-color: darken($color: #00bf9a, $amount: 5%);
      color: #fff;
    }
  }

  &__danger {
    background: #fd77a4;
    color: #fff;
    border-color: transparent;
    font-weight: normal;

    &:hover {
      background-color: darken($color: #fd77a4, $amount: 5%);
      color: #fff;
    }
  }

  &[disabled] {
    background-color: #ddd;
    color: #aaa;
    border-color: #ddd;
    cursor: default;
    &:hover {
      background-color: #ddd;
      color: #aaa;
      border-color: #ddd;
    }
  }
}
</style>

