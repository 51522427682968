<template>
  <v-infinite-scroll
    :loading="loading"
    @top="prevPage"
    @bottom="nextPage"
    :offset="15"
    style="max-height: 100vh; overflow-y: scroll; -webkit-overflow-scrolling: touch;"
  >
    <div class="news">
      <LeftMenu />
      <main class="page-container">
        <MobileHeader />
        <PageTitle :title="'Новости'" purse notice createNewsButton />
        <div class="content">
          <div v-if="this.$store.state.allNews.length">
            <NewsCard 
              v-for="item in this.$store.state.allNews"
              :key="item"
              :newsData='item'
              :id="item.id"
            />
          </div>
          <div v-else>
            <p>Новостей нет</p>
          </div>
        </div>
        <ModalOverlay />
        <ChosenNews v-if="this.$store.state.openedModal === 'curNews'"/>
        <AddNews v-if="this.$store.state.openedModal === 'AddNews'"/>
        <EditNews v-if="this.$store.state.openedModal === 'EditNews'"/>
        <DeleteNews v-if="this.$store.state.openedModal === 'DeleteNews'"/>
      </main>
    </div>
     <Preloader/>  
  </v-infinite-scroll>
</template>

<script>
  import LeftMenu       from '../Components/LeftMenu.vue';
  import PageTitle      from '../Components/PageTitle.vue';
  import ModalOverlay   from '../Components/ModalOverlay.vue';
  import MobileHeader   from '../Components/MobileHeader.vue';
  import NewsCard       from './NewsCard.vue';
  import ChosenNews     from './ChosenNews.vue';
  import AddNews        from './AddNews.vue';
  import EditNews       from './EditNews.vue';
  import DeleteNews     from './DeleteNews.vue';
  import axios          from "axios";
  import InfiniteScroll from "v-infinite-scroll";
  import "v-infinite-scroll/dist/v-infinite-scroll.css";
  import Preloader from "../Components/Preloader";

  export default {
    name: 'News',
    components: {
      LeftMenu,
      PageTitle,
      MobileHeader,
      ModalOverlay,
      NewsCard,
      ChosenNews,
      AddNews,
      EditNews,
      DeleteNews,
      InfiniteScroll,
      Preloader
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      handleClick() {
        this.$store.commit('openModal', 'AddNews');
      },
      prevPage() {
        if (this.page == 1) {
          return;
        }
        --this.page;
      },
      nextPage() {
        ++this.page;
        this.api();
      },
      api() {
        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.$store.state.access_token
          }
        });

        this.loading = true;

        instance.get(`/api/get/news?offset=${this.$store.state.newsOffset}`)
                .then(response => {
                  this.$store.commit('addNewNews', response.data);
                  this.loading = false;
                });
      }
    },
    mounted() {
      // TODO: Достать данные с сервака     

      const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.$store.state.access_token
          }
        });

        this.$store.commit('showPreloader');

        instance.get("api/get/news/")
                .then(response => {
                  this.$store.commit('setAllNews', response.data);
                  this.$store.commit('hidePreloader');
                })
                .catch(error => {
                  if (error.response.status === 401) {
                    this.$store.commit('forceLogout');
                  }
                  if (error.response.status === 422) {
                    alert('422 News');
                  }
                  if (error.response.status === 500) {
                    alert('500 News');
                  }
                });
    }
  }
</script>

<style lang="scss" scoped>
  .add-btn {
    margin-bottom: 20px;
  }
</style>
