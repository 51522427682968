<template>
  <div class="modal-big">
    <div class="modal-big__header">
      <h3 class="modal-big__title">Редактирование профиля</h3>
    </div>
    <div class="modal-big__body">
      <form class="modal-big__form form">
        <div class="form__row">
          <span>Личные данные</span>
        </div>
        <div class="form__row">
          <label class="form__label" for="inputName">Имя:</label>
          <input
            v-model.trim="$v.userName.$model"
            class="form__input"
            type="text"
            placeholder="Иванов Иван Иванович"
            id="inputName"
            :class="{
              errorInput: $v.userName.$anyError, 
              validInput: !$v.userName.$anyError && $v.userName.$dirty && $v.userName.$model
            }"
            :value="this.$store.state.user.name"
          >
        </div>
        <div class="form__row">
          <label class="form__label" for="inputEmail">Email:</label>
          <input
            v-model.trim="$v.userEmail.$model"
            class="form__input"
            type="text"
            placeholder="mail@mail.com"
            id="inputEmail"
            :class="{
              errorInput: $v.userEmail.$anyError, 
              validInput: !$v.userEmail.$anyError && $v.userEmail.$dirty && $v.userEmail.$model
            }"
            :value="this.$store.state.user.name"
          >
          <div
            class="error"
            v-if="!$v.userEmail.email && $v.userEmail.$dirty"
          >Некорректный формат email</div>
        </div>

        <div class="form__row">
          <span>Смена пароля</span>
        </div>
        <div class="form__row">
          <label class="form__label" for="passwordOld">Старый пароль:</label>
          <input
            v-model.trim="$v.passwordOld.$model"
            class="form__input"
            type="password"
            id="passwordOld"
            :class="{
              errorInput: $v.passwordOld.$anyError || !$v.passwordOld.required, 
              validInput: !$v.passwordOld.$anyError && $v.passwordOld.$dirty && $v.passwordOld.$model
            }"
          >
          <div
            class="error"
            v-if="!$v.passwordOld.minLength && $v.passwordOld.$dirty"
          >Не менее {{ $v.passwordOld.$params.minLength.min }} символов</div>
          <div class="error" v-if="!$v.passwordOld.required">Введите старый пароль</div>
        </div>
        <div class="form__row">
          <label class="form__label" for="passwordNew">Новый пароль:</label>
          <input
            v-model.trim="$v.passwordNew.$model"
            class="form__input"
            type="password"
            id="passwordNew"
            :class="{
              errorInput: $v.passwordNew.$anyError || !$v.passwordNew.required, 
              validInput: !$v.passwordNew.$anyError && $v.passwordNew.$dirty && $v.passwordNew.$model
            }"
          >
          <div
            class="error"
            v-if="!$v.passwordNew.minLength && $v.passwordNew.$dirty"
          >Не менее {{ $v.passwordNew.$params.minLength.min }} символов</div>
           <div class="error" v-if="!$v.passwordNew.required">Введите новый пароль</div>
        </div>
        <div class="form__row">
          <label class="form__label" for="passwordNewRepeat">Подтверждение пароля:</label>
          <input
            v-model.trim="$v.passwordNewRepeat.$model"
            class="form__input"
            type="password"
            id="passwordNewRepeat"
            :class="{
              errorInput: $v.passwordNewRepeat.$anyError, 
              validInput: !$v.passwordNewRepeat.$anyError && $v.passwordNewRepeat.$dirty && $v.passwordNewRepeat.$model
            }"
          >
          <div
            class="error"
            v-if="!$v.passwordNewRepeat.sameAsPassword && $v.passwordNewRepeat.$dirty"
          >Новый пароль и подтверждение пароля не совпадают</div>
        </div>

        <div class="form__row form__row--btns">
          <button class="btn btn__success" @click.prevent="changeSettings">Сохранить</button>
          <button class="btn btn__danger" @click.prevent="close">Отменить</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  helpers,
  minLength,
  sameAs,
  requiredIf
} from "vuelidate/lib/validators";
import axios from "axios";

const phoneNumber = helpers.regex(
  "alpha",
  /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
);

export default {
  name: "EditUserModal",
  data() {
    return {
      userName: "",
      // userPhone:         null,
      userEmail: "",
      passwordOld: "",
      passwordNew: "",
      passwordNewRepeat: ""
    };
  },
  methods: {
    close() {
      this.$store.commit("closeModal");
    },
    changeSettings() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.access_token
          }
        });

        this.$store.commit("showPreloader");

        let data = {};
        if (this.userName) {
          data.name = this.userName;
        }
        if (this.userEmail) {
          data.email = this.userEmail;
        }
        if (this.passwordNew) {
          data.old_password = this.passwordOld;
          data.password = this.passwordNew;
          data.password_confirmation = this.passwordNewRepeat;
        }

        

        instance
          .post("api/post/user/update", data)
          .then(response => {
            
            if (response.data.result) {
              if (this.userName || this.userEmail) {
                this.$store.commit("updateUserInfo", data);
              }

              this.$store.commit("hidePreloader");
              this.$store.commit("closeModal");
              this.$store.commit("createMessage", {
                type: "success",
                text: response.data.message
              });
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response.status === 401) {
              console.log("401");
              this.$store.commit("createMessage", {
                type: "danger",
                text: error.response.data.message
              });
            }

            if (error.status === 422) {
              this.$store.commit("createMessage", {
                type: "danger",
                text: error.data.errors
              });
            }
          });
      }
    }
  },
  validations: {
    userName: {},
    userPhone: {
      phoneNumber
    },
    userEmail: {
      email
    },
    passwordOld: {
      required: requiredIf(function() {
        return this.passwordNew;
      }),
      minLength: minLength(8)
    },
    passwordNew: {
      minLength: minLength(8),
       required: requiredIf(function() {
        return this.passwordOld;
      }),
    },
    passwordNewRepeat: {
      sameAsPassword: sameAs("passwordNew")
    }
  }
};
</script>

<style lang="scss" scope>
.form__input {
  resize: none;
}
</style>
